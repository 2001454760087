import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Aug232012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/luggage-loot.jpg" alt="Items left in a suitcase" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 aug232012'>
                    <h5>August 23rd, 2012</h5>
                    <h3>Perfect Timing</h3>
                    <p className='p'>At some point I know this nest will be empty.  It could have been today but, because of a divine intervention roughly eight years ago, that isn't yet staring me in the face.</p>
                    <p className='p'>There's one less bird landing in this roost these days. He has made his own nest in Wichita - a whole 7.6 miles away. These first few days I've been keenly aware of a little less ambient heat, more from a feeling of lost security than from the lack of consumption.</p>
                    <p className='p'>Just like the first time this little birdie flew the coop [for college], this is a poignant moment. I'm proud. I'm worried. I want him to come have lunch with me every day just so I can make sure he's eating right.</p>
                    <p className='p'>The other day, he sent me a text that asked if I'd left him a card.  Cards are kind of my thing - if I want you to know I love you; that I'm thinking about you, I give a card.</p>
                    <p className='p'>He found a card in a suitcase we had given him when he moved out.  After being used and abused many times by many people through many states and a few countries, the suitcase had a flat wheel and we were just going to throw it out anyway.  I wish I had thought to leave a card in it, but I hadn't. The envelope wasn't addressed but the card was signed, 'All my love, Heather', so he figured it had been forgotten in the suitcase.</p>
                    <p className='p'>The card wasn't the only thing he found in that suitcase.</p>
                    <p className='p'>There was also a cross necklace that my husband bought it for me in Tel Aviv, Israel. Ten years ago.</p>
                    <p className='p'>At first thought, you might think the necklace had been forgotten and was ten years too late.  Actually, although my husband maybe had forgotten about the necklace (that was the suitcase he used to travel back and forth to Israel every four weeks for six months back in 2002), God had not forgotten.</p>
                    <p className='p'>And the giving was right on time.</p>
                    <p className='p'>For the past several months my heart has been heavy and I have been lamenting over being a part-time single parent; not having my husband home during the week. I have been sulking over not being able to have dinner together on a Tuesday night or go for an after-dinner stroll through the neighborhood. It's been an all-out pity party.</p>
                    <p className='p'>When I got that necklace this week, it made me reflect back to the time it was purchased (and intended to be given) and how, at that time, I saw my husband only every four weeks - not every weekend, like I am blessed to now.</p>
                    <p className='p'>Blessed.</p>
                    <p className='p'>Yes, I am blessed, more than I will ever comprehend, with my family, friends, and a loving God who withholds gifts until just the right time.</p>
                    <p className='p'>His time.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Aug232012;