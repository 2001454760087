import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Feb222011 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2011/pillows.jpg" alt="Pillows" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb222011'>
                    <h5>February 22nd, 2011</h5>
                    <h3>This Relationship Is Over</h3>
                    <p className='p'>So, what do you do when your pillow is getting the crap beat out of it? You get out of bed and blog, of course.</p>
                    <p className='p'>This fat girl, whose body I currently live in, and I were having a conversation the other day. It was mostly one-sided. I was asking her, “who the heck are you?” and “how the heck did I get here?” And she didn't really have any answers.</p>
                    <p className='p'>It's been said the definition of insanity is: doing something over and over expecting a different result. That's comforting because I always thought it was: talking to yourself.</p>
                    <p className='p'>Anyway, the fat girl and I decided (I'm sure it was a mutual decision) that we really don't like living together anymore. She makes me crazy. I test her limits. It's really not working. So we are parting ways.</p>
                    <p className='p'>I will never love her the way she wants to be loved. She could never be the workout partner I need her to be. We are not soul mates. We are not kindrid spirits. We are enemies on the deepest level. We can't even have an honest conversation.</p>
                    <p className='p'>I'm sure she will pop up now and then. We might occasionally have lunch together or share nachos at a football game. And that's fine. As long as I don't have to look at her in the mirror everyday.</p>
                    <p className='p'>I guess I'll go see if my pillow will take me back.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb222011;