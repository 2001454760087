import React from 'react';
import { Link } from 'react-router-dom';
import './main.css';
import './archives.css';

const Archives = () => {

    return (

        <section className="main-sec">
            <div className="main-container">
                <div className='main-grid'>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2016</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan042016.jsx" className='arch-grid-indiv'>January 4th : Moping Mom</Link>
                        <Link to="/feb112016.jsx" className='arch-grid-indiv'>February 11th : Blooming Where You Are Planted</Link>
                        <Link to="/feb282016.jsx" className='arch-grid-indiv'>February 28th : Thanks, You Made a Difference</Link>
                        <Link to="/may182016.jsx" className='arch-grid-indiv'>May 18th : Dear Kids</Link>
                        <Link to="/may242016.jsx" className='arch-grid-indiv'>May 24th : No Spring Chicken</Link>
                        <Link to="/may262016.jsx" className='arch-grid-indiv'>May 26th : The ABCs of Me</Link>
                        <Link to="/may272016.jsx" className='arch-grid-indiv'>May 27th : I Am Mom, Hear Me Roar</Link>
                        <Link to="/may312016.jsx" className='arch-grid-indiv'>May 31st : Boots in Summer</Link>
                        <Link to="/jun102016.jsx" className='arch-grid-indiv'>June 10th : It Starts With Me</Link>
                        <Link to="/sept022016.jsx" className='arch-grid-indiv'>September 2nd : Hurricane, Kansas Style</Link>
                        <Link to="/sept172016.jsx" className='arch-grid-indiv'>September 17th : Life With Boys</Link>
                        <Link to="/dec312016.jsx" className='arch-grid-indiv'>December 31st : Happy New Year</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2015</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan052015.jsx" className='arch-grid-indiv'>January 5th : Looking Back To Look Forward</Link>
                        <Link to="/jan072015.jsx" className='arch-grid-indiv'>January 7th : Resolutions Feed Garage Sales</Link>
                        <Link to="/jan122015.jsx" className='arch-grid-indiv'>January 12th : Just In Case - Confessions of a “Collector”</Link>
                        <Link to="/jan262015.jsx" className='arch-grid-indiv'>January 26th : How I'm Tracking My 2015 'Move More' Goal</Link>
                        <Link to="/feb032015.jsx" className='arch-grid-indiv'>February 3rd : When It's Time To Let Your Kids Pick The Radio Station</Link>
                        <Link to="/feb132015.jsx" className='arch-grid-indiv'>February 13th : Planes, Trains, and Artillery Fire</Link>
                        <Link to="/mar032015.jsx" className='arch-grid-indiv'>March 3rd : Hard Times Call For YoYos</Link>
                        <Link to="/mar092015.jsx" className='arch-grid-indiv'>March 9th : As Long As It Looks Clean, It's Good Enough</Link>
                        <Link to="/apr292015.jsx" className='arch-grid-indiv'>April 29th : Treasures Tucked Into Books</Link>
                        <Link to="/jun122015.jsx" className='arch-grid-indiv'>June 12th : Life on Lake Placid</Link>
                        <Link to="/jul062015.jsx" className='arch-grid-indiv'>July 6th : What Body Clock?</Link>
                        <Link to="/aug182015.jsx" className='arch-grid-indiv'>August 18th : Unicorns Live in the Mountains</Link>
                        <Link to="/oct112015.jsx" className='arch-grid-indiv'>October 11th : Strangely Beautiful, Profoundly Sad</Link>
                        <Link to="/nov062015.jsx" className='arch-grid-indiv'>Novemeber 6th : Late to the Party</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2014</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan312014.jsx" className='arch-grid-indiv'>January 31st : Our Move to OZ</Link>
                        <Link to="/mar102014.jsx" className='arch-grid-indiv'>March 10th : Just When You Think You've Seen It All</Link>
                        <Link to="/apr242014.jsx" className='arch-grid-indiv'>April 24th : Adrian Peterson Confused for Adrian Peterson</Link>
                        <Link to="/jul282014.jsx" className='arch-grid-indiv'>July 28th : Inaugural Savannah Summer</Link>
                        <Link to="/sept082014.jsx" className='arch-grid-indiv'>September 8th : Ten Things You Can Still Do With One Arm</Link>
                        <Link to="/sept092014.jsx" className='arch-grid-indiv'>September 9th : Book Review: The Legend of Sheba</Link>
                        <Link to="/dec162014.jsx" className='arch-grid-indiv'>December 16th : I Bought The Lies</Link>
                        <Link to="/dec262014.jsx" className='arch-grid-indiv'>December 26th : Because That's Just The Way We've Always Done It</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2013</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan042013.jsx" className='arch-grid-indiv'>January 4th : Rockin' the 4-0</Link>
                        <Link to="/jan112013.jsx" className='arch-grid-indiv'>January 11th : My Year of Books</Link>
                        <Link to="/mar182013.jsx" className='arch-grid-indiv'>March 18th : A Little of This, A Little of That</Link>
                        <Link to="/apr012013.jsx" className='arch-grid-indiv'>April 1st : Happy Anniversary ... No Fooling!</Link>
                        <Link to="/may012013.jsx" className='arch-grid-indiv'>May 1st : To Garden or Not To Garden</Link>
                        <Link to="/may132013.jsx" className='arch-grid-indiv'>May 13th : Bookstore Bliss</Link>
                        <Link to="/jul162013.jsx" className='arch-grid-indiv'>July 16th : Book Review: The Happiness Project</Link>
                        <Link to="/jul222013.jsx" className='arch-grid-indiv'>July 22nd : A Friend's a Friend Forever</Link>
                        <Link to="/jul302013.jsx" className='arch-grid-indiv'>July 30th : Book Review: The Kite Runner</Link>
                        <Link to="/aug142013.jsx" className='arch-grid-indiv'>August 14th : (Mostly) Happily Mothering Boys</Link>
                        <Link to="/sept192013.jsx" className='arch-grid-indiv'>September 19th : Book Talk: MISERY LOVES COMPANY</Link>
                        <Link to="/oct152013.jsx" className='arch-grid-indiv'>October 15th : Big Changes</Link>
                        <Link to="/oct222013.jsx" className='arch-grid-indiv'>October 22nd : Sweet Tooth Aliens Have Landed</Link>
                        <Link to="/nov252013.jsx" className='arch-grid-indiv'>November 25th : Perfect Caramel Apple Flop</Link>
                        <Link to="/dec022013.jsx" className='arch-grid-indiv'>December 2nd : Author Interview: Erin Healy</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2012</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan172012.jsx" className='arch-grid-indiv'>January 17th : Cheez-Its in Utah</Link>
                        <Link to="/feb022012.jsx" className='arch-grid-indiv'>February 2nd : I Yam What I Yam</Link>
                        <Link to="/feb132012.jsx" className='arch-grid-indiv'>February 13th : Treadmill Epiphany</Link>
                        <Link to="/feb212012.jsx" className='arch-grid-indiv'>February 21st : Lent Minus Facebook Equals Contrition</Link>
                        <Link to="/mar232012.jsx" className='arch-grid-indiv'>March 23th : No Pay No Play</Link>
                        <Link to="/mar262012.jsx" className='arch-grid-indiv'>March 26th : Monday Morning Self-Reflection</Link>
                        <Link to="/apr162012.jsx" className='arch-grid-indiv'>April 16th : “The serpent deceived me, and I ate.” Gen. 3:13</Link>
                        <Link to="/apr192012.jsx" className='arch-grid-indiv'>April 19th : For now, Dick Clark ... So long</Link>
                        <Link to="/jun112012.jsx" className='arch-grid-indiv'>June 11th : The Season of Dog Hair</Link>
                        <Link to="/aug022012.jsx" className='arch-grid-indiv'>August 2nd : Eat Mor Chikin</Link>
                        <Link to="/aug062012.jsx" className='arch-grid-indiv'>August 6th : No Ink Joy</Link>
                        <Link to="/aug232012.jsx" className='arch-grid-indiv'>August 23rd : Perfect Timing</Link>
                        <Link to="/dec122012.jsx" className='arch-grid-indiv'>December 12th : Eyeball to Measuring Spoon</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2011</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/feb222011.jsx" className='arch-grid-indiv'>February 22nd : This Relationship Is Over</Link>
                        <Link to="/apr132011.jsx" className='arch-grid-indiv'>April 13rth : Cinderella</Link>
                        <Link to="/apr142011.jsx" className='arch-grid-indiv'>April 14th : City or Country</Link>
                        <Link to="/jun212011.jsx" className='arch-grid-indiv'>June 21 : The Airport</Link>
                        <Link to="/jun222011a.jsx" className='arch-grid-indiv'>June 22nd : The Airplane</Link>
                        <Link to="/jun222011b.jsx" className='arch-grid-indiv'>June 22nd : The Lav</Link>
                        <Link to="/jun262011.jsx" className='arch-grid-indiv'>June 26th : Savannah ... Smooth. Sultry. Stogies.</Link>
                        <Link to="/sept282011.jsx" className='arch-grid-indiv'>September 28th : She was Just "Differnt"</Link>
                        <Link to="/oct192011.jsx" className='arch-grid-indiv'>October 19th : Slow Down</Link>
                        <Link to="/dec212011.jsx" className='arch-grid-indiv'>December 21st : My Clients, My Friends</Link> 
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2010</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/jan292010.jsx" className='arch-grid-indiv'>January 29th : Snow Day! Snow Day!</Link>
                        <Link to="/may012010.jsx" className='arch-grid-indiv'>May 1st : Deadheading Not Required</Link>
                        <Link to="/jul112010.jsx" className='arch-grid-indiv'>July 11th : Camping is ...</Link>
                        <Link to="/nov182010.jsx" className='arch-grid-indiv'>November 18th : Me. Take it or leave it.</Link>
                        <Link to="/nov252010.jsx" className='arch-grid-indiv'>November 25th : Thanksgiving Ham</Link>
                    </div>
                    <br />
                    <div className='arch-grid'>
                        <div><b>2009</b></div>
                        <div></div>
                        <div></div>
                        <Link to="/sept212009.jsx" className='arch-grid-indiv'>September 21st : Church Camp Memories</Link>
                        <Link to="/sept282009.jsx" className='arch-grid-indiv'>September 28th : Daylight Saving Time</Link>
                    </div>
                    <br />
                </div>
            </div>
        </section>

    )
}

export default Archives;