import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Dec212011 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2011/ladies-sun.jpg" alt="Women facing sunset" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec212011'>
                    <h5>December 21st, 2011</h5>
                    <h3>My Clients, My Friends</h3>
                    <p className='p'>“A friend reaches for your hand and touches your heart.”</p>
                    <p className='p'>This being my first week without clients I decided it was fitting to write about them and what they taught me over the years.</p>
                    <p className='p'>I have had clients that ranged in age from the very young with very little experience and not yet even considering starting their own families to the more mature with experiences to fill a lifetime, having already raised their families.  There are great things to learn from any age when you are sitting holding their hand.</p>
                    <p className='p'>My clients kept me up to date on life.  Not just their own but also the world around us. I learned everything from politics to gardening.  I was kept apprise of the price of oil and the price of apples.  We talked about dieting and how to squeeze in some exercise in the car during a long road trip.  I learned about growing up in small town Kansas and about working for a living when that was just what everyone did.  We talked about books and movies and music and what was going on around town.  I heard stories about the Andover tornado and of floods, festivals and scandals.  I learned people love to share what they know.  Which works out well for me because I learn a lot that way.</p>
                    <p className='p'>My clients were my best friends, always asking how members of my family were doing and catching up on the antics that get the boys grounded and turn my hair grey.  They asked because they truly cared.  We shared our ailments, our hobbies, our Schwan man, our favorite recipes, our lives.</p>
                    <p className='p'>I will miss these ladies dearly.  They have taught me much along the way, lessons that I will forever carry with me into whatever the next chapter of my life holds.  I suspect many of them will end up in my books, in some form or another, for we write what we know and these ladies are what I have known for the last five years.</p>
                    <p className='p'>Ladies, I wish you well.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec212011;