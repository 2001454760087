import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jan262015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/activity-tracker.jpg" alt="Activity tracker" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan262015'>
                    <h5>January 26th, 2015</h5>
                    <h3>How I'm Tracking My 2015 'Move More' Goal</h3>
                    <p className='p'>I received an activity tracker for Christmas, having forgotten than I'd even asked for one. I hoped the little wrist-band gizmo was going to do the walking for me and give me all the credit.</p>
                    <p className='p'>It's a pretty cool gadget, really. I can set an idle alert to remind me to get up and move and a reminder when it's time to get ready for bed. Then it tracks how well I sleep.</p>
                    <p className='p'>The Power Nap feature lets me set a nap length myself or will set an optimal length based on my previous night's sleep. This feature will be my favorite if ever I get the time to take a nap.</p>
                    <p className='p'>I've learned two important things in the four weeks of wearing this thing 23 ½ hours a day.  First, if I want to get eight hours of sleep, I have to be in bed for at least nine. I wake up if the air so much as moves outside much less if there's actual wind, so sleeping a solid eight hours is impossible.</p>
                    <p className='p'>Secondly, setting my daily step goal at 10,000 was very optimistic. Reaching that goal means intentionally stepping most of my waking hours. Since I am calling myself a writer, and that means not stepping even out of my desk chair for hours on end, there's no way I'm getting that many steps every day. In fact, my average on good days is just under 5,000.</p>
                    <p className='p'>A couple of days ago, I logged 9,377 steps by 10p.m. It was dark but there was NO WAY I was not going for the full 10,000 since there was a good chance I wouldn't be that close any time soon. So before bed I walked around the house until I had reached it.</p>
                    <p className='p'>By the time I crawled into bed I had logged 10,318 steps, 4.6 miles, 103% of my goal. The next morning I learned a third very-important something: I am out of shape.</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan262015;