import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';


const Header = () => {
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handleLinkClick = () => {
        setIsActive(false);
    };

    return (

                <header className='header'>
                    <section className='logo-container'><Link to="/"><img src="images/twi-pen-logo.png" alt="Logo" className='logo' /></Link></section>
                    <br />
                    <nav className='nav-bar'>
                        <ul className={`nav-menu ${isActive ? 'active' : ''}`}> 
                            <li className='nav-item'>
                                <Link to="/recent" className='nav-link' onClick={handleLinkClick}>Recent</Link>
                            </li>
                            <br />
                            <li className='nav-item'>
                                <Link to="/archives" className='nav-link' onClick={handleLinkClick}>Archives</Link>
                            </li>
                            <br />
                            <li className='nav-item'>
                                <Link to="/reviews" className='nav-link' onClick={handleLinkClick}>Reviews</Link>
                            </li>
                            <br />
                            <li className='nav-item'>
                                <Link to="/contact" className='nav-link' onClick={handleLinkClick}>Contact</Link>
                             </li>
                        </ul>
                        <div className={`hamburger ${isActive ? 'active' : ''}`} onClick={handleToggle}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </div>
                    </nav>

                    <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                        {/* <use href="#gentle-wave" x="48" y="0" fill="rgba(130, 209, 216, 1)" /> */}
                            <use href="#gentle-wave" x="48" y="0" fill="rgba(130, 123, 183, 1)" />
                            <use href="#gentle-wave" x="48" y="3" fill="rgba(153, 204, 209, 1)" />
                            <use href="#gentle-wave" x="48" y="5" fill="rgba(167, 99, 106, 1)" />
                            <use href="#gentle-wave" x="48" y="7" fill="rgba(97, 182, 200, 1)" />
                        </g>
                    </svg>
                </header>
    )
}

export default Header;