import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jan072015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/treadmill-for-sale.jpg" alt="Cartoon" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan072015'>
                    <h5>January 7th, 2015</h5>
                    <h3>Resolutions Feed Garage Sales</h3>
                    <p className='p'>A few years ago, I wrote a newspaper column about the flawed thinking in making New Year's resolutions. January resolutions feed self-contempt and June garage sales. I suggested that, rather than putting our lives and habits under the microscope at the top of every year to find the things we don't like about ourselves in order to form a plan for improvement, we instead find those things that make us happy and keep doing those.</p>
                    <p className='p'>It was a tongue-in-cheek piece in which I admitted to drinking too much coffee (still do) and not washing my hair every day (still don't.)  But having turned forty-something this week, I'm faced with the reality that my body and my mind are aging quicker than I'd like and it might benefit me in the long run to at least make some goals for 2015. I guess that's what “mature” people do.</p>
                    <p className='p'>Note that I am calling these GOALS and not resolutions. According to a statistic I read recently, only eight percent of people that make resolutions actually keep them. I know myself well enough to know that I am not likely to be in that percentage.</p>
                    <p className='p'>Resolutions are too finite. All or nothing. Pass or fail. Gold star or frownie face. I just don't need that kind of stress, especially self-induced.</p>
                    <p className='p'>Goals can be measured more like a grade where even fifty percent doesn't mean failure, it just means lack of effort. Seventy-five percent says I didn't get there, but I tried.</p>
                    <p className='p'>As an act of accountability, I am recruiting some friends and family to hold my feet to the fire. This might be really dumb on my part because I'm putting those I love the most in the target zone of fiery darts when I slip up and they call me on it. But, in the event that I do reach that elusive one hundred percent, I want someone to celebrate with.</p>
                    <p className='p'>This year I'm writing more, reading more, moving more, connecting more.</p>
                    <p className='p'>At least that's my GOAL.</p>
                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan072015;