import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Apr162012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/billboard.jpg" alt="Billboard" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 apr162012'>
                    <h5>April 16th, 2012</h5>
                    <h3>“The serpent deceived me, and I ate.” Gen. 3:13</h3>
                    <p className='p'>“He is filled with fury, because he knows that his time is short.” Rev. 12:12</p>
                    <p className='p'>There was an article a couple weeks ago in the Wichita Eagle about billboards that went up in Wichita. The billboard message: “Are you good without God? Millions are.”</p>
                    <p className='p'>(First, I think it's interesting that God is capitalized.)</p>
                    <p className='p'>The article states the ads, which will be up for a month, were placed by the Wichita Coalition of Reason (CoR), an alliance of three nontheistic groups in the city: Air Capital Skeptics, Wichita Atheists, and WSU Atheists & Agnostics. The CoR says the billboards are intended to send a message to agnostics, atheists and skeptics to let them know they are not alone.</p>
                    <p className='p'>One member of the coalition states, “Nonbelievers sometimes feel as if they are walking on eggshells and can encounter resistance and sometimes ostracism from family and friends when they express their views” and “it's pretty clear that being outspoken as a nonbeliever can be a precarious thing.  It can cost you friends and jobs” said another.</p>
                    <p className='p'>These statements drew an instant, nearly involuntary “Pffft” from me. Isn't that the identical plight of believers around the world and has been since before Jesus walked on water? In fact, the Bible tells believers to expect this.  Jesus, when he gave the Sermon on the Mount, told believers “Blessed are those who are persecuted because of righteousness, for theirs is the kingdom of heaven.” (Side note: He doesn't say theirs will be; He says theirs is the kingdom of heaven.)</p>
                    <p className='p'>(Warning: Sarcasm ahead)</p>
                    <p className='p'>The group uses terms like “freethinkers” (as opposed to us restricted thinkers) and “like-minded.” (I've heard it said that if two people think exactly alike, one of them is unnecessary.) Their website claims their goals are to foster a sense of community among the like-minded (and the rest of you can go to … where? hell?) and to raise public awareness about issues that are important to our secular community.  Ya know, like poverty, hunger, homelessness and the like.  The issues those believers … what? … sweep under the rug? Come on. Those are important to the believing, God-fearing community as well.</p>
                    <p className='p'>Y'all, this article and the resulting research into this group saddens as well as scares the pants off me.</p>
                    <p className='p'>The human race is hungry and searching. For meaning. For purpose. For belonging. C.S. Lewis thought this to be a God-hunger that is in all of us. In The Weight of Glory he describes it like this:</p>
                    <p className='p'>“We cannot tell it because it is a desire for something that has never actually appeared in our experience.  We cannot hide it because our experience is constantly suggesting it..”</p>
                    <p className='p'>Every one of us knows in the depths of our soul there is something “out there” but often we go looking in the wrong direction - toward self or a man-made solution.</p>
                    <p className='p'>And many have been chased away from churches by legalism and unacceptance. That's unfortunate. They will never find lasting fulfillment or peace in things of this world. The new wears off. The shine dulls. And off they go to find the next thing that makes them happy; makes them feel accepted.</p>
                    <p className='p'>And the saddest part:  If they never find their way to believing in and accepting the very God they are fighting for the right to deny, they are doomed to eternity in a fiery pit.  There is no such thing as nothingness. It's heaven or hell. There is no “opt out.”</p>
                    <p className='p'>And they are surrounding themselves with no one who knows any better, no one to challenge their belief, no one who can speak truth to them.  As Christians, we are called to be salt of the earth (Matt. 5:13) but y'all that means we have got to get out of the salt shaker because they are not going to come to us.  Remember, they don't darken the doorways of the churches that preach the Gospel. Because they are “skeptics.”</p>
                    <p className='p'>Now, here's what scares me:  That intelligent, educated people actually believe they are OK living without God and are recruiting other hungry, searching people to the same beliefs. They are forming their own religion of sorts, albeit a “Burger King” religion - I'll have it my way. But there is strength in numbers and I don't want my family or friends (or future friends) to be recruited to those numbers. I want them on my side. God's side. I want them to find what it is their hungry, searching souls were made to find.</p>
                    <p className='p'>So I ask: Where is the Wichita Coalition for the Believers in Christ? Who will foot the bill for the opposing billboard that says,</p>
                    <p className='p'>“Are you good without God? Millions are just as lost.”</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Apr162012;