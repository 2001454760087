import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const May012013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/gardening.jpg" alt="Gardening" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may012013'>
                    <h5>May 1st, 2013</h5>
                    <h3>To Garden or Not To Garden</h3>
                    <p className='p'>There was a day a few weeks ago that I really thought I'd plant a garden this year. I envisioned myself, with my knees in the dirt, finding the God of my inspiration in the mulch. I'd hum praise songs while pulling weeds and waiting for my salsa ingredients to grow.</p>
                    <p className='p'>Lala la la…………</p>
                    <p className='p'>And then came the time to plant. And it snowed. And rained. And ice froze on the trees. When the sun finally came out, the nurseries had vegetable plants ready for sale. They mocked me. They said, <i>you should really have these in the ground by now.</i></p>
                    <p className='p'>Then the realization that I hate gardening, of all kinds, slowly settled into my being. I don't like too hot, too cold, too windy. I don't like planting things. I don't like pulling weeds. I don't even like watering. And I especially don't like all the bugs that think they own the place.</p>
                    <p className='p'>I'm not sure why I like the idea of having a gardening hobby. I guess I look at it from the eyes of people who think that such a thing is relaxing. Like runners think of running. Neither of those do I think is relaxing. In fact, I would put the two activities in the same category: brutal.</p>
                    <p className='p'>I tried being a runner. I went 13.1 miles. And then I lost a couple toenails and decided if God had wanted me to be a runner, He would have made me to enjoy it and given me better toenails. Running is not my gift.</p>
                    <p className='p'>Thankfully, the toenails grew back.</p>
                    <p className='p'>So, I'm thinking that this winter weather that's coming today - May 1 - is God's way of patting me on the back and saying, <i>It's OK, gardening is not your gift either.</i></p>
                    <p className='p'>I'm not sure how we are supposed to discern what our gifts are. But I'm thankful to be figuring out what mine are not.</p>
                    <p className='p'>So, I'm not going to stress out about the weather. I'm not going to worry about not having tomato plants in the ground. I'll put a couple in pots, like I did last year, and let them grow if they want to.</p>
                    <p className='p'>“Be joyful always; pray continually; give thanks in all circumstances, for this is God's will for you in Christ Jesus.” 1 Thessalonians 5:16-18</p>
                    <p className='p'><i>Give thanks in all circumstances.</i> That's a tough one. Especially for those who are ready for spring; ready to get outside and plant things.</p>
                    <p className='p'>Well, I'm thankful I don't have to. Yet.</p>
                    <p className='p'>I'm thankful for one last cold snap that forces me to be inside, doing what I <i>do</i> enjoy doing. And when spring finally gets here I'll be thankful that I don't have a garden forcing my attention. I'll take my laptop, my books, my notebooks out to the deck and <i>give thanks</i> that it's screened in to keep the bugs out.</p>
                    <br />
                    <p className='p'>To garden or not to garden….it's not even a question.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May012013;