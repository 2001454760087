import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Feb032015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/radio.jpg" alt="Car radio" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb032015'>
                    <h5>February 3rd, 2015</h5>
                    <h3>When It's Time To Let Your Kids Pick The Radio Station</h3>
                    <p className='p'>I have this rule about children riding in my car: not in the front seat until you're thirteen. This rule came about from a story I read in a novel about a fireman's experience with the death of a young child, having been thrown through the dash into the engine in a car accident. I think it might have been out of a Karen Kingsbury book. (Ask me about it and I'll tell you.) The story grabbed at my heart and didn't let go. So I made the rule and rarely break it.</p>
                    <p className='p'>When the first of my Littles turned thirteen, and earned his rite of passage to the front seat, I knew the who-was-sitting-where argument would end; the other Little choosing any seat he wanted in the back.</p>
                    <p className='p'>I also anticipated the return of the arguments when the second Little turned thirteen. What I did not expect was the argument turning to who got to sit in the back! Whaaa……???</p>
                    <p className='p'>They had discovered the ability to listen to music of their choosing in the back seat by using the DVD headphones. This meant they did not have to listen to the station I had playing from the front. (I should mention here that they do not have smart phones and are not generally allowed to take their MP3 players to school.)</p>
                    <p className='p'>Call me oversensitive, but this offended me. Not because I listen to only great music in the front seat (which I do) but because I generally listen to the same stuff they listen to. Or at least I did. Apparently now I don't. So I had to make a schedule assigning front-seat sitting. (Oye, the trials of parenthood, right?)</p>
                    <p className='p'>SiriusXM makes for a great torture tool when it comes to what you make your kids listen to while riding in the front seat with you. Specifically, 70s on 7, 80s on 8, and 90s on 9 - all great stations. But I try to mix it up every day so they never have expectations of how they are to be tortured. Some days it's Classic Rewind, Alt Nation, or Ozzy's Boneyard. Some days it's The Message, The Blend, or Watercolors.</p>
                    <p className='p'>Today's playlist:
                        <ul>
                        <li>Doctor! Doctor! by Thompson Twins (1984)</li>
                        <li>Landslide the Smashing Pumpkins version (Nobody has ever done it better than Stevie Nicks.)</li>
                        <li>Land of Confusion the Disturbed version (Better than the Genesis original, I think.)</li>
                        <li>Gangsta's Paradise  by Coolio (1995)</li>
                        </ul>
                    </p>
                    <p className='p'>My thirteen year-old son, when that last one was playing says to me, “You were able to drink beer when that song came out.” This stumped me for a minute wondering why that was his first thought when listening to a song with such dark lyrics. But he was right. Barely.</p>
                    <p className='p'>Then my mind trailed off into the land of Where Has Time Gone. I turned twenty-two in 1995. That song on the radio that couldn't have been more than five or ten years old was actually twenty years old. Twenty. Yikes.</p>
                    <p className='p'>Starting tomorrow, the front-seat passenger gets to choose the music. I'm hoping for a current Top 40 station.</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb032015;