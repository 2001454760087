import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const May182016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/motivated-kid.jpg" alt="Motivated kid" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may182016'>
                    <h5>May 18th, 2016</h5>
                    <h3>Dear Kids</h3>
                    <p className='p'>Dear Kids,</p>
                    <p className='p'>You are in the home stretch now. You've trudged on for months to land at this moment. You've read novels you didn't understand, written essays you couldn't repeat today, copied and studied vocab words that you know you'll never use in conversation. You've worked Algebra problems, Geometry problems, Physics and Chemistry problems until your erasers were gone. You've spent hours on the computer pretending to do research. And, because you have the privilege of attending a private Christian school, you've memorized Scripture verses and studied the Old Testament, the life of Jesus, and everything in between, even when you weren't sure what you were learning was true or good or held any promise.</p>
                    <p className='p'>Your Dad and I have yelled and threatened, cajoled and coaxed, begged and blackmailed until we're blue in the face and I'm pretty sure we each have sprouted new gray hairs this year. We've spent sleepless nights worrying about your futures, awoken early to plead on our knees for God to just help us get through this school year. We've conferenced with teachers, emailed the counselor, and tracked your progress way more than we should have needed to. We've all but emptied our savings account to give you the best possible education so you'll be intelligent, productive members of the society you'll one day enter in, with little to no show of appreciation from you. I suppose we didn't appreciate all our parents did for us either.</p>
                    <p className='p'>Next week, after exams are over, we'll move on into summer - whatever that means. We'll move forward with whatever we need to do next. We'll do it because that's what we've been called to do. We'll do it because, as imperfect as we are in the doing, we love you.</p>
                    <p className='p'>Work hard, kids, and learn to persevere. Finish well. Because, trust us, there will be harder things to come in your life and there just isn't a better time to figure out how to push through than now.</p>
                    <p className='p'><i>“Therefore, since we are surrounded by so great a cloud of witnesses [who by faith have testified to the truth of God's absolute faithfulness], stripping off every unnecessary weight and the sin which so easily and cleverly entangles us, let us run with endurance and active persistence the race that is set before us, 2 [looking away from all that will distract us and] focusing our eyes on Jesus, who is the Author and Perfecter of faith [the first incentive for our belief and the One who brings our faith to maturity], who for the joy [of accomplishing the goal] set before Him endured the cross, disregarding the shame, and sat down at the right hand of the throne of God [revealing His deity, His authority, and the completion of His work].”</i> Hebrews 12:1-2 AMP</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May182016;