import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Oct192011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/pumpkincarve.jpeg" alt="Pumpkin" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 oct192011'>
                        <h5>October 19th, 2011</h5>
                        <h3>Slow Down</h3>
                        <p className='p'>Yesterday we attended our annual Pumpkin Carving party with our Pecan Court friends. This tradition, started a number of years ago, continues as some of our children grow old enough to carve by themselves and others of our children “age out”. We gather our pumpkins together for a night of carving and a soup supper. When the pumpkins are all carved, we line them up on the porch, light 'em up, then oooh and ahhhh.</p>
                        <p className='p'>The pumpkins themselves range from the simple, traditional jack-o-lantern faces to the difficult and ornate. And there are always a couple KSU pumpkins from the K-Staters among us.</p>
                        <p className='p'>We have managed, in our busy lives, to reconnect every year with these friends. At one time, when our kids were growing up together, we shared almost a communal living lifestyle. I'll never forget, shortly after one family moved in, the youngest of the group of kids - a toddler at the time- opened our front door and walked right in. She was just looking for someone to play with. We never worried about where our kids or our kids' toys were. If they weren't at our house, they were in the neighborhood somewhere.</p>
                        <p className='p'>Driveways were our living room; the cul-de-sac was the playground. Put out your lawn chair and they will come. We'd driveway sit until after dark while the kids played in the street. With only one kid old enough to drive at the time, when him or his friends would barrel down the 'sac' one or all of us would yell to SLOW DOWN!</p>
                        <p className='p'>Slow down.</p>
                        <p className='p'>The older kids have moved on to college, work, and kids. The babies of our group are getting ready to enter middle school. The adults are older…wiser…older (oh I said that, didn't I?).</p>
                        <p className='p'>I am sad that we aren't all together anymore. Jason and Kyle both missed pumpkin carving night this year as well as many of the other “olders”. But, I'm hopeful that the traditions we've instilled in them will carry on into their adulthood when the time comes.</p>
                        <p className='p'>If I allow myself to imagine that far into the future I can see them in their own young neighborhoods - standing at the bus stop chatting long after the bus has left the corner, annual camping trips, pumpkin carving parties and progressive dinners, driveway sitting until after dark - debating on who's going to break it up first to go fix supper only to decide everyone will just have sandwiches, and yelling at their own teens to SLOW DOWN!</p>
                        <p className='p'>Slow down.</p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Oct192011;