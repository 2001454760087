import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Jan172012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/home.jpg" alt="Dream Home" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan172012'>
                    <h5>January 17th, 2012</h5>
                    <h3>Cheez-Its in Utah</h3>
                    <p className='p'>Every day I sign up to win a house in Utah.  Not just any house - The HGTV Dream Home 2012.  If I win I can choose the prizes which include the house, a car and some cash, or I can choose to just take cash.  OK, let's think about this…</p>
                    <p className='p'>I've never been to Utah so I have no idea what it's like there.  I know it's cold.  Is it windy?  That makes a huge difference to me because I don't like cold, I hate wind and when cold and wind combine it's nearly intolerable. (So, what the heck am I still doing in Kansas?)  I looked up the town of Park City where the house is located and it looks like a pretty cool little town.  It has a bunch of cute little boutique shops, restaurants, coffee shops, etc.  It appears to be one of those places where you could just hang around all day and never get bored.</p>
                    <p className='p'>The house, of course, is huge.   It's a Dream Home; it's supposed to be huge.  Some of the past years homes I have gagged my way through the decoration choices on the virtual tour but this house I actually like most of it.  There are just a couple chandeliers that I thought belonged in a Moose Lodge but never in a house.  It's laid out pretty well and the best part: there are only three bedrooms.  This means that as long as I have kids living under my roof there is no room for relatives.  OK, I'm just kidding.  Maybe that's not the best part.</p>
                    <p className='p'>There is that little problem of having to move to Utah if I chose the prizes.   But then I think, “Is that really a problem?”  The more I think about it, the more I kind of like the idea.  Of course I'm relating it to having a cabin on a lake or in the mountains somewhere that I could escape to, like the Stephen King wannabe that I am, and spend days on end writing the next bestseller with my trusty dog at my feet and empty boxes of Cheez-Its littering my desk.  Do you think they have Cheez-Its in Utah?</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan172012;