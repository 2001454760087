import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Feb282016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/revenant.jpg" alt="Revenant book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb282016'>
                    <h5>February 28th, 2016</h5>
                    <h3>Thanks, You Made a Difference</h3>
                    <p className='p'>There's a new movie out right now, The Revenant, which is based off of a book written by a guy from my hometown. I think that's pretty cool. It would be cooler if I knew him, but I don't.</p>
                    <p className='p'>But I think my six degrees of separation is still pretty cool.</p>
                    <p className='p'>I happened to notice that the author of the book, Michael Punke, thanked a few of his teachers from said hometown on the acknowledgement page. In his words, “Thanks to a group of outstanding teachers from Torrington, Wyoming: …Craig Sodaro, Randy Adams … If you ever wonder whether teachers make a difference, please know that you did for me.”</p>
                    <p className='p'>I smiled. These were my teachers as well.</p>
                    <p className='p'>Mr. Adams taught me history or geography or some other subject I really didn't care about at the time. He was a good teacher; I just wasn't his greatest student.</p>
                    <p className='p'>Mr. Sodaro, however, was my eighth grade English teacher - a subject I was interested in.  Not only was he a great teacher, but I also credit him, in part, for my wanting to be a writer (even if it went off my radar for a couple decades.) To this day, he remains one of my favorite teachers. He might even rank #1 on that list. As evidence of that, I have kept - for all these (gulp) thirty years - two of my writing assignments from his class, one fiction and one non-fiction.</p>
                    <p className='p'>(There was another teacher mentioned that I'm pretty sure was my freshman English teacher, and no discredit to him, but I wasn't very focused on school that year.)</p>
                    <p className='p'>I love two things about these papers. First, that they are handwritten. Hand writing anything is rapidly becoming a thing of the past and that's just sad. I cling to the handwritten word mightily. Secondly, I love the painstakingly perfect penmanship, every left-slanted line and circle-dotted 'i' of it.</p>
                    <p className='p'>To tell you the truth, I'm not entirely sure how or why I hung on to these papers all these years. But I'm glad I did. It’s a visual reminder that this course was set before me long before I knew I was on it.</p>
                    <p className='p'>I echo Mr. Punke's sentiment: Thanks, you made a difference for me too.</p>
                    <br />
                    <div className='p-img-container'>
                        <img src="/images/2016/h-written-paper-1.jpg" alt="School paper 1" className='p-imgs' />
                        <img src="/images/2016/h-written-paper-2.jpg" alt="School paper 2" className='p-imgs' />
                    </div>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb282016;