import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Jul302013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/book-kite-runner.jpg" alt="Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul302013'>
                    <h5>July 30th, 2013</h5>
                    <h3>Book Review: The Kite Runner</h3>
                    <p className='p'>One day, while in my favorite used book store in Wichita, I came across a paperback copy of Khaled Hosseini's <a href="https://www.amazon.com/s?k=The+Kite+Runner+Khaled+Hosseini&i=stripbooks&crid=12CIEARC13W5K&sprefix=the+kite+runner+khaled+hosseini%2Cstripbooks%2C115&ref=nb_sb_noss_2" target="_blank" rel="noopener noreferrer"></a> The Kite Runner.</p>
                    <p className='p'>I was already interested in reading this book because I'm always interested in reading first novels. But, this wasn't just an ordinary used book.  This one was highlighted front to back in five different colors with comments next to each. There were two names written inside the front cover, the first one crossed out. By that and the legend of highlighter colors, I am assuming this was some sort of school assignment.</p>
                    <p className='p'>I was intrigued by someone else's assessment, especially with all the pretty highlighter colors. And with a discounted price tag of one dollar, I just couldn't leave it on the shelf.</p>
                    <p className='p'>I won't say I <i>should</i> have left it on the shelf. I actually learned a great deal about story telling from the previous reader and her markings. But I can already tell you this is bound to be my least favorite read of 2013.</p>
                    <p className='p'>And it's not because it was poorly written or the characters weren't developed enough. On the contrary, this is a very well-written, swift-moving story with highly developed characters.</p>
                    <p className='p'>The Kite Runner is a powerful story of betrayal and redemption (but swallow quick or you'll choke on all the literary themes tucked in between.)</p>
                    <p className='p'>The glimpses of happiness are overpowered by the overarching themes of guilt and loss. And, although a page-turner by anyone's standards, the almost happy ending isn't strong enough to deliver the good feeling I was looking for at the end of such an intense life story.</p>
                    <p className='p'>Yes, the hero gets the girl. And yes, he atones for his betrayal of his best friend. And all that is good. But there was a too-little-too-late ending that simply left me sad for the hero and his family.</p>
                    <p className='p'>I know this author has written two more books since this one and maybe the story continues and brightens after all. But someone will have to fill me in because I am not likely read them myself.</p>
                    <p className='p'>Unless I happen upon them for, say … a dollar and some pretty highlighting.</p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul302013;