import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Jun102016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/crying.jpg" alt="Eye crying" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jun102016'>
                    <h5>June 10th, 2016</h5>
                    <h3>It Starts With Me</h3>
                    <p className='p'>I read An Open Letter to My Teenage Boys About Brock Turner and Rape Culture to my own teenage boys at lunch today. I didn't make it through it without crying and they, of course, thought I was off my rocker. But, that's OK. This is important stuff they need to know.</p>
                    <p className='p'>I am thankful God chose me to raise boys - boys that have and will become men who will face some of the same decisions this young man and the two tacklers faced. From what I understand, raising boys is easier than raising girls. And mostly, I agree with that. Except when it comes to stuff like this.</p>
                    <p className='p'>Moms (and dads) of boys have the huge responsibility to raise them up to be men who WILL uphold the dignity of women at all costs. Who will not take cookies from open cookie jars when they know it is wrong. Who will throat punch, in the name of God, anyone who tries to.</p>
                    <p className='p'>As a mom of boys, I am so thankful it doesn't end with me. But, I am acutely aware that it does start with me.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jun102016;