import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Aug062012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/pens.jpg" alt="Pen drawer" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 aug062012'>
                    <h5>August 6th, 2012</h5>
                    <h3>No Ink Joy</h3>
                    <p className='p'>I really should never watch TV.  Or, at least, not the commercials.   I am a commercial sucker; a TV advertisers dream.  And that has gotten me a lot of unused stuff.</p>
                    <p className='p'>The one exception:  One night back in 2002, while my husband was away working in Israel, I was watching TV with my kids and an infomercial came on about the FoodSaver.  The kids thought that would be a perfect addition to our kitchen and that I would get a lot of use out of one of those neat gadgets.  I agreed and I ordered one.  That actually turned out to be a good investment.   I used that FoodSaver for ten years before I replaced with a newer version.</p>
                    <p className='p'>I can't even count the number of products I have tried because I saw it on TV that turned out to be a flop.  But last week I added to that count.</p>
                    <p className='p'><b>A Pen by Any Other Name</b></p>
                    <p className='p'>Those close to me know that I have a fascination with pens that borders on fetish obsession.  One pen is never ever enough.  There are different pens for different paper and different moods.  And don't get me started on ink color.</p>
                    <p className='p'>There have been times I've felt I needed a little inspiration and have gone out in search of a new pen to write with.  It's almost as if having a new pen will put new thoughts into my head and those thoughts will come flowing out onto my paper.  A new pen can be magical.</p>
                    <p className='p'>OK, so maybe it is a fetish.</p>
                    <p className='p'>My writing joy is not limited to clacking on the keyboard.  In fact, my love of writing came first from penning in my journals.  My handwriting sometimes gives a very clear indication of my writing mood.  I can look back through my journals and spot frustrated entries just by the handwriting.  And haven't we all had times where we've dotted our i's with little hearts or stars?  Well, maybe just us girls.</p>
                    <p className='p'>Last week I saw a Paper Mate commercial on the new InkJoy pens.  Ooooo... Ahhhh...</p>
                    <p className='p'>InkJoy.</p>
                    <p className='p'>The name alone caught my attention.  “Find your InkJoy” the commercial encourages.  They're supposed to be revolutionary.  A product with 'Joy' in the name must evoke feelings of the same name and thereby earning some level of perfection.   I had to find out what makes them so special they score their own commercial.</p>
                    <p className='p'><b>No Joy</b></p>
                    <p className='p'>Pen and paper must connect with just the right amount of friction.  Not enough ink flow causes too much friction and requires a greater amount of pressure by the user.  This user's hand tires quickly with these pens.  Too much ink flow causes…well, too much ink.  And that means a messy page.</p>
                    <p className='p'>This InkJoy pen is hard to explain.  It's like a rollerball without the “scratch” but writes more like a gel pen without the wet mess.  So, in a sense, I guess it's the best of both worlds.  Those worlds.</p>
                    <p className='p'>But what if you prefer a ball point?  That's me; I am a ball point girl.  I don't like a scratchy pen or a wet, messy pen.  Ball points are neither of these.  They're simple.  I pick up my pen quite a bit when I write and ball points don't “carry over.”  What I'm talking about is the fine ink line that connects 'pen up' to 'pen down.'</p>
                    <p className='p'>I'm getting that little, annoying, fine, carry-over line with my InkJoy pen.  Darn.  Not perfection.  No joy.</p>
                    <p className='p'>Sometimes (most of the time, I suspect) the end needs to be differentiated from the beginning.  Otherwise you wouldn't know where a word ends and the next one begins.  All of life would justbeoneverylongsentence.  No Child Left Behind would have to change to Good Luck Kid.</p>
                    <p className='p'>We all need pause.  And we need our pens to as well.</p>
                    <p className='p'>So, into the pen drawer goes the InkJoy pen.  I'm going back to my stole-it-from-a-hotel ball point pen.  Don't judge.  It was used.  And you know you've done it too.</p>
                    <p className='p'>No worries, Paper Mate.  I bought the new ComfortMate ULTRA mechanical pencils too.  Because not everything can be written in ball point.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Aug062012;