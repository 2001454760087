import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Feb132015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/dave-train.jpg" alt="Locomotive" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb132015'>
                    <h5>February 13th, 2015</h5>
                    <h3>Planes, Trains, and Artillery Fire</h3>
                    <p className='p'>Train whistles used to provide the background noise for my home videos. But that was before I lived near an Army air base.</p>
                    <p className='p'>Actually, I live between Hunter Army Airfield (15 miles to the East, by road, not air) and Fort Stewart (40 miles to the Southwest.) There's a train around here somewhere too. I can hear the whistle when the Army sounds are quiet, which isn't often.</p>
                    <p className='p'>The sound of a jet taking off is unmistakable, but it was months of hearing the booming rumble that would literally shake our house before a neighbor nonchalantly explained that was just the artillery fire at Fort Stewart.</p>
                    <p className='p'>It was unsettling at first to hear artillery fire at all times of the day and night. (Especially night.) I'd never been anywhere before where it sounded like the middle of a war. My neighbors - most have lived here all their lives, or at least a long time - are evidently oblivious to the sound now. You get used to it over time, I guess, and it's just background noise.</p>
                    <p className='p'>I think our armed forces, in general, are like that - in the background. Men and women, doing their jobs out of the eye of most of us; learning, preparing, protecting in ways we never see. That's how I think of those sounds now that I've been hearing them for a year. Someone is learning to fire a really big gun in case they need to protect me. Another someone is learning to fly fighter jets or bombers (I don't even know what, I'm very military aircraft dumb) to protect the borders of the nation I live in.</p>
                    <p className='p'>I don't want to be nonchalant about that, ever. I don't want it to become simply background noise. These are sounds of peacetime in action.</p>
                    <p className='p'>I picture these brave people I hear firing the artillery (or piloting one of those jets I hear but can't see) getting off work after a long day and returning to their family for dinner or out with friends for a movie. I know that's a rose-colored view; there are many still being deployed and leaving families behind for far too long to be healthy. But my sheltered, privileged, American-housewife self can absorb only so much reality at a time. And I just learned to be comforted by the sound of peacetime artillery fire.</p>
                    <p className='p'>Baby steps, privileged American housewife, baby steps.</p>
                    <p className='p'>There is something utterly awe-striking in watching the ginormous Army helicopters return to the Airfield or feel the subtle rumble in your gut from artillery fire. Just like seeing Air Force One fly into Wichita for service or the rare sighting of the Stealth Bomber. I am privileged to have lived near Wichita, Kansas, the “Air Capital of The World,” and now live in Savannah, Georgia, where the skies are always full of wonder.</p>
                    <p className='p'>But I still miss the trains.</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb132015;