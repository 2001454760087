import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Mar102014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/stroller.jpg" alt="Stroller" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar102014'>
                    <h5>March 10th, 2014</h5>
                    <h3>Just When You Think You've Seen It All</h3>
                    <p className='p'>I once saw a goat riding in the back of a truck on a busy street in Andover, Kansas. I took a picture. It was funny to me because you just don't see goats riding in the back of trucks in suburban America.</p>
                    <p className='p'>But what I saw last weekend makes that goat sighting seem completely normal and not picture worthy.</p>
                    <p className='p'>As we were leaving the Lowe's parking lot, we were slowed by a couple pushing their stroller ahead of us. They were apparently oblivious that they were holding up traffic by walking in the middle of the driveway.</p>
                    <p className='p'>As we waited for them to get out of our way so we can exit the parking lot, my husband jokingly says they are probably pushing their cat or dog in that stroller. We laugh. I've seen this in Savannah so I was picturing a little toy mini peepeepoo dog.</p>
                    <p className='p'>I look into the stroller as we pass them but it wasn't a little dog. Or even a little cat. Or any variation of either.</p>
                    <p className='p'>It was a pig. Snout and all.</p>
                    <p className='p'>Sadly, I wasn't quick enough to snap a picture. I was laughing too hard to find my phone.</p>
                    <p className='p'>I'm not going to say that now I've seen it all, because I thought I had seen it all the first time I saw someone pushing their dog in a stroller through the park. Obviously I was wrong. There is so so much more to see in this crazy, just-because-I-can world.</p>
                    <p className='p'>I look forward to seeing it all.</p>
                    <p className='p'>And I'm keeping my camera (phone) ready.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar102014;