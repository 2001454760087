import React from 'react';
import './footer.css';


const Footer = () => {

    return (

        <section className="footer-container">

            <div className="footer-left-grid">

                <p>&copy; 2023 Heather Eslick</p>

            </div>


            <div className="footer-center-grid">

                <a href="https://www.facebook.com/heather.eslick" target="_blank" data-hint="Visit me on Facebook">
                    <img src="/images/facebook-icon.svg" alt="Facebook Link" className="social"/></a>
                
                <a href="https://www.instagram.com/heathereslick_writer/" target="_blank" data-hint="View my pictures on Instagram">
                    <img src="/images/instagram-icon.svg" alt="Instagram Link" title="View my Instagram" className="social"/></a>
                
                <a href="https://twitter.com/acjagmom" target="_blank" data-hint="Chat with me on Twitter">
                    <img src="/images/twitter-icon.svg" alt="Twitter Link" title="Chat on Twitter" className="social"/></a>

                <a href="https://www.pinterest.com/acjagmom/" target="_blank" data-hint="Check out my interests on Pinterest">
                    <img src="/images/pinterest-icon.svg" alt="Pinterest Link" title="View my Pinterest" className="social"/></a>
                
            </div>


            <div className="footer-right-grid">

                <p data-hint="Go see Dave's other work">Website by <a href="http://www.daveburkhart.dev" target="_blank" className="footer-h-link">Dave Burkhart</a></p>

            </div>
            
            
        </section>

    )
}

export default Footer;