import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Jun212011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/dia.jpg" alt="Airport" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 jun212011'>
                        <h5>June 21st, 2011</h5>
                        <h3>The Airport</h3>
                        <p className='p'>People watching in an airport…something frequent fliers just no longer do. They miss all the great wonders of people, from the subtlest things to the flamboyant. So many different kinds of people and yet there are some just like us. I suspect there to be one, at least one, that could be my identical twin. Maybe not in looks but in everything else - preferences, habits, inhibitions, dreams and expectations.</p>
                        <p className='p'>There are those weary travelers just looking to get to their destination, passing the time reading their Wall Street Journal or playing solitaire on their iPad. Then there are those who look forward to the destination but thoroughly enjoy the journey, taking in all the sights, sounds and smells the experience has to offer.</p>
                        <p className='p'>Too many hair colors to count, too many different brands of shoes. Some wild, crazy styles of hair, clothes and travel bags. And some of each of those that look as if they are older than the person sporting them.</p>
                        <p className='p'>Some look lonely, like they wish they had someone to talk to. Some look perfectly content to not be bothered by a soul. Some people hold conversations loud enough for the whole airport to hear. And maybe that's what they want.</p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Jun212011;