import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Apr292015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/book2015.jpeg" alt="Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 apr292015'>
                    <h5>April 29th, 2015</h5>
                    <h3>Treasures Tucked Into Books</h3>
                    <p className='p'>I love finding other people's notes and things tucked into the pages of books.</p>
                    <p className='p'>One of my favorites is from a book I inherited. It's an old, torn-up book of Shakespeare's collected works. The book itself is worth only a few bucks, but what's inside is worth way more. You can read about it <Link to="/may132013.jsx" className='back-btn'>here</Link>.</p>
                    <p className='p'>Today I found a list tucked into the pages of a library book. It's a brand new book so, possibly, the person who checked it out before me was the first one to check out the book, the first eyes to scan the pages.</p>
                    <p className='p'>The list is someone's TO DO list, including what appears to be a list of items for a homemade gift.</p>
                    <p className='p'>Grandma - Scented Eye Pillow</p>
                    <p className='p'>-Flax Seeds</p>
                    <p className='p'>-Lavender (dried)</p>
                    <p className='p'>-Fabric (natural)</p>
                    <p className='p'>-Sewing needle</p>
                    <p className='p'>The person also planned to make an apple crisp. Maybe that was for Grandma as well.</p>
                    <p className='p'>I was going to throw the note away, doing my part to maintain the library and all that. But, instead, I put it back for the next person to find. It might become a journal entry for them about finding it or maybe just a reminder to write a letter to their own Grandma.</p>
                    <p className='p'>Guess what is going on my TO DO list now?</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Apr292015;