import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Jul162013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/book-happ-proj.jpg" alt="Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul162013'>
                    <h5>Jul 16th, 2013</h5>
                    <h3>Book Review: The Happiness Project</h3>
                    <p className='p'>I just finished reading <a href="https://www.amazon.com/s?k=the+happiness+project+gretchen+rubin+book&crid=22GZ989NOR89H&sprefix=book+the+happiness+project+gre%2Caps%2C113&ref=nb_sb_ss_ts-doa-p_1_30" target="_blank" rel="noopener noreferrer">The Happiness Project</a>  by Gretchen Rubin. Twice. When I finished it the first time I wished I'd taken notes, so I immediately read it again. Not because I thought there were any new great truths I had missed but more because it was a pep talk that I needed to hear and I wasn't listening the first time. And, since it was my copy, I underlined, commented in the margins, and made smiley faces in places that validated my own attitudes and behaviors.</p>
                    <p className='p'>I also made a list of obscure words so I could look them up - there were seventeen of them, non-repeating. It made me wonder if the author didn't purposely overuse her thesaurus to impart a sense of higher education. Or maybe these are words she uses in every day conversation and is just showing readers how smart she is. In any case it made me feel under educated to need to look up the definition of so many words in a book about a subject as seemingly simple as happiness.</p>
                    <p className='p'>I commend the author for sticking with such a project for a year. (I'm pretty sure I'd lose interest after the first two weeks.) Although I'll admit to not being sure how I feel about her systematic approach, I feel the plan was decisive, the research included is abundant, and I appreciated the well-written, conversational narrative.</p>
                    <p className='p'>The author bases her resolutions to happiness on a few overarching principles she calls The Twelve Commandments. The one I appreciated the most was Be Gretchen. The idea that being true to who you are can result in greater happiness seems so simple and yet so hard to achieve. Add to that commandment a few of Rubin's Secrets of Adulthood (things like, You can choose what you do; you can't choose what you like to do and Don't let the perfect be the enemy of the good) and it becomes easier to let some things go (another of the Commandments.)</p>
                    <p className='p'>Very sound advice. I am now free to not try to like running or gardening just because those seem like more legitimate practices than people-watching or reading books about happiness.</p>
                    <p className='p'>Although I don't feel compelled to start my own Happiness Project, as the author suggests, I did organize my pantry and renewed my determination to make time for friends and pursue a passion. So, overall, I'd say this was a worthwhile read. But probably not twice.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul162013;