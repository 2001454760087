import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Dec122012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/pot.jpg" alt="Cooking pot" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec122012'>
                    <h5>December 12th, 2012</h5>
                    <h3>Eyeball to Measuring Spoon</h3>
                    <p className='p'>I have been watching the Food Network for years. It's how I learned to cook outside of a box. (Or semi outside out of a box if referring to Sandra Lee.) But the other day I made a disturbing connection while watching one of my new favorite TV stars, Melissa d'Arabian,  assemble a marinade …”about a tablespoon” does not even come close to an actual measured tablespoon.</p>
                    <p className='p'>This isn't really a new thing. In fact, in Rachael Ray's kitchen, “a palm full” is a unit of measure, “eyeballing it” constitutes near accuracy, and a coffee mug full is “about a cup.” In a world where there is no such thing as standard or metric units of measure, Rachael Ray is queen. And when we're talking about “season to taste” this isn't a bad thing.</p>
                    <p className='p'>The bad thing is that our eyeballs have grown to be size of dinner plates. We've learned to “eyeball” a serving size when dishing up our food with our plate-sized eyeballs and, as a result, our waistlines have expanded to match them. Mine certainly has.</p>
                    <p className='p'>I never eat only two cups of popcorn. It's more like six. Having eyeballed my salad dressing for years, I could have sworn I wasn't too far off from the recommended two tablespoons. I was way off.  I couldn't even tell you the last time I actually measured out the peanut butter for a PB & J sandwich but I'm sure it is more than the recommended serving size of two tablespoons. When the recipe calls for a half a cup of cheese on my casserole and I've used the entire two cup package … well, I must have eyeballed it.</p>
                    <p className='p'>Of course none of this would matter if I would just move more.</p>
                    <p className='p'>But then look at Ina Garten, the Barefoot Contessa. OK, first I have to say something about that title. A contessa wouldn't be cooking her own food, let alone doing it barefoot. And Ina isn't even Italian. She's from New York! But, I digress.</p>
                    <p className='p'>Ina measures everything. Everything! Even the salt and pepper. Even “a pinch” is collected with a measuring spoon. (I actually have a set of measuring spoons that say dash, pinch, and smidgen that I've never used.) This is probably because, out of all the TV celebrity cooks, Ina is really the only baker in the bunch. And baking requires precise measuring. A smidgen too much baking powder and your cake will fall like the Dow after a Presidential election.</p>
                    <p className='p'>I like to cook but I am not a baker. I like to eat but I need to eat like a baker, apparently . Measure, don't eyeball.  Don't eat like you cook. Got it. Problem solved.</p>
                    <p className='p'>Oh, and move more.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec122012;