import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Dec312016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/fireworks-in-the-sky.jpg" alt="Fireworks" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec312016'>
                    <h5>December 31st, 2016</h5>
                    <h3>Happy New Year</h3>
                    <p className='p'>This is my favorite week of the year. I am one of the fortunate who get to live this week like days don't exist, only moments.</p>
                    <p className='p'>Most years, on this week between Christmas and New Year's, I often have to stop and think about what day it is. And that's only if I have to, or want to. Usually, I don't care.</p>
                    <p className='p'>This is the week of eating when our stomachs, rather than the clock, tells us it's time to eat. It's the week of reading, and lounging, and watching movies, and catching up on all the things we said we'd do when we got the time.</p>
                    <p className='p'>This is the week of no schedules. The week of staying up late and eating too many carbs. The week to spend time with family - really spend time - and some of the rules go on pause. This week, we drove two hours just to go to a zoo, because we live where it's still 75 degrees in December.</p>
                    <p className='p'>While the guys spent time at the dining room table, laying down some puzzle pieces (I am not allowed near the puzzles - it may or may not have something to do with stealing pieces,) I spent time behind a canvas, laying down some paint (which I'd rather do anyway.)</p>
                    <p className='p'>We've watched everything from classic Christmas movies to football games. We've eaten Christmas dinner followed by days of leftovers and most of the pie and cookies, including the treats our neighbors brought over.</p>
                    <p className='p'>And we've had the good fortune of an older son willing to teach a younger son to drive.</p>
                    <p className='p'>My favorite week of the year always ends with my least favorite part of the year - the dreaded RESOLUTIONS. It's probably my least favorite because I never accomplish what I wanted to by the end of the year.</p>
                    <p className='p'>As this week comes to a close, and we prepare to ring in a new year, it's easy to think on all the things I would have done differently, could have done better, wished would have not happened at all. There weren't many things during the year I can say went exactly as I'd planned for them to go.</p>
                    <p className='p'>And thank God for that. I would have royally messed up some things.</p>
                    <p className='p'>In the midst of any disappointment or regret is the knowing that none of it is a part of my story alone. Everything that happens to me also impacts the lives of those around me. And I am thankful God includes us in each other's stories. He's got it all figured out before we are even aware there's anything to be figured out. We just need to remember where to look.</p>
                    <p className='p'>I'm not one for making resolutions, as I've written about before, but I think making goals are important to growth. On this last day of 2016, as I think about my goals for the coming year, I'm making a mental list of a few unimportant things that need to be left in 2016, a few things that I'm taking with me into 2017 to continue to work on, and a few new things to add in the next year.</p>
                    <p className='p'>My goals for the new year include more of certain things - write more, read more, move more, love more - and less of others. Empty carbs are the only thing in the less column so far, but I think my husband would like to add a few things to it.</p>
                    <p className='p'>For now, I'm going to enjoy the last few hours of this year. There's carbs to eat, champagne to drink, and an annual family poker game to be played. Tomorrow is a new day, a new year.</p>
                    <p className='p'>Happy New Year!</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec312016;