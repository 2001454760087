import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Jan312014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/yellow-brick-road.jpg" alt="Ruby slippers" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan312014'>
                    <h5>January 31st, 2014</h5>
                    <h3>Our Move To OZ</h3>
                    <p className='p'>Thirty days in and this cross-country move is starting to feel more like reality and less like an extended vacation. Thirty days in and I still mutter<i>I can't believe we moved </i>and<i>I can't believe we live here</i>.</p>
                    <p className='p'><b>The Yellow Brick Road</b></p>
                    <p className='p'>All packed up and ready to hit the road, we stood in our kitchen, embraced in a hug of sadness, feeling the surreal yanking down a path paved, brick by brick, with decisions we had made. There was no stopping it, no turning back. The only move to be made was the one forward, one foot by painful foot in front of the other. The only thing that got us out that door and into that loaded-down car was the comfort that although the bricks may have been ours, the paving was God's alone.</p>
                    <p className='p'>We traveled a different route than the one we had taken two months prior, one with less toll booths and more billboards. From the signage in Missouri one can deduce that the state is either filled with perverts and pyromaniacs or wants to be. And I believe Tennessee is the prettiest state in our Nation.</p>
                    <p className='p'>The most unsettling thing about moving to another part of the country is the unknown - the imaginary monsters. But the thing about imaginary monsters is that they are always scarier in our minds than they are in reality.</p>
                    <p className='p'><b>Lions and Tigers and Bears … Oh My!</b></p>
                    <p className='p'>After the last hurrahs of the new year - New Year's Eve fireworks and my birthday - the daunting task of getting the boys enrolled in school reared its ugly head. Due to the monster that is public education here, we elected to enroll the boys in a private, Christian school. This turned out to be a little like building a sand castle one grain of sand at a time. (I'm told that's just the pace at which things are done in the South.)</p>
                    <p className='p'>On January 13th, donned in their still-smells-new school uniforms, our boys became SCPS Raiders. After more than thirteen years, I am no longer an ACJagMom (but haven't yet gotten up the nerve to change my email.) Although the adjustment has been a little steep, they like the school and are doing well. And for the money we are spending, they had better graduate academic superstars! I never fully realized how fortunate we were to have been in a top-notch public school district.</p>
                    <p className='p'>The monsters of the simple daily tasks like grocery shopping and getting gas were conquered by the Kroger store just down the street. Just take the “marketplace” out of Dillons Marketplace, move the aisles around like a chess game, and you've got our local Kroger store, except with an entire aisle of wine. Oh yes, I do like grocery stores in the South. I have yet to find my new Hair Wizard and both me and my grey-speckled regrowth desperately miss my old Wizard.</p>
                    <p className='p'>On the day of my birthday, we got an offer on our house. Not a great one; it took some back-and-forth, a little swallowing, and a whole lot of letting go. In the end, the monster of a house not sold was slayed and out of its not-yet cold carcass crawled a new monster - the one of finding a new house. Luckily, that little monster didn't have much time to grow as we found one fairly quickly.</p>
                    <p className='p'><b>Moving to OZ</b></p>
                    <p className='p'>One of my greatest fears was that moving to my love-affair city, living real life in a place I had previously only visited as a tourist, would diminish its appeal. Sometimes what makes something mysterious and attractive is the unfamiliar and, when its ugly secrets are revealed, it's no longer a thing of beauty.</p>
                    <p className='p'>Last weekend, for the first time since moving here, we ventured downtown - to where I first fell in love with Savannah. The streets canopied with big Live Oaks dressed in Spanish Moss, the historic buildings and house museums, and the river that carries the big barges I've spent many a digital moment trying to capture - all a familiar, welcoming hug. And that's when we look at each other with a look that carries both the I can't believe we live here and the<i>This is good. This was the right move.</i></p>
                    <p className='p'>That doesn't mean that there aren't secrets behind the curtain, like the Great and Wonderful Wizard isn't so great and wonderful but, for now, I'm dancing with the Munchkins and trying to avoid the Wicked Witch and her flying monkeys.</p>
                    <p className='p'><b>We Are Not In Kansas Anymore, Toto</b></p>
                    <p className='p'>The biggest difference? You'd think it was the weather. But so far, not so much. I've seen 18 degrees here, something I didn't expect to see but didn't consider it out of the realm of possibility. But a severe winter storm? With snow and ice? That one had me scratching my head. Did the Kansas winter follow me here? Please don't tell anyone. I would have some pretty angry Southerners looking to ship me back to Kansas.</p>
                    <p className='p'>As I write this post, the boys are in their third snow day… Snow Day… In <i>Savannah</i>. Those things don't go together, right? We didn't end up getting any snow so, technically, it's not a snow day. Technically, it's a Threat-Of-Snow Day. That might be a bigger difference: school is canceled before the storm actually hits, rather than after. Maybe that's because this part of the country is more used to hurricanes than snow storms. But, still, that isn't the biggest difference. So, what is?</p>
                    <p className='p'>Fireworks in January?… Nope</p>
                    <p className='p'>No basements?… Nope</p>
                    <p className='p'>Rarely any wind?… That is a blessing for this wind weeny, but no, not the biggest difference.</p>
                    <p className='p'>The biggest difference for me is having my husband home to share life with. Every day. Everything else melts away in the light of that. It was my prayer for so many years that I didn't think God was listening. Like the wizard behind the curtain, I couldn't see it. But brick by brick, the path to this answered prayer was being built.</p>
                    <p className='p'>Dorothy and I are similar but have opposite stories:</p>
                    <p className='p'>Dorothy was whisked away to a dreamland where she made new friends but just wanted to go home to her family.</p>
                    <p className='p'>I climbed aboard the crazy train to OZ, family in tow, and left behind the friends I shared my life with. Someday, probably in the next few weeks, I'll take off the ruby slippers. But I'll keep them tucked away because who knows where the next tornado may take us.</p>
                    <p className='p'>(I know, it was a cyclone in L. Frank Baum's story. But I'm from Kansas, and this is<i>my</i> story.)</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan312014;