import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Jun262011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/cigar.jpeg" alt="Cigars" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 jun262011'>
                        <h5>June 26th, 2011</h5>
                        <h3>Savannah…Smooth. Sultry. Stogies.</h3>
                        <p className='p'>Savannah will always be the place where I saw my first jellyfish, took my first ride on a water taxi, and smoked my first cigar. (The jellyfish was actually at Hilton Head, but same trip.)</p>
                        <p className='p'>Savannah is an old city with a lot of history. The feeling there is one of the most relaxed, non-hurried, mellow cities I've ever visited. The patio seating, cigar shops, park benches, outdoor music and rooftop bar settings invite you to stay awhile, hang out, put your watch away and just go with it. Spend some time there and you'll likely meet a new friend, see something unexpected, and most definitely sweat a little. Or a lot.</p>
                        <p className='p'>There's a sense of secrets that are understood but rarely talked about; a history not completely told. If only the moss-covered trees could talk, I'm sure they'd tell stories that would make you laugh, maybe cry, give you the greatest picture of how things really were & how they are. And most definitely make you want to hang around awhile or visit again very soon.</p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Jun262011;