import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Dec262014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/not-noel.jpg" alt="OLEN" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec262014'>
                    <h5>December 26th, 2014</h5>
                    <h3>Because That's Just The Way We've Always Done It</h3>
                    <p className='p'>Traditions. Some make sense, some don't. Some are passed down from generation to generation, some are only one generation old.</p>
                    <p className='p'>A couple weeks before Christmas this year, I was having a very hard time getting into the spirit. Part of it was probably being in a new city and a new house and not yet feeling “settled.” I'm sure the warm, humid weather had something to do with it too. It's hard to get in the Christmas spirit when you sweat putting up the outside lights.</p>
                    <p className='p'>If it weren't for our family traditions, Christmas would never feel like Christmas to me. And yet, I seem to forget that when I need it most. This year was the first in twenty-five Christmases that we were not celebrating with my in-laws. It was going to be just the six of us, far far from home. So more than any year before, <i>this</i> is the year I needed traditions.</p>
                    <p className='p'>Many years ago, I worked with a woman who told me of a tradition she had for “The Twelve Days” of Christmas for her girls. Each day for the twelve days leading up to Christmas Day, she gave them small, inexpensive gifts. At the time she told me of this tradition, her girls were in college in another town but she still mailed them their gifts to open each day they were away. The gifts changed over time to match their age, and as college students she got them stamps and sticky notes, pens and stationary.</p>
                    <p className='p'>That very year, I instituted the Eslick Twelve Days tradition. I'm not exactly sure what year the tradition of starting Day 1 with Tic Tacs stuck, but the boys have been receiving packs of Tic Tacs on Day 1 for many years and I think they'd be disappointed if I changed that now.</p>
                    <p className='p'>This year, like a few before, I've considered skipping The Twelve Days for the sake of my own convenience. And again this year I was reminded that “it's tradition.”</p>
                    <p className='p'>I realized this year, after my oldest son took away the trash bag I was planning to collect the discarded wrapping paper and bows as they flew off the packages, that the mess is<i>tradition</i> as well. Just like decorating for Christmas on Thanksgiving weekend, sending Christmas cards, making monkey bread for breakfast Christmas morning, opening stocking stuffers last, doing family Christmas puzzles, driving around looking at Christmas lights, the NOEL stocking holders that mysteriously rearrange themselves, and the countless other traditions I've taken for granted.</p>
                    <p className='p'>Traditions are what make family<i>family</i>. They are what bind us together, what makes us unique, what sets us apart from everyone else. Some families share a few of our traditions. Some have traditions we've never had, like caroling on Christmas Eve or going to the movies on Christmas Day. But no other family holds all of our traditions, and ours don't exactly match anyone else's.</p>
                    <p className='p'>God made me to crave change in some things. That's part of the reason my hair has been so many colors over the years. But Christmas is Christmas anywhere we take our traditions.</p>
                    <p className='p'>I wanted to start a new tradition this year, our “Georgia” Christmas tradition, and have s'mores on Christmas Eve. But it had been raining for two days and was too humid to get the fire going. We managed to get it done Christmas night instead, so I guess that'll be our s'mores day instead. That's just the way it worked out.</p>
                    <p className='p'>And years from now, when asked why we have s'mores on Christmas night, we'll say, “Because that's just the way we've always done it.”</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec262014;