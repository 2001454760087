import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Jun222011b = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/lav-door.jpg" alt="Lav Door" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 jun222011b'>
                        <h5>June 22nd, 2011</h5>
                        <h3>The Lav</h3>
                        <p className='p'>The airplane lavatory. Oh my…I just confirmed my icky feeling for airplane lavatories.</p>
                        <p className='p'>I seriously think airplane bathrooms are worse than Porta Potties. What do hugely obese people do? How do they fit in there? That bathroom is literally two feet wide! So don't even think about raising your arms while you're in there.</p>
                        <p className='p'>And it's pretty obvious that people don't sit ON the seat, but instead hover OVER the seat and possibly just squat “somewhere” in the small rectangular space because, not only is the seat completely drenched but, so is the floor!! I felt the need to wipe my feet when I stepped out…and I did. 🙂</p>
                        <p className='p'>Apparently, though, I made the decision to brave the plane potty just in time because when I came back to my seat the “stay seated with your seat belt securely fastened, we are entering turbulence” announcement was made. Whew…no pee pee dance in my overpriced, butt-cheek-to-butt-cheek-with-your-neighbor airplane seat.</p>
                        <p className='p'>And, in case you forget, the flight attendants are quick to remind passengers that disabling, tampering with or destroying lavatory smoke detectors will get your butt thrown off the plane!</p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Jun222011b;