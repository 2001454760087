import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Mar262012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/jeans.jpg" alt="Jeans" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar262012'>
                    <h5>March 26th, 2012</h5>
                    <h3>Monday Morning Self-Reflection</h3>
                    <p className='p'>Writers often draw from their own lives and experiences when they put pen to paper. Or fingers to keyboard, whichever the case may be. One of my favorite authors has incorporated many facets of her own life into her stories. I enjoy picking up little tidbits in her stories from what I know is taken from her own personal life.</p>
                    <p className='p'>Recently I read a blog post by another author that challenged me to self-reflection. The questions posed were 1) what feeds your soul? and 2) what sucks the life out of you?</p>
                    <p className='p'>I thought it would be interesting to find out what I had to say about this. Here's what I came up with.</p>
                    <p className='p'>What Feeds My Soul:</p>
                    <p className='p'>-Good coffee <br />-Slow mornings <br />-Reading a good book by the fire <br />-Lingering kisses <br />-Songs that sing what I feel <br />-Relaxing with all my kids gathered together (These times are getting fewer and farther between) <br />-Friendships with the people who know my soul and love me in spite of myself <br />-Hugs <br />-Cooking <br />-Clear messages from God that are direct answers to prayer <br />-Starting a new project (Unfortunately, this is why I never master any one thing) <br />-Skinny jeans</p>
                    <p className='p'>What Sucks the Life Out Of Me:</p>
                    <p className='p'>-High-stress, overly dramatic people<br />-People who talk too slow (or move too slow)<br />-Dieting<br />-Yard work<br />-Not enough sleep<br />-My own indecision<br />-Wind<br />-Skinny jeans</p>
                    <p className='p'>Surely this is not an exhaustive list but it makes me feel pretty doggone balanced. And even if I'm not, my protagonist most likely will be. That's good enough.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar262012;