import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Sept082014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/rosie-the-riveter.jpg" alt="Rosie the Riveter" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept082014'>
                    <h5>September 8th, 2014</h5>
                    <h3>Ten Things You Can Still Do With One Arm</h3>
                    <p className='p'>When I was in the sixth grade I broke my wrist and had to wear two different casts for fourteen weeks. It's a long story, but to this day I blame it on my brother. And a horse named Kid.</p>
                    <p className='p'>I had managed to make it all these years since (nearly 30, if you're counting) without breaking another bone.</p>
                    <p className='p'>Until Friday. When I tripped over the sleeping dog. In the dark. With my arms full of the things I was carrying into the house.</p>
                    <p className='p'>It happened so fast, I was on the ground before my brain had time to trigger my muscles into action to catch myself. And I think I closed my eyes.</p>
                    <p className='p'>I landed on “all fours,” just not the right four. I hit both knees and both elbows on the hard slate-tile floor. Yes, it hurt. And yes, I cried. I almost threw up.</p>
                    <p className='p'>Long story short, after a Saturday morning at two Urgent Care facilities and a set of X-rays, I'm sent home with my broken elbow in a splint and sling.</p>
                    <p className='p'>After a weekend learning to function without the use of my left arm, I came up with this list of things that can be done with only one arm. And occasionally a foot.</p>
                    <p className='p'>10 Things You Can Still Do With One Arm:
                        <ol>
                            <li><b>Make The Bed </b>- I'm saying this can be done. I looked at the mangled mess of sheets and pillows and decided I could get it done if I wanted to. I didn't want to. Because there's always having to unmake it later with only one arm and really, who needs that?</li>
                            <li><b>Shower </b>- Granted, this would have been easier with a little help from the hubby, but he had just come in hot and sweaty from mowing and I knew the water temperature thing would be an issue. I do not take cooler than HOT showers. Ever. Automatic shampoo, conditioner, and body wash dispensers are now on the shopping list.</li>
                            <li><b>Dress </b>- First, let me say that this is just plain harder if you're a woman. Getting a bra on with one arm is a little like a dog chasing and catching its tail. The rest is an extraordinary feat of acrobatics with a lot of tugging and pulling that leaves you gasping for breath by the end.</li>
                            <li><b>Drive </b>- An automatic transmission makes this one a breeze. The hardest parts are getting the door closed (or shifting if you're without you're right arm) and getting the seatbelt fastened. Besides, as every teenager knows, it's just not cool to drive with both hands anyway.</li>
                            <li><b>Type </b>- It's a little slow (this post has taken the better part of two episodes of Pioneer Woman minus the commercials) with regular use of the backspace button, but it gets done.</li>
                            <li><b>Pump Gas </b>- I could have done this but I didn't have to. I have teenage boys.</li>
                            <li><b>Buy Groceries </b>- If you don't buy a lot of heavy stuff, the cart is actually pretty easy to steer with one hand. And for all the times the sacker has asked if you would like some help out with your groceries and you have declined with two good arms, you'd think it would be obvious that you're going to need help lifting those bags, especially the one with all 24 cans in it. It's not obvious to anyone but you.</li>
                            <li><b>Vacuum </b>- Normally a one-arm job anyway unless you have a Dyson that “never loses suction,” which I have. Just watch out for small animals because getting those out of the vacuum canister is always a two-arm job.</li>
                            <li><b>Laundry </b>- You can claim injured and get out of this one indefinitely, but I found it's easier to kick the laundry basket all the way to the washer than wait for two available hands attached to the same body. Another great use of feet with this one is opening and closing dresser drawers.</li>
                            <li><b>Play Catch With the Dog </b>- Ok, I didn't do this one. But I could have if it wasn't raining all afternoon.</li>
                        </ol>
                    </p>
                    <p className='p'>Now, for all the things I discovered that could be done one handed, the one thing I found that I couldn't do overshadowed them all - pulling up my hair!</p>
                    <p className='p'>This is seriously distressing. Especially because it's September in Savannah with the heat index hovering somewhere between hot and infernal. And don't think the rain helps. Rain just makes the hot stick to you. And then the hair sticks to the hot.</p>
                    <p className='p'>After fourteen failed attempts at hair wrangling, I went with the headband and just stayed in out of the sticky heat.</p>
                    <p className='p'>The good news is that today's visit to the orthopedic doctor didn't result in a cast. It turns out my fracture is somewhat minor, aligned perfectly, and just needs some down time to heal.</p>
                    <p className='p'>I am, of course, hoping never to break another bone. Thankfully,  I've learned that leaving sleeping dogs lie isn't always safe, especially when it's dark and your hands are full. 😉</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept082014;