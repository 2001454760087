import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Feb212012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/lent.jpg" alt="Lent cross" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb212012'>
                    <h5>February 21st, 2012</h5>
                    <h3>Lent Minus Facebook Equals Contrition</h3>
                    <p className='p'>“For everything there is a season, and a time for every matter under heaven…”  Ecclesiastes 3:1</p>
                    <p className='p'>Two years ago I felt a calling to write. And, for a while, I did. Certainly not what I thought I was being called to write but writing what I considered “practice” for whatever it was God was going to have me write.</p>
                    <p className='p'>And then, I ran out of things to say. And the writing well ran dry. And so I waited. I just knew that any day God would drop into my lap the idea for a book and all the pieces and parts I'd need to put into that book. And I waited some more.</p>
                    <p className='p'>And I'm still waiting.</p>
                    <p className='p'>And, in this waiting, I began to evaluate whether or not there was a problem with the direction I was taking. I have quit my business, spent hours upon hours reading about the dos and don'ts of a writing career, taken courses in creative writing, and continued to wait on the idea to form or be dropped in my lap. Every day my husband would kindly ask, “Did you get any writing done today, Honey?”</p>
                    <p className='p'>I hadn't.</p>
                    <p className='p'>Slowly I started to realize that I was faithful to a lot of other things in my day but not to my writing. And anytime I attempted to write and got bored, stuck or blocked, I would jump on Facebook. Facebook became my constant companion, my entertainment, my outlet, my encouragement, and where I looked for validation. It has been first and last in my day, every day. In essence, it has become an idol. Yikes!</p>
                    <p className='p'>When I wondered why God wasn't directing my writing, it never even dawned on me that I was never giving him the chance. In my waiting for direction, my devotion was directed at Facebook. I wouldn't have heard the still, small voice. I wasn't listening for it.</p>
                    <p className='p'>So on this Fat Tuesday, even not being Catholic or having ever given up anything for Lent, I recognize my dependence on Facebook and am committing the next forty days to hearing that still, small voice. And this time I will be listening.</p>
                    <p className='p'>I am denying myself Facebook time during Lent. I am reclaiming that wasted time in my days. As a first act of forward motion on this writing train, I am submitting an article in a writing competition and the deadline is May 1st. That leaves me roughly 8 ½ weeks to write, revise, revise again, and submit. I need to get started.</p>
                    <p className='p'>Anyone who knows me very well, gets how huge this decision is for me. I am a Facebook junkie. But I need to stop turning to Facebook to fill the gaps in my day and start looking to the One who puts the gaps in my day. I'm pretty sure he puts them there for a reason.</p>
                    <p className='p'>In the interest of full disclosure (and so you don't think I've completely lost my marbles) I should also mention that I have linked my Twitter account to Facebook so, should I have something to say, I can still say it without having to log on to Facebook. But if you 'like' a post, I won't know it. All my Facebook messaging is turned off. I do have Facebook on the Flipboard app on my iPhone but it seems to have a mind of its own and feeds me only what it feels like is important.</p>
                    <p className='p'>So if you are having a party or you're unexpectedly pregnant, please email, text, call or DM me on Twitter, because if those pieces of important information are only on Facebook, I won't know about it until after the helium is gone and the balloons hit the floor. Or at least until Easter.</p>
                    <p className='p'>Happy Lenting Y'all!</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb212012;