import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jan052015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/later.jpg" alt="Clock" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan052015'>
                    <h5>January 5th, 2015</h5>
                    <h3>Looking Back To Look Forward</h3>
                    <p className='p'>Today is the last day of our Christmas break. Tonight we head to bed at a reasonable time. Tomorrow morning we wake early to our alarms - which we haven't done since the middle of December.</p>
                    <p className='p'>Tomorrow I will finish up my list of goals for 2015. Today, I reflect back on this first year in our new home state.</p>
                    <p className='p'>I search my calendars - electronic and paper - to see what we did all year. There were a few highlights, such as our first Savannah St. Patrick's Day, a trip to Jacksonville for a US Soccer match, visits from friends and family - all of which were great times.</p>
                    <p className='p'>Looking back, I can clearly see what needs to happen in this new year: more great times, more highlights, and more effort to have them. In an entire year in a new place, I can come up with only a handful of highlights, and I know there needs to be more of them because this life is so very short - just a breath of our entire existence - and there are things to see, places to go, experiences to be had.</p>
                    <p className='p'>I have the worst habit of procrastinating, even with the fun stuff. I put off memory-making, relationship-building opportunities until everything is just right, which it never seems to be.</p>
                    <p className='p'>For today, I am not going to worry about the Christmas decoration tubs that still need hauled up to the attic, or the sticky, glittery floors. Today is the last day before the boys' semester two, with all its homework and deadlines and test prep.</p>
                    <p className='p'>No one is guaranteed tomorrow. So, today we live<i>this</i> day.</p>
                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan052015;