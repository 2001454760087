import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const May272016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/radio-2.jpg" alt="Car radio" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may272016'>
                    <h5>May 27th, 2016</h5>
                    <h3>I Am Mom, Hear Me Roar</h3>
                    <p className='p'>I have this thing with music. It's my 'go to' when I'm in a mood. My mood steers the music I listen to, sing to, worship to, cry to, scream to. Maybe it should be the other way around, and I guess sometimes it is, but not today.</p>
                    <p className='p'>On days when there is a roar in my gut clawing to get out, the only way to loose it from its cage is to climb inside with it. Hard rock music is the key in the lock.</p>
                    <p className='p'>Most of the time, I don't even hear the lyrics. The throaty, guttural, roaring voice fills the cage, but there are no words, because words just aren't sufficient enough to calm the beast like the pounding rhythm can.</p>
                    <p className='p'>This is remnants of my formative years, I know; the effects of growing up with 80s heavy metal blaring from the car stereos of everyone I knew. But it's a part of me that I never wish to change.</p>
                    <p className='p'>On angry days like today, Disturbed's Warrior is the battle cry of this angry mama. I am one with the Warrior inside. Yes, I am angry today, but the anger will not win. For now, that beast has been let out and slain.</p>
                    <p className='p'>I am mom, hear<i>me</i> roar.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May272016;