import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Feb112016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/plant-roots.jpg" alt="Plant roots" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb112016'>
                    <h5>February 11th, 2016</h5>
                    <h3>Blooming Where You Are Planted</h3>
                    <p className='p'>Two years ago today, a bunch of strangers were packing up all our earthly belongings into cardboard boxes, to load them into a big (really big - we have too much stuff) truck, to haul them 1200 miles to our new home.  I still find myself muttering<i>I can't believe we moved.</i></p>
                    <p className='p'>Moving is hard. Yes, it's physically hard, especially if you're the one doing all the heavy lifting. But, more than that - far more than that - it is mentally hard. Moving is more than changing the location of your stuff; more than changing the grocery store where you buy your food; more than the difference in language, culture, or the weather. It is an uprooting.</p>
                    <p className='p'>Putting down roots takes great care and a good amount of time. Every season with a friend, every town festival, every job change or neighborhood change, every school fundraiser or band concert, every putting up of the Christmas tree and taking it back down again - day by day - we grow new roots and grow others deeper in the place where we are.</p>
                    <p className='p'>Now, I'm not a gardener (and anyone who knows me will tell you that is a huge understatement,) but nearly every plant I've ever transplanted has died. I am so not kidding. And I'm pretty sure it has something to do with the roots. When you pull a plant out of the pot it's been in for a while, no matter how careful you are, the roots rip a little. And it takes time to recover.</p>
                    <p className='p'>The same thing happens when you're rooted deeply where you are and you have to rip up your roots to transplant everything into new ground.  It takes time for your life to recover.</p>
                    <p className='p'>Although I had done some packing of our belongings, arranged for the termination of household services, had a <s>pot to pi</s> home to land in on the other side - done everything I could do to be physically prepared - I was not mentally prepared. I thought the dirt would be like home, the fertilizer would smell the same, and the plants around me would be the familiarity I needed. But this was not the case.</p>
                    <p className='p'>For the first year, I was undernourished - starving even - and wilted. I tried (although pretty wimpily) to do things, go places, talk to people, and get involved, but the mourning in my soul clouded my vision and stunted my growth.  Yeah, I know, I should have just put on my big girl panties and left the pity party. Trust me, I wanted to. I tried. But moving is hard.</p>
                    <p className='p'>Well, they say hindsight is 20/20. Having come out of that first year, I can tell you that my problem was I never took my eyes off<i>me</i>. I never looked up. Negative emotions tend to plunge me right straight down the dark hole of narcissistic self pity. When I did bother to look up, I started to see all that God had set before me, everything waiting for my attention.</p>
                    <p className='p'>It started when we finally found our church home. I cannot stress enough to anyone who has just moved or is about to move how vitally important it is to first, find your church home, and then build the rest around that. God had things for me to do, but I had been too busy sulking to notice. I love the saying<i>bloom where you are planted</i> (as corny as it sounds, it's actually Scriptural) because I'm finding that, not only is it possible to grow in new soil, but God will use the new soil to grow new roots.</p>
                    <p className='p'>I still miss home, my people, the really great grocery store just down the street, and all my favorite restaurants … the lighter traffic, the Warren movie theater, the sunsets. And wine sold in grocery stores has nothing on Groves or Jacobs, let me tell you.</p>
                    <p className='p'>But Savannah has long been my love-affair city because there is so much to love here. When the rest of the world is belly-aching about blizzards, ice, hurricanes, heat waves, or crazy wind (I do not miss that Kansas wind,) Savannah is safely tucked in the armpit of the coast. I am still in awe when I drive down streets canopied with Live Oaks cloaked in Resurrection Fern after a good rain. I still love watching the container ships coming and going along the river. And even though I'm not a beach person, I like having the option of going to the beach any day of the week.</p>
                    <p className='p'>So, even though moving is hard, when you look for where God is working and lean into being a part of it, the blooms are bigger and more vibrant than they've ever been.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb112016;