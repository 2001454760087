import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Jul222013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/dusty-heather.jpg" alt="Reunion" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul222013'>
                    <h5>July 22nd, 2013</h5>
                    <h3>A Friend's a Friend Forever</h3>
                    <p className='p'>I recently had a reunion with my best friend from middle school. After many thwarted attempts to get together, we hadn't seen each other in twenty-four years so we had a lot of catching up to do.</p>
                    <p className='p'>And it was great.</p>
                    <p className='p'>In the span of five hours, we caught each other up on the last two decades of our individual lives. Then we promised it would not be as long before we caught up again.</p>
                    <p className='p'>Later that night I reflected on our short time together. When the afterglow of our reminiscing (thanks due, in part, to the pictures her mom sent with her of our childhood together) started to fade I found myself sad that we live so far apart (her in California and me in Kansas) and can't share more of our lives together.</p>
                    <p className='p'>Something tells me that if we moved next door to each other we'd pick up right where we left off twenty-something years ago. Minus the loud clothes and bad hairdos.</p>
                    <p className='p'>We used to be inseparable. And when we were separated after she moved, we clung to the communication of our time – letter writing. Eventually life interrupted as it so often does in long-distance friendships and we fell out of touch for some time.</p>
                    <p className='p'>And then a new form of communication emerged - Facebook. There's a lot to be said for this technology that made a fortune for its creators and enriched the lives of millions around the globe.</p>
                    <p className='p'>But Facebook provides merely pieces of the big-picture puzzle of our lives. And as much as I appreciate this legal form of voyeurism, it takes a physical presence to maintain most friendships. Even people in our lives that were once friends, without face-to-face time, eventually slip into past acquaintanceship.</p>
                    <p className='p'>I learned some important things about friendship this week: First, get pictures. Because you'll never remember it all.</p>
                    <p className='p'>Second, look at the pictures from time to time. Together if you can. Remembering the moments that made you friends strengthens the bond.</p>
                    <p className='p'>And last, no relationship is effortless. It takes work to maintain friendships. You have to be intentional and take the time. Time to listen. Time to laugh. Time to love. And take new pictures.</p>
                    <p className='p'>Friendships either grow and strengthen or they don't. It is comforting to know the good ones can withstand the test of time. Or miles. Or a lot of both.</p>
                    <p className='p'></p>
                    <p className='p'>“Friendship is unnecessary, like philosophy, like art… It has no survival value; rather it is one of those things that give value to survival.” - C. S. Lewis</p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul222013;