import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const May312016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/boots-summer.jpg" alt="Hail storm" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may312016'>
                    <h5>May 31st, 2016</h5>
                    <h3>Boots in Summer</h3>
                    <p className='p'>These pictures popped up yesterday in my Facebook memories from a few years ago. We had a major hail storm that did several thousands of dollars damage to our house and decimated my yard plants. I had to shovel the hail to find the power cord for the pool cover pump.</p>
                    <p className='p'>The snow boots with capris look was one I had not worn before - or since. In fact, since moving to Savannah I have not even seen those snow boots. It doesn't snow here.</p>
                    <p className='p'>Or hail.</p>
                    <p className='p'>Although we are nestled in the armpit of the East coast, hurricanes are not out of the realm of possibility. I should probably get boots for my hurricane kit, just in case.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May312016;