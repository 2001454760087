import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Jan042016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/coffee-cup.jpg" alt="" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan042016'>
                    <h5>January 4th, 2016</h5>
                    <h3>Moping Mom</h3>
                    <p className='p'>It’s been four years since he has lived under our roof. Eight since he moved away for college. You’d think I’d be used to him leaving by now.</p>
                    <p className='p'></p>
                    <p className='p'>Nope.</p>
                    <p className='p'></p>
                    <p className='p'>Dropped my big kid at the airport and have been moping ever since. But this – something as small and stupid as the coffee cup he left on the porch, where he sat just a few short hours ago – makes me want to run to the airport and drag him back. So I can tell him how proud I am of him and how much I love him and how I hate living so far away.</p>
                    <p className='p'></p>
                    <p className='p'>And so he can pick up his dishes.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan042016;