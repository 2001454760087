import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Jan042013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/thirty-something.jpg" alt="" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan042013'>
                    <h5>January 4th, 2013</h5>
                    <h3>Rockin' the 4-0</h3>
                    <p className='p'>Today is the day. My birthday. My 40th birthday. Ugh.</p>
                    <p className='p'>I can't say I've been thrilled about this birthday. It seems as though it's a milestone for a mile I have not yet finished. I'm not ready to be forty. I haven't done all the things on my bucket list I wanted to have done by this time in my life. I think my perfection in procrastination might have something to do with that. There are so many more things I want to accomplish and the years left after forty will not be enough to get them all done.  There are two halves in life - the half before 40 and the half after. I am now in the after; the second half of my life. I can either embrace it or cry, but I can't change it.</p>
                    <p className='p'>Turning 30 wasn't a big deal, but 25 was hard. At 25 I looked back at what I had done and, while having graduated high school while being a wife and a mother was a miracle and a blessing, I felt I hadn't really done anything since. It kind of feels the same at 40.</p>
                    <p className='p'>I was telling my Dear Husband this the other night - that I really didn't feel like I've accomplished all that I was meant to by this time in my life - to which he rolled his eyes and said I was being ridiculous. And then he listed all the things I have done. Sometimes looking at things through someone else's eyes gives you a new and better perspective. That's what best friends do and I'm thankful to be married to mine.</p>
                    <p className='p'>So, instead of lamenting over all the goals I didn't reach in 2012, my 39th year and the end of my first half, I have decided to be excited to have another chance and to make 40 a ROCKIN' year! They say 40 is the new 20. I guess I'll find out.</p>
                    <p className='p'>Looking over the goals I wrote for 2012, there isn't much I would change for 2013. The areas of Faith, Family and Health are all the same. In Writing, I'll continue the neurotic ebb-and-flow dance that I've fallen in step with and pray that I'll wind up nearer my goals by the end of the year. The Other category, which includes contact with family and friends, will have a bigger focus this year. Those relationships are an important part of life; important for learning and sharing and being a part of something beyond ourselves and our four walls. I let the four walls of myself confine me a little bit much last year and I think I'd like to 'get out' more this year.</p>
                    <p className='p'>By the end of this year and every year that follows, I want to be able to say, “that was my best year yet.”</p>
                    <p className='p'>Because I am starting to grasp the importance of good time management, the motto I've picked for this year is:</p>
                    <p className='p'>Do it. Do it right. Do it right now.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan042013;