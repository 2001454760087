import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Sept092014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/book-the-legend-of-sheba.png" alt="The Legend of Sheeba Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept092014'>
                    <h5>September 9th, 2014</h5>
                    <h3>Book Review: The Legend of Sheba</h3>
                    <p className='p'>I was fortunate enough to receive an advance reader copy of this book from the author and Howard Books through Net Galley for review. It released today.</p>
                    <p className='p'>This is the first of Tosca Lee's books that I have read and I have to tell you, I am hooked! I absolutely love how poetically she writes.</p>
                    <p className='p'>The Legend of Sheba: Rise of a Queen is an intense read, beginning to end; a page turner. It is an eloquently written tale, seamlessly woven with details from Lee's exhaustive research on the real queen of Sheba.</p>
                    <p className='p'>The Bible account of the Queen of Sheba's visit to King Solomon is more about Solomon's splendor and great blessings from God and isn't really about the queen at all. This story explores this queen of many names. It is a story about the difficult rise of a queen, her fears, and the passions that consumed her, hers as well as the passions of others.</p>
                    <p className='p'>If you're familiar with the kings and prophets of the Old Testament then this will read like the chapters that were left out. Some familiar faces, some new, it is as mysterious as it is familiar.</p>
                    <p className='p'>This is the peek behind the curtain.</p>
                    <p className='p'>FROM AMAZON:</p>
                    <p className='p'><i>Her name is legend. Her story, the epic of nations. The Queen of Sheba. A powerful new novel of love, power, and the questions at the heart of existence by the author of the award-winning “brilliant” (Library Journal) and “masterful” (Publishers Weekly) Iscariot.</i></p>
                    <p className='p'><i>There is the story you know: A foreign queen, journeying north with a caravan of riches to pay tribute to a king favored by the One God. The tale of a queen conquered by a king and god both before returning to her own land laden with gifts.</i></p>
                    <p className='p'><i>That is the tale you were meant to believe.</i></p>
                    <p className='p'><i>Which means most of it is a lie.</i></p>
                    <p className='p'><i>The truth is far more than even the storytellers could conjure. The riches more priceless. The secrets more corrosive. The love and betrayal more passionate and devastating.</i></p>
                    <p className='p'><i>Across the Red Sea, the pillars of the great oval temple once bore my name: Bilqis, Daughter of the Moon. Here, to the west, the porticoes knew another: Makeda, Woman of Fire. To the Israelites, I was queen of the spice lands, which they called Sheba.</i></p>
                    <p className='p'>In the tenth century BC, the new Queen of Sheba has inherited her father's throne and all its riches at great personal cost. Her realm stretches west across the Red Sea into land wealthy in gold, frankincense, and spices. But now new alliances to the North threaten the trade routes that are the lifeblood of her nation. Solomon, the brash new king of Israel famous for his wealth and wisdom, will not be denied the tribute of the world—or of Sheba's queen. With tensions ready to erupt within her own borders and the future of her nation at stake, the one woman who can match wits with Solomon undertakes the journey of a lifetime in a daring bid to test and win the king. But neither ruler has anticipated the clash of agendas, gods, and passion that threatens to ignite—and ruin—them both. An explosive retelling of the legendary king and queen and the nations that shaped history.</p>
                    <br />
                    <p className='p'>MORE FROM ME:</p>
                    <p className='p'>I immediately fell in love with the Queen's character, of any name. After being betrayed by her father and those closest to her, including the man she loved most, she finds her voice and stands her ground against those who threaten to marionette her into bringing a king in beside her. She hits back. She bites. She will not be bullied. And she still maintains the grace of a queen.</p>
                    <p className='p'>Her mother told her when she was six years old “that wisdom is lasting and therefore more precious.” So it's no wonder the new King of Israel, who is reported to her to be wise beyond himself, intrigues her. After years of sharing private letters with him, she is determined to meet the man behind the words. She risks leaving her home to journey North to meet him face to face. What she finds in him reveals much about herself.</p>
                    <p className='p'>I don't hesitate to recommend this book but I would caution you to keep in mind while reading that it is, in fact, a novel. It is very easy to confuse fact with fiction with the seemingly effortless way Lee blends the two.</p>
                    <p className='p'>Side note: There is a prequel to this story, Ismeni, that gives great backstory and is available to download for free. It's not necessary to read it first, but I recommend it.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept092014;