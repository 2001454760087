import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Jul282014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/summer-mem.jpg" alt="Summer memories" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul282014'>
                    <h5>July 28th, 2014</h5>
                    <h3>Inaugural Savannah Summer</h3>
                    <p className='p'>This poor blog has been left for dead in the shadow of novel aspirations. But, summer is two weeks to her sad end and deserves a moment of pause and reflection.</p>
                    <p className='p'>Summer began with a huge exhale after a brutal first school semester. I think we, collectively, released our white-knuckled grip on the academic reins and just let life happen for a few weeks before actively engaging in anything requiring any sort of planning or effort. OK, maybe that was just me.</p>
                    <p className='p'>I have to admit, it's been a surprisingly relaxed summer.</p>
                    <p className='p'>Hovering over my desk, from behind my computer, I find it very easy to spit yep to the never-ending requests to go swimming or other outdoor activities. Being home alone provides quiet space for that ever-elusive muse to peek its head out of hiding. Win-win.</p>
                    <p className='p'>And, as of this writing, neither boy has come home bleeding or burnt too badly.</p>
                    <p className='p'>We haven't taken any “vacations” per se. Being this is our first summer living in a vacation city, we're still investigating Savannah and her neighbors. It's all new to us, so it's like a being on vacation every week.</p>
                    <p className='p'>Since landing here we've trekked every direction but West. Because, well, West is the direction we came from. And believe me, there's nothing that direction but trees for many miles.</p>
                    <p className='p'><b>USA! USA! USA!</b></p>
                    <p className='p'>One of the highlights of summer was our trip to Jacksonville for a US Soccer game. We did one of those don't-tell-the-kids-where-we're-going-until-we-get-there kind of surprises. That was probably more fun for David and I than the kids but we sure got some fun snickers in over it.</p>
                    <p className='p'>I had never seen adult men play soccer. I'm pretty surprised they get paid for it, actually. (Don't judge me; I'm a football-blooded American.) But it was exciting seeing our National team play live. Especially because we won.</p>
                    <p className='p'>The heat in the stands was intense, the fans were obnoxious, and we had a great time.</p>
                    <p className='p'><i>Salt Life Anyone?</i></p>
                    <p className='p'>Summer now apparently means beach. So we did that. It was crowded. And hot. And some of us burned our feet on the very hot sand. I honestly don't know how people do an entire day at the beach, especially when it's 200 degrees. After three hours and a long, excruciating walk, this heat-stroked mama had to drag her swimmers home.</p>
                    <p className='p'>Must improve my beach skills if I'm going to live this close. Or get a boat.</p>
                    <p className='p'><i>This Is Why I Love Her</i></p>
                    <p className='p'>One of the top Savannah attractions is her historic sites. I've seen many of them in the last three plus years I've been visiting my love-affair city but, until recently, had never visited the Wormsloe Plantation Historic Site. The “Oak Avenue” that leads to the tabby ruins of Noble Jones' colonial estate is one of the most breath-taking driveways I've ever seen.</p>
                    <p className='p'>There's more to the Savannah Live Oaks than Spanish Moss (which isn't Spanish, and isn't moss.) When it rains, the tree branches take on a green “fuzz” that, when you look closely, is actually fern. Resurrection Fern.</p>
                    <p className='p'>It's called that because it only comes out (resurrected) after a good rain. You'd never know it was there beforehand; it's crispy brownness masquerades as part of the branch. But after a monsoon soaker (a common occurrence during summer in coastal Georgia) when the humidity rises and breathing feels like you're taking on water, this fern emerges like magic. Truly beautiful magic.</p>
                    <p className='p'>The day we were at Wormsloe, the Oaks were covered in Resurrection Fern. And, after seeing that the Oaks downtown were not, I'm inclined to believe that Wormsloe's Oaks are Heavenly blessed. A little something put there by my Creator just because I like it so much.</p>
                    <p className='p'>As school approaches, pulling the summer rug out from under us, our list of things to see and do is still full. Disney World is only four hours South, Charleston a short two hours the other direction, Hilton Head less than that. Those will have to wait.</p>
                    <p className='p'>For now, there's still some sun to soak up, library books to be checked out, fishing to be done, and maybe even time for me to work on my beach endurance.</p>
                    <p className='p'>It's been a good first Savannah summer and it's not over until 8:00am on August 12th. We've still got two weeks.</p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul282014;