import React from 'react';
import './main.css';
import './contact.css';

const Contact = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/lets-connect.png" alt="Connect" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 contact'>
                        <br />
                        <h3>Contact Me</h3>
                        <br />
                        <br />
                        <br />
                        <a href="mailto:admin@heathereslick.com"><img src="/images/pngegg.png" alt="Email" className='email-btn' /></a>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

        // <section className='main-sec cont-sec'>
        //     <div className='main-container'>
        //         <form class="form-container">
        //             <h2>Contact Me</h2>
        //             <input type="text" placeholder="Your Name" className='ff'/>
        //             <input type="email" id="email" placeholder="Email" className='ff'/>
        //             <textarea name="" id="" rows="7" placeholder="Message" className='ff'></textarea>
        //             <button>Send Message <i class="fa-solid fa-paper-plane"></i></button>
        //         </form>
        //     </div>
        // </section>

    )
}

export default Contact;