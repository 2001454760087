import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Aug022012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/chick.jpg" alt="Chick-fil-A line" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 aug022012'>
                    <h5>August 2nd, 2012</h5>
                    <h3>Eat Mor Chikin</h3>
                    <p className='p'>Where has the summer gone?  I had so many plans for fun stuff when summer started and I don't think we've done a single one.  We were going to visit Tanganyika Wildlife Park, because we haven't been there yet.  We were going to visit the Sedgwick County Zoo.  Haven't been there either.  We were even going to try to visit the Underground Salt Museum.  Nope, haven't done that either.  Local attractions have lost out to busyness of lawn care, planting some seeds and watching them grow, and multiple trips to libraries around the city.</p>
                    <p className='p'>Well, that all changed yesterday.</p>
                    <p className='p'>Chick-fil-A became a local attraction in Wichita before the paint had even dried.  We had tried to eat there several times but the very long line, usually spilling out the door and into the parking lot, always made us rethink our lunch destination.  It's just chicken after all.</p>
                    <p className='p'>Yesterday was Chick-fil-A Appreciation Day as determined by Mike Huckabee and Rick Santorum, who felt the liberal's attack on Chick-fil-A's president and CEO, Dan Cathy, was unjust, unconstitutional and unfounded.  They urged people to eat at Chick-Fil-A on Wednesday to show support for our First Amendment right of free speech.</p>
                    <p className='p'>If you have no idea what I'm talking about, Google it.</p>
                    <p className='p'>After reading an article on the opinion page of the Wichita Eagle yesterday about the liberal's reaction to Cathy's comments in support of traditional marriage, I decided to support his right to say what he wants in our free country without his livelihood being boycotted by hypocrites.</p>
                    <p className='p'>So off we go to wait with the masses in line for some chicken nuggets.</p>
                    <p className='p'>Now I thought I was being smart by getting there ahead of the lunch crowd.  Apparently, a lot of other people thought the same thing.  Here's what the walk-up line looked like from my drive-thru vantage point at 11am:</p>
                    <p className='p'>Make that 11:15ish.  It took 15 minutes just to get off the street and into the drive-thru lane.</p>
                    <p className='p'>Today's news is that Chick-fil-A had record sales yesterday, on Appreciation Day.  Thousands and thousands of people all over the United States appreciated their chicken from one of the 1600 Chick-fil-A restaurants.  (Insert giggle.)</p>
                    <p className='p'>I guess trying to boycott Chick-fil-A was not such a great idea.</p>
                    <p className='p'>AbcNews anchor, Diane Sawyer, said this confirms that the fast-food chain is the new ground zero in the culture wars over gay marriage.  I think that's stretching it a little.  Actually, I think that is preposterous.  But we all have the right to say what we want in our free country.</p>
                    <p className='p'>People will stand on principal for no food.  This time we got really good chicken nuggets.</p>
                    <p className='p'>Summer fun... check.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Aug022012;