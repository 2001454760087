import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';
import './carousel.css';

const Aug182015 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2015/unicorn.jpg" alt="Unicorn" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 aug182015'>
                        <h5>August 18th, 2015</h5>
                        <h3>Unicorns Live in the Mountains</h3>
                        <p className='p'>Vacation: a period of suspension of work, study, or other activity, usually used for rest, recreation, or travel.</p>
                        <p className='p'>Ask ten people what is their idea of a vacation and you will likely get ten different answers. Here's mine: A period of time when I get to do what I want and none of what I don't want.</p>
                        <p className='p'>I've come to the conclusion that vacations are like unicorns. We've heard about them, we've conjured up an idea of what they look like, we've heard of other people say they have experienced them, but they're not real.</p>
                        <p className='p'>Like attaching a horn to a horse's head and calling it a unicorn, we take time off of our normal dailies, pile the kids and a bunch of stuff in the car, head for someplace called Vacationland, only to come home more worn out than when we left, and we call that vacation. Going back to our normal dailies is the vacation from the vacation.</p>
                        <p className='p'>For me, vacationing in the mountains - cabin, not tent - is the only way to get close to the unicorn. Seeing nature as God intended - smelling unpolluted air, hearing nothing but the deafening chorus of the cicadas and the cluck of the frogs, actually being able to see the stars at night, hiking through trees and over rocks, finding waterfalls - and then climbing between clean sheets on a pillow top mattress at night, that's vacation.</p>
                        <p className='p'>We'll still go to Disney World because we now live only four hours away, but that's a trip, not a vacation.</p>
                        <p className='p'>We loved this “vacation” so much, we already have plans to go back next month!</p>
                        <p className='p'>Unicorns may be real, but I'm not wasting my time looking for them. There are too many other great things to see.</p>
                        <div className='container'>
                            <div className='carousel'>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                                <div className='image'><span></span></div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Aug182015;