import React from 'react';
import { Link } from 'react-router-dom';
import './arch2009.css';

const Sept282009 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2009/pocketwatch.jpg" alt="Pocket watch" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept282009'>
                    <h5>September 28th, 2009</h5>
                    <h3>Daylight Saving Time</h3>
                    <p className='p'>Daylight Saving Time. For some, those three little words evoke feelings of weariness, frustration, agitation, and just plain out-of-sorts. I'll admit that's true of me in the spring. But, in the fall, I love DST. For me, there are few things greater than a 25 hour Sunday in the fall.Since 2007, Daylight Saving Time (not Savings with an 's' at the end) begins the second Sunday in March and ends the 1st Sunday in November in most US states. (Before 2007, time changed the last Sunday in April and October) Hawaii, Arizona, US Virgin Islands, Puerto Rico, and American Samoa do not change times because, being close to the equator, their sunlight hours are pretty much the same year round. In the spring we “spring forward” and have a 23 hour day. Then, in the fall, we “fall back” and gain back that lost hour making it an “extra” hour in our day.</p>
                    <p className='p'>Daylight Saving Time was originally created to make better use of daylight and decrease energy consumption. Adding daylight in the afternoon also benefits retailing, sports and other activities that exploit sunlight after working hours. However, the shift in daylight causes problems for farming, evening entertainment and other occupations tied to the sun. Numerous studies have been done to determine the effect of time change on the severity of auto accidents and many feel the change creates sleep disturbances.</p>
                    <p className='p'>We all know that the time changing is our reminder to change the batteries in our smoke detectors, and that the name of our time zones change to correlate with whether we're in Daylight time or Standard time. But consider this: twins are born as the time changes. The first twin is born at 1:55; the second is born ten minutes later at 2:05. Since time changes at 2:00, the second twin's time of birth is actually 1:05, reversing their birth order (on paper).</p>
                    <p className='p'>Here's something else to make you go hmmm….What will you do in your “extra” hour on November 1st this year? Will you exercise? Read? How about finish a craft that's been awaiting your attention? Or maybe you'll try a new craft. There are many things you can get in an hour these days - a glucose test, a cash advance, or a photo. Most people say they get an extra hour of sleep. That is usually how I choose to spend my extra hour when DST ends. But, this year, I think I'll try something active. I think I'll call my brother in Hawaii and brag about the extra hour in my day.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept282009;