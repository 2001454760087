import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Jun222011a = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/airplane.jpg" alt="Airplane" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 jun222011a'>
                        <h5>June 22nd, 2011</h5>
                        <h3>The Airplane</h3>
                        <p className='p'>The plane.</p>
                        <p className='p'>This is the quietest flight I think I've ever been on. I saw children and infants board but I haven't heard a peep from the back since they walked past me. Maybe they all drifted to sleep.</p>
                        <p className='p'>The first class cabin certainly has its perks but I'm not sure this warm, slightly melted Twix candy bar is one of them. Better than no Twix candy bar? I suppose. At least I didn't have to pay for it.</p>
                        <p className='p'>Why does it always seem to get cold once we are in the air? Don't they have temperature regulators on these big birds? Ya, I know, quit complaining about the free flight with the free first class upgrade with the free slightly melted Twix. As DH often says, I'd complain if were hung with a new rope. I've got to stop being so predictable.</p>
                        <p className='p'>Where is this flight taking me? To Atlanta, then on to Savannah, obviously. But it goes further than that. Discovery maybe. Discovery of a place I've never been and, I'm sure, of a culture I've never experienced. Discovery of the place DH now lives part-time. Discovery of understanding. Hopefully two-way understanding. There are doors that need opened. And possibly closed. But let's get the plane on the ground first.</p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Jun222011a;