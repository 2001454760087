import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Feb022012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/sweater.jpg" alt="Sweater" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb022012'>
                    <h5>February 2nd, 2012</h5>
                    <h3>I Yam What I Yam</h3>
                    <p className='p'>I am a bargain shopper.  I'm sure this comes from growing up without money and having no choice but to have a tendency toward frugality.  I don't think this is a bad thing.  In fact, I think this is one of my better qualities, even if some would argue that this is one my more annoying qualities.  Actually, only one person I know would have that argument and I happen to be married to him.  So, needless to say, shopping together is a true test of our civility.</p>
                    <p className='p'>There aren't many things I like paying full price for.  Obviously cars are at the top of the list.  No one should ever pay full price for a car because that's a true rip off.  Furniture is up there too.  Did you know furniture is marked up over 200 percent?  Makes me hate shopping for furniture because I know I'm never going to get the price I want.</p>
                    <p className='p'>Now, I do have a tendency toward instant gratification which is probably why credit cards are a danger for me.  But, when it comes to buying clothes I have the patience of Job.  Well, OK, maybe not to that extreme.  But I know that it doesn't make sense to pay full price today when I can wait a few weeks and get it on the clearance rack.   And if there aren't any on the clearance rack then I didn't need it anyway.  I need the one that is on the clearance rack.  Paying full price for clothes is, to me, almost like flushing dollar bills down the toilet.  I don't know about you, but I've never been stupid or careless enough to flush money.  At least not that I know of.  I did lose $800 cash once but that is another story for another day and I'm pretty sure I didn't flush it down the toilet.</p>
                    <p className='p'>A couple weeks ago I found this really soft, cozy sweater on clearance at Target.  It was one of those sweaters that you just can't help but touch.  It was only $8 and so, of course, I snagged it up quick.  This sweater would have been the perfect sweater.  The tag said it was polyester and nylon.  That really doesn't mean anything to me.  But like I said it was very soft and cozy- perfect for the chill of Kansas winters.  The tag also said machine wash and tumble dry.  Perfect.  Nothing difficult there.</p>
                    <p className='p'>What the tag didn't say was, “likely to snow.”  Yep, snow.  Little white fluffy sweater snow.  Everywhere.  In fact, the first day I wore it, we stepped out of church and my husband thought it was snowing.  Nope, just my sweater, Honey.  By the end of lunch that day not only was my black scarf covered in white sweater snow but so were my jeans.  This stuff is worse than cat hair.  I didn't think anything was worse than cat hair.</p>
                    <p className='p'>But, holding out hope, I washed the sweater hoping to get rid of the snow.  That didn't work.  I washed it again.  Nope.  Every time I wore the sweater it snowed.  I seriously considered spraying it with hairspray to keep the snowy fibers stuck to what remained of the sweater - that is how much I really wanted to wear it.</p>
                    <p className='p'>Alas, I finally gave up on my sweater and reluctantly tossed it in the Goodwill basket.  Every time I walk by that basket I wonder if I should give the sweater just one more chance.  But even right now, as I look at my black shirt and see the snow all over my sleeves just from folding that blasted thing to take a picture so you'd have a visual, I am reminded of why it landed in that basket to begin with.</p>
                    <p className='p'>I'm sure there is a lesson in this somehow.  Like you get what you pay for.  Or cheaper isn't always better.  But “I yam what I yam” (you have to read that in Popeye-voice) and what I yam is a bargain shopper at heart and I'm not likely to start paying full price for sweaters.  Or furniture or cars.  Especially furniture and cars.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb022012;