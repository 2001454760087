import React from 'react';
import { Link } from 'react-router-dom';
import './arch2010.css';

const Jul112010 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2010/fifth-wheel-rv.jpg" alt="Fifth wheel camper" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul112010'>
                    <h5>July 11th, 2010</h5>
                    <h3>Camping is ...</h3>
                    <p className='p'>For some, camping is a tent in the woods. For me, it's 50 amp service for my amenities on wheels.</p>
                    <p className='p'>For some, it's fishing by day and cooking your catch by night. For me, it's painting my nails at the picnic table having already found the perfect pre-formed burgers for David to throw on the grill and a nice pre-fab bucket potato salad.</p>
                    <p className='p'>Camping is lazy days and even lazier nights. It's sunflower seeds and beer. Usually too much of both. Proof of that comes from the raw tongue and swollen ankles.</p>
                    <p className='p'>Camping is waking up because the birds are being too damn noisy for me to sleep any longer but getting out of bed (not a sleeping bag) only after David turns the radio up to get-out-of-bed-you-lazy-people volume and starts naggin' me for coffee. Does the early bird get the worm because the worm is still sleeping? Lucky worm.</p>
                    <p className='p'>Camping is…no watches, we eat when we get hungry, sleep when we get sleepy; no cell phones, everybody we'd want to talk to is already here; no showers, oh wait, we have a shower. And some of us actually use it.</p>
                    <p className='p'>No schedules, no commitments. The biggest worry is if it's going to rain out our campfire and whether the black tank is actually full or there's just TP stuck to the sensors.</p>
                    <p className='p'>The kids are free to do their thing, whatever that is. Sometimes it's collecting muscle shells from the lake beach or acorns from a campsite tree.The tree climbing is like none other but usually ends in more than a few scrapes and tears. And more often than not, someone takes home evidence of poison ivy.</p>
                    <p className='p'>It's not camping if someone doesn't get cut, bit or infected. But hey, when you have air conditioning, who cares?</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul112010;