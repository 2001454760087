import React from 'react';
import './main.css';

const Main = () => {

    return (

        <section className='main-sec'>
            <div className="main-container">
                <div className='main-flex-left'>
                    <img src="/images/heather1cropped.jpg" alt="Heather's headshot" className='head-pic' />     
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1'>
                        <br />
                        <p className='p'>Welcome to my blog! I'm glad you're here.</p>
                        <br />
                        <p className='p'>My name is Heather Eslick and I am a writer, currently working on my first novel. I have been married to my husband for twenty-five years and we have four boys. I always say that I have two that keep me on my toes and two that keep me on my knees, and it's never the same two at the same time.</p>
                        <br />
                        <p className='p'>I grew up in a small town in Wyoming before my mom drug me to Kansas in June of 1988. I remember getting the flu that first week and thinking, I can't live here. I will die! It was sooo humid!</p>
                        <br />
                        <p className='p'>Twenty-five years later we transplanted in Savannah, Georgia. All those years in Kansas were good practice for living in the South. I loved Kansas and miss it dearly. But I'm enjoying the mild winters of Southern Georgia, am learning to say y'all in the right places, and someday may even learn to like sweet tea. Gracious! </p>
                        <br />
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section>

    )
}

export default Main;