import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Nov062015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/stop-light.jpg" alt="" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 nov062015'>
                    <h5>November 6th, 2015</h5>
                    <h3>Late to the Party</h3>
                    <p className='p'>It seems I'm late to the Thankfulness party this year. What party, you ask? I'm referring to the one where everyone shows up on social media the first few days of November to express their thankfulness for each and every possible thing they can think of until they run out of ideas midmonth and the whole notion fizzles out, being replaced by warnings like, “Only 7 Mondays until Christmas!” Have mercy.</p>
                    <p className='p'>As it is, I am thankful mostly for the same things as everyone else: God, family, friends, a working toilet (never more than on this day when certain unnamed stomach disturbances force me to stay home and write silly, yet totally meaningful blog posts.)</p>
                    <p className='p'>So, let's think out of the box a little bit, shall we?</p>
                    <p className='p'>Red lights. Many of us curse them, if not run them. They seem to go on for e v e r… and then twenty seconds more. They make us late, give us the grumpies, and are detrimental to our miles per gallon. We drum the steering wheel in impatience. We are annoyed by the commercial we've heard for the third stinking time today on the “no commercials” station and pound the radio dial for something else. Anything else. We pull out our cell phone to text (gasp!) our waiting party that we're probably going to be two minutes late because we're stuck at the longest stop light known to man. Or worse, we post it as a Facebook status real quick because to not would just be a waste of a good status.</p>
                    <p className='p'>But what if we decided to be thankful for red lights? Thankful for the chance in our day to p a u s e for just a moment on our way to wherever our working car is taking us. Imagine if we took the thirty-second opportunity to tell God thanks for our working car, or to notice the wildflowers that grow along the side of the road, or appreciate the couple holding hands through the crosswalk. I like to spy on people behind me in my rear view mirror. People can be so funny when they think no one is watching.</p>
                    <p className='p'>Trust me, when you spend a red light in thankfulness, they really seem pretty short. Too short.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Nov062015;