import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Sept172016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/h-snakeskin.jpg" alt="Snake skin hanging on car mirror" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept172016'>
                    <h5>September 17th, 2016</h5>
                    <h3>Life With Boys</h3>
                    <p className='p'>This is life with boys.</p>
                    <p className='p'>Husband, after mowing: “You'll need to pull your car back into the garage because I'm too sweaty.”</p>
                    <p className='p'>So, I go out to move my car…</p>
                    <p className='p'>In the house, all the boys are snickering. One did it, they all knew about it. Not one warned me.</p>
                    <p className='p'>So, don't anyone dare suggest to me that raising boys is easier than raising girls. I happen to know from experience that that “easier” comes only when you've<i>grown a pair,</i> if you know what I mean.</p>
                    <p className='p'>Me, with the evilest eye I can muster, after removing a dangling snake skin from my rearview mirror to pull my car into the garage: “That wasn't nice. And payback can be a b**ch.”</p>
                    <p className='p'>I'm not mad. Really, I'm not. I actually like that they bond together over pranking me. But I just don't see how I can let this go unreciprocated. Because a snake was involved!</p>
                    <p className='p'>This is life with boys. And when you're the only girl in the house, you get ganged up on in the name of fun. But karma has nothing on a mama who has a prank to repay.</p>
                    <p className='p'>Stay tuned…</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept172016;