import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const Sept022016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/hurricane.jpg" alt="Hurricane" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept022016'>
                    <h5>September 2nd, 2016</h5>
                    <h3>Hurricane, Kansas Style</h3>
                    <p className='p'>What happens when you put a Kansas girl in the path of an Atlantic hurricane?  She's likely to panic and buy too many hurricane supplies.</p>
                    <p className='p'>I know hurricanes are very serious and should be taken seriously. The truth is, hurricanes scare me. Even though they are probably the most predictable of the natural disasters (I didn't look this up; I'm just assuming,) the effects are largely unpredictable (again, my assumption from this virgin experience.)</p>
                    <p className='p'>Savannah is somewhat protected from hurricanes that hit the Florida coast, because the trajectory is bounced upward toward the Carolinas and skirts our little armpit area of the coast all together. Which kind of makes Savannah the perfect coastal city.</p>
                    <p className='p'>Unless the hurricane is making landfall on the Gulf coast of Florida. Then, apparently, it barrels across state after state excruciatingly slow as a tropical storm, throwing up tornados and laying down trillions of gallons of floodwaters. This was the prediction that was broadcast on every news channel and emergency outlet.</p>
                    <p className='p'>Schools cancelled for today, all non-essential persons were off work (if you had to work today, consider yourself essential,) and city authorities were advising people to just stay home and off the roadways if at all possible.</p>
                    <p className='p'>So, along with the entirety of Savannah and the surrounding communities, I went shopping yesterday for supplies to get us through every possible scenario. I, basically, spent $248 on canned soup, bottled water, and batteries.</p>
                    <p className='p'>And we used none of it.</p>
                    <p className='p'>Basically, what I got out of this storm is that Savannah hurricane days are very much like Wichita snow days. Minus the snow and hot chocolate, add boiled peanuts and green tea. (I still have not grown a taste for sweet tea, but I'm now convinced the only way to eat peanuts is salty, warm, and slightly mushy.)</p>
                    <p className='p'>Seriously, this storm was just like being back in Kansas. Right down to the 3am tornado warning. I was the only one who got out of bed to check on it, didn't bother taking cover because the rotation wasn't close enough, and was back in bed, listening to the wind howl inside of fifteen minutes. All the while, our “storm rations” will be my lunches for the next 27 days.</p>
                    <p className='p'>But, hey, the kids got a four-day weekend out of the deal. Just like they did in Kansas.</p>
                    <p className='p'>Minus the boiled peanuts and green tea.</p>
                    <br />
                    <div className='p-img-container'>
                        <img src="/images/2016/h-boiled-peanuts.jpg" alt="Boiled peanuts" className='p-imgs' />
                        <img src="/images/2016/h-tea.jpg" alt="Tea" className='p-imgs' />
                    </div>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept022016;