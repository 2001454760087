import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jul062015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/night-running.jpg" alt="Night running" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jul062015'>
                    <h5>July 5th, 2015</h5>
                    <h3>What Body Clock?</h3>
                    <p className='p'>I was up at 5 a.m. this morning to take My Kid to the airport and saw several 0-dark-thirty runners. Although I don't get why people risk tripping in the dark space between street lights for fitness or craft or muse, I understand why they sacrifice sleep for these things.</p>
                    <p className='p'>I've tried this a couple of times myself when training for half marathons, but found that I hate both running and dark mornings.</p>
                    <p className='p'>My Kid commented that I wasn't a great conversationalist that early in the morning. I know this is true, but still there is the ever-running conversation going in my head, even at 0-dark-thirty. This morning I was asking myself when will I become one of those “morning people?”</p>
                    <p className='p'>My mom is one of those people with the body clock alarm so, naturally, I always assumed I would inherit it as I got older. I am amusing myself by choosing to believe that I must not be older yet.</p>
                    <p className='p'>However, I do hope that one day I might willingly get up with the sun (or, heaven forbid,<i>before</i> the sun) without using phrases like “heaven forbid,” and be able to enjoy the early morning. I'd  like to not hate running too.</p>
                    <p className='p'>If anyone has any suggestions for either, I love to hear them after 8 a.m.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jul062015;