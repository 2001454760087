import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const May132013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/shakespeare.jpg" alt="Shakespeare book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may132013'>
                    <h5>May 13th, 2013</h5>
                    <h3>Bookstore Bliss</h3>
                    <p className='p'>There are two kinds of stores that make me happy happy happy: office supply stores and bookstores.</p>
                    <p className='p'>(My mother probably thought I was going to include shoe stores but I'm sure that's an entirely different blog post.)</p>
                    <p className='p'>For me, there's almost nothing more wondrous than a bookstore. And each one has its own feel; its own life that settles over you while you're there.</p>
                    <p className='p'>Books have a way of helping you identify not only the world around you but you yourself, even if you haven't read them.</p>
                    <p className='p'>For instance, scanning rows of cookbooks at Barnes and Noble you can see how diverse is our world of cooking and eating. And even if you allow yourself to contemplate for just a moment about being the next Barefoot Contessa, Pioneer Woman, or Betty Crocker you pretty much already know where you fit on those shelves.</p>
                    <p className='p'>My favorite are used bookstores. I can't help but wonder about the previous owners of the books on the shelves I scan. Are they the read-it-once-then-pass-it-on type? Did they have a library of books that sat dusty until their death when their children dispersed the collection to make them manageable?</p>
                    <p className='p'>My grandmother was the latter type. She had a ceiling-to-floor wall of books in her back bedroom. (I loved that room.) Most of the books were older than I was and had been passed on from someone else's library.  It was a great collection.</p>
                    <p className='p'>I inherited just one of those books: Shakespeare's Complete Works. It dates back to 1878 and would probably be worth some money if it were in better shape. But with the spine cover torn off completely and the front cover hanging by literal threads, it's worth only a few dollars. (I looked it up.)</p>
                    <p className='p'>However, the value it holds for me is far greater. First, because it last belonged to my grandmother. A grandmother like no other, I assure you. But the greatest value is in the story it tells. Not the stories Shakespeare penned. But the other stories, tucked between the pages….</p>
                    <p className='p'>The black and white photo, tucked between the pages of The Tempest,  of two stoic-faced sisters with high collars, long skirts and dusty shoes, seated in front porch rocking chairs.</p>
                    <p className='p'>A newspaper clipping of a list of books called Models For Writers, tucked into the pages of The Comedy of Errors. (No irony there.)</p>
                    <p className='p'>A red flower, carefully pressed between the pages of Second Part of King Henry IV.</p>
                    <p className='p'>And multiple clippings of poems found in the pages of King Richard III and Troilus and Cressida.</p>
                    <p className='p'>This book may have never been read. But it has been used, and used well. And now it sits gathering dust on my bookshelf. It will be up to my children to decide where it goes from there after I die. It might end up in the hands of someone who, like myself, for a moment took the time to marvel at the stories tucked between the words. Someone who happened upon it in a used bookstore.</p>
                    <p className='p'>“To my mind there is nothing so beautiful or so provocative as a secondhand bookstore…To me it is astonishing and miraculous to think that any one of us can poke among the stalls for something to read overnight–and that this something may be the sum of a lifetime of sweat, tears, and genius that some poor, struggling, blessed fellow expended trying to teach us the truth.”</p>
                    <p className='p'>― Lionel Barrymore</p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May132013;