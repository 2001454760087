import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Mar032015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/yoyo.jpg" alt="Yoyo" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar032015'>
                    <h5>March 3rd, 2015</h5>
                    <h3>Hard Times Call For YoYos</h3>
                    <p className='p'>It used to be that I hardly ever caught the sicknesses my children brought home. Our household was like any other in that if one kid got sick, they all got sick. They would pass it around to each other and sometimes back again, but I managed to stay away from most of it, even being the head caretaker.</p>
                    <p className='p'>Those days are evidently gone, and it seems they've taken my immunity with them.</p>
                    <p className='p'>Now, if one of the kids gets so much as the sniffles, I can pretty much count on not only catching it, but it's guaranteed to morph into something multiple times cruddier by the time it gets to me.</p>
                    <p className='p'>One kid brings home a cough, I get the flu. Another kid starts the chain with sneezing and, by the time it circles around it me, it's turned into World War III for the sinuses.</p>
                    <p className='p'>I am not one of those brave, selfless, does-it-all-anyway moms, who puts on the happy face, does all the laundry, sterilizes every surface and handle, and still has a hot meal on the table at the end of the day.</p>
                    <p className='p'>I also do not live with any nurturers. Not that any one of them wouldn't do something for me if I asked them, but there won't be any volunteering going on. I don't get offended, I just understand this is how many boymen are and claim YoYo.</p>
                    <p className='p'>I love YoYo, which stands for <b>You're on Your own</b>. It means I take care of me, and you take care of you. It's also known as Every Man For Himself - EMFH - but that isn't as acrostically friendly.</p>
                    <p className='p'>Yesterday I started having symptoms of the colds my children have been tossing around the last couple of weeks. I did what I usually do, which is spend the first three-fourths of the day trying to convince my body that it's wrong and my mind not to listen to it. Then I did something I<i>never</i> do and<i>never</i> allow my kids to do - I took a decongestant at dinner time. Big-time good mama no-no.</p>
                    <p className='p'>By six o'clock this morning, when I hadn't slept at<i>all</i> despite what my little activity tracking wrist friend said (serves to show it doesn't know the difference between still and sleep,)  I was reminded why I have a rule never to do this.</p>
                    <p className='p'>Pseudoephedrine works great for two things: as a decongestant and a stimulant. The latter is why buying it requires a photo ID, a blood sample, and your first-born child - kidding, sort of - and is the steam behind the train that is<i>Breaking Bad</i>. (Or was.) And it will keep you awake all night long. Believe me.</p>
                    <p className='p'>Then the dilemma becomes: don't take it again so you can get some sleep but then not sleep because you can't breathe except through your mouth, or take it again so you can breathe but not ever sleep again.</p>
                    <p className='p'>(These are the times that try a mama's soul. Ok, maybe not her soul, but probably her sanity. And definitely her mood.)</p>
                    <p className='p'>This mama chose to take another one first thing this morning. Right out of the gate I cleaned a toilet, did a couple of loads of laundry, and purged my closet (because me and those clothes both know there's no going back.)</p>
                    <p className='p'>But now it's mid-afternoon and the Mucinex-D is wearing off, which means I'm tired and I can't breathe outma nose.<i>Twelve hours, my eye</i>. Back to the dilemma.</p>
                    <p className='p'>I'm going to go take a nap, the YoYo card tucked in my back pocket. We'll see if it gets played tonight.</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar032015;