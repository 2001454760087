import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Oct222013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/cheerios.jpg" alt="Cheerios" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 oct222013'>
                    <h5>October 22nd, 2013</h5>
                    <h3>Sweet Tooth Aliens Have Landed</h3>
                    <p className='p'>I do not have a sweet tooth. Let me repeat: I do NOT have a sweet tooth. Sounds like I'm trying to convince myself, right?</p>
                    <p className='p'>The truth is, I have come to accept that I do, in fact, have a sweet tooth one month every year. And that month runs from mid-October to Thanksgiving.</p>
                    <p className='p'>(OK, so it's more like 45 days.)</p>
                    <p className='p'>This is not something I can explain. I do not understand it myself. However, I am convinced that the retail world we live in has something to do with it.</p>
                    <p className='p'>There is some kind of alien force that invades my brain every October and turns me into one of those people – and, I say those in the most affectionate kind of way because most people I know and love are like this – who love sweets. I, myself, am not one of those people.</p>
                    <p className='p'>Well, not most of the year.</p>
                    <p className='p'>Eleven months out of the year (sometimes ten and a half, depending on how early Halloween candy hits the shelves) I never, and I mean never, EVER crave sweet things. But more than that, sweets have no appeal to me most of the time.</p>
                    <p className='p'>I never eat donuts for breakfast, I scrape the frosting off cupcakes at birthday parties, and I stopped using flavored coffee creamer in my twenties, roughly ten seconds after I started drinking coffee.</p>
                    <p className='p'>I'd rather have the appetizer than the dessert, I prefer onion rings over ice cream (that's instead of, not on top of,) and Cabernet rather than Chardonnay. (Well, OK I never drink white wine. In fact, the year-old bottle in my fridge that I cook with probably needs replaced.)</p>
                    <p className='p'>Today is October 22nd, and even though I haven't yet bought Halloween candy, the aliens landed this morning. This is how I know…</p>
                    <p className='p'>Those sneaky aliens put chocolate syrup on my cereal!</p>
                    <p className='p'>I'm telling you, that bowl of chocolate banana Cheerios was down my gullet before I even knew what had happened. The cat was lapping at the dots of milk at the bottom of my bowl when it dawned on me what time of year it is.</p>
                    <p className='p'>That bottle of sugar-free chocolate syrup has been sitting in the spot next to my low-sugar almond milk since spring and I haven't even noticed it. My alien-infested brain noticed it this morning.</p>
                    <p className='p'>So, here starts the season of hot chocolate instead of hot tea, stealing from the kids' trick-or-treat stash, and now apparently chocolate syrup on my cereal.</p>
                    <p className='p'>Happy Fall, Y'all!</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Oct222013;