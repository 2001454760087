import React from 'react';
import { Link } from 'react-router-dom';
import './arch2010.css';

const Jan292010 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2010/5am.jpg" alt="Clock" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan292010'>
                    <h5>January 29th, 2010</h5>
                    <h3>Snow Day! Snow Day! Oh wait…we already knew that.</h3>
                    <p className='p'>At 5:18 this morning the phone rings, jolting me out of deep slumber. What's wrong? Who could possibly be calling me at this hour? I jump out of bed to pick up the receiver I can easily reach without getting out of bed but now my heart is racing, my adrenaline is pumping, and I am worried.</p>
                    <p className='p'>This is what I hear: “This is an important message from Andover Public Schools. There will be no school today due to the weather.”</p>
                    <p className='p'>You have GOT to be kidding me? I'm pleased that the powers that be made the decision to not risk the lives of our children by having school in these treacherous weather conditions but do they not think that if I wanted to know at five in the morning if school has been called off that I could turn on the TV and find out for myself?</p>
                    <p className='p'>Just called to let all you sleeping people know that you can sleep in today - here's your sign!!</p>
                    <p className='p'>And didn't we sign up to receive important messages by text?</p>
                    <p className='p'>One last rant before I get off my soap box…</p>
                    <p className='p'>There are 3 joys of snow days like this for kids. One is getting to go outside and play in the snow because it's not bad enough to have to stay inside all day. The second is getting to have hot chocolate when you come in from playing outside so long that you can't feel how hot the cup really is while your outerwear (and possibly your underwear) dry out.</p>
                    <p className='p'>The third is waking up in the morning (at regular time) frantically searching for the channel that has the school closings, impatiently waiting to see your school's name scroll across the bottom of the page so that you can hoop and holler and then crawl back into bed.</p>
                    <p className='p'>That third joy is stripped away by 5a.m. phone calls. Can we please be a little less technologically advanced on just this one subject?</p>
                    <p className='p'>Besides, only the teachers are up at 5a.m. The rest of us don't need to know until after six (or maybe even seven.)</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan292010;