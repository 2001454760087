import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Apr242014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/ap-autograph.jpg" alt="Autograph" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 apr242014'>
                    <h5>April 24th, 2014</h5>
                    <h3>Adrian Peterson Confused for Adrian Peterson</h3>
                    <p className='p'>The conversation that happens in the car on the way home from school is pretty much the same every day.</p>
                    <p className='p'>I ask<i>how was your day</i> and the response is never more than slightly left or right of<i>good</i>.</p>
                    <p className='p'>Then we turn on the radio or book on CD and tune in (or sometimes out) for the duration of the 30 minute drive home.</p>
                    <p className='p'>Today was different only in my reaction to their slightly-right-of-good response.</p>
                    <p className='p'>After telling me he finally remembered to bring home his calculator, Ben says, “Adrian Peterson came and spoke at our school today.” And he pulls out a 3x5 index card with an autograph on it.</p>
                    <p className='p'>Wait, what? THE Adrian Peterson? Oklahoma's Adrian Peterson?! In Savannah?!!</p>
                    <p className='p'>Yes, he assures me and Ethan confirms.</p>
                    <p className='p'>Being long-time Oklahoma Sooner fans we had some talking to do.</p>
                    <p className='p'>Off goes the radio and out poured the questions. I had a few. Like how come the<i>parents</i> weren't informed of this ahead of time? And invited to attend? And is he still there?</p>
                    <p className='p'>I called David. This was exciting!</p>
                    <p className='p'>I took the boys' picture with the autographed index card and emailed it out to their dad and brothers. There were some bragging rights to claim after all.</p>
                    <p className='p'>Then I noticed the jersey number on the autographed card was #29. I looked it up - that wasn't right. Who was #29?</p>
                    <p className='p'>Well, it turns out it wasn't Sooner-turned-Viking Adrian Peterson, but instead Chicago's Adrian Peterson. Which makes more sense because that Peterson went to Georgia Southern.</p>
                    <p className='p'>But you understand the confusion.</p>
                    <p className='p'>The bubble I was floating around in for an entire hour after school may have been busted but still, I was a Bears fan at one time (starting in 1986 probably) so it's still cool.</p>
                    <p className='p'>And probably bigger news than remembering your calculator.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Apr242014;