import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Oct152013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/savannah.jpg" alt="Savannah" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 oct152013'>
                    <h5>October 15th, 2013</h5>
                    <h3>Big Changes</h3>
                    <p className='p'>The announcement was made yesterday so now I'm free to talk about the BIG change we are about to enter into….</p>
                    <p className='p'>After calling Kansas home for over 25 years, we are pulling up roots and moving south to Savannah, Georgia.</p>
                    <p className='p'>My husband, David, has been working as a consultant for thirteen years, much of that time on the road wherever the current project happens to be - usually the East Coast - but has accepted a direct position with Gulfstream Aerospace in Savannah.</p>
                    <p className='p'>I am as thrilled as I am heartbroken; looking forward as much as I look back. There will be laughs mixed with tears in the weeks ahead as we say goodbye to our families, our friends, our home. The goodbyes, of course, will be the hard part.</p>
                    <p className='p'>But hey, it's Savannah - my love affair city! If you've ever been there, you understand why that is. If you haven't been there … well, you're missing out. Come visit. I'd love to show you around.</p>
                    <p className='p'>I don't yet know if this move will be a permanent transplant or a lengthy sojourning. I trust God will continue to lovingly shepherd us, as He has done so far.</p>
                    <p className='p'>The thing I look the most forward to is building a full-time family life. With David on the road much of the time, our family life has felt somewhat fragmented and time with friends has had to be sacrificed to preserve our precious-little family time.</p>
                    <p className='p'>I can say, without a doubt, that I believe this to be God's plan for our family right now. There have been too many signs pointing this direction in the last couple of years, and especially the last few months, to deny that.</p>
                    <p className='p'>And He has so graciously paved the way for us - from moving the mountains we thought were immovable to answering prayers before we even knew they needed prayed. As the song lyrics say … this is what it means to be held; this is what it is to be loved.</p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Oct152013;