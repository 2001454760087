import React from 'react';
import { Link } from 'react-router-dom';
import './arch2014.css';

const Dec162014 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2014/report-card-fail.png" alt="Failing report card" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec162014'>
                    <h5>December 16th, 2014</h5>
                    <h3>I Bought The Lies</h3>
                    <p className='p'>Some days I think I stress more about how my children behave than I do anything else. In fact, I know I do.</p>
                    <p className='p'>I expect them to be adult minded when their teenaged brains haven't yet matured to adult. Sometimes I doubt they will without my constant hounding and pounding. I know I'm being unfair and yet I can't help constantly pushing them to think better, act better,<i>be</i> better.</p>
                    <p className='p'>Semester exams started today and I'm pretty sure I've been more stressed about it than they have. I not only want them to do well, but because I have allowed my identity as a parent to get wrapped up in how well they do - I need them to do well.</p>
                    <p className='p'>I've been doing a study on Ephesians and, as I reflected this morning on Satan's tactics in my life as The Father of Lies, I realized how much I pull my identity from my children rather than from who I am in God through Christ. And then I start to believe I am all powerful in their lives and the only good that will come out of them will be a direct result of my constant attention to discipline and correction.</p>
                    <p className='p'>Yes, I have an it's-all-about-me issue.</p>
                    <p className='p'>So I have to ask myself,<i>Why do I need them to do well? What difference does it make to me if they don't? </i>I am still saved by His grace according to His mercy. I am not God to my children. I can trust Him to be a more ever-present, powerful influence in their lives than I am.</p>
                    <p className='p'>Ephesians 2:6 talks about us being seated with Him in the heavenly places. This isn't a dimensional position, but a position of authority and power.<i>Present</i> authority and power. As in<i>right now!</i></p>
                    <p className='p'>Read what Kay Arthur says about this:</p>
                    <p className='p'><i>When you don't understand God's mercy, you are slow to run to Him in the time of need, and although the enemy cannot separate you from the love of God in Christ Jesus, once you are saved he delights in deceiving you so you don't appropriate what is yours. His strategy is to convince you that whatever you need from God you don't deserve. He wants to convince you that you shouldn't ask Him for it or expect Him to give it to you.*</i></p>
                    <p className='p'>I bought the lies.</p>
                    <p className='p'>The lies that say, “if your children fail, you fail” and “when your children make bad choices, it's a reflection of your parenting.”</p>
                    <p className='p'>The fact is, I haven't neglected teaching them. I have poured hours into training them how to study, how to make decisions that don't hurt them or anyone else, explaining to them how important it is to brush their teeth, and endless other life lessons. And I've prayed for them along the way.</p>
                    <p className='p'>Is there more I could have done? More I should be doing now? Without a doubt, there is. But mostly that involves including more grace and mercy. Because all the correcting and lecturing in the world won't direct a child's heart like receiving grace and mercy. That's how children learn of God.</p>
                    <p className='p'>So, what does that look like? I'm still trying to figure that out. Here's what I do know: Whether or not my children fail their exams speaks nothing to my effectiveness as a parent. What does speak to it is how I demonstrate God's mercy to them in all situations. Even semester exams.</p>
                    <p className='p'>So next week, after exams are over and there's nothing more they can bring to the table, we are going to move on - all of us - to focusing on The Reason for the season.</p>
                    <p className='p'>And maybe even bake some cookies and work some puzzles.</p>
                    <p className='p'>Because January will be here before we know it, along with another chance to think better, do better, be better, for all of us.</p>
                    <br />
                    <br />
                    <br />
                    <p className='p'>*LORD, Is It Warfare? Teach Me to Stand, Kay Arthur</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec162014;