import React from 'react';
import { Link } from 'react-router-dom';
import './arch2010.css';

const May012010 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2010/aquaglobe.jpg" alt="Aqua Globe" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may012010'>
                    <h5>May 1st, 2010</h5>
                    <h3>Deadheading Not Required</h3>
                    <p className='p'>This weekend my dear husband dragged me to the flower store to buy some colored nuisance for our yard. What I'm talking about is those pretty petaled thingies that require love, attention, and water. Don't get me wrong, I love to see all the pretty flowers and smell the fragrance of them on the breeze. But I do not want to be the one to plant them, cultivate them, feed or weed them, or take care of them an any way. “Yarding” is not my thing.We ended up purchasing two hanging baskets full of absolutely beautiful arranged flowers. The tags said they are for full sun so I know I can hang them on my south-facing deck without fear that they'll be scorched to smitherines on the first really hot, sunny day. I learned my lesson about that last year.What got me super excited about these prettier-than-weeds plantings is the line on the tag that read “deadheading not required.” (Just the fact that I know what deadheading is in relation to flowers is amazing in itself.) It might as well have had a big tag that said “Heather! Buy these flowers. These are for YOU!”</p>
                    <p className='p'>My greatest “find” last year was the as-seen-on-TV AquaGlobe. This truely is the best thing since sliced bread for those of us with brown thumbs. And more than that, those of us whose lives are so crazy buzy they cannot possibly remember to water the colored nuisance (I mean, the pretty flowers) on their deck everyday. So, into my new hanging baskets of flowers go my AquaGlobes filled to the brim.</p>
                    <p className='p'>Hanging basket of flowers ... $24.99. As-seen-on-TV AquaGlobes ... 2 for $9.99. “Deadheading not required”…..Priceless.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May012010;