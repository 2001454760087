import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Apr132011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/cinderella.jpg" alt="Cinderella dancing" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 apr132011'>
                        <h5>April 13th, 2011</h5>
                        <h3>Cinerella</h3>
                        <p className='p'>What was your favorite book as a child...</p>
                        <p className='p'>...and why?</p>
                        <p className='p'>Cinderella. I knew the words by heart. I was fascinated by the story of a poor girl turned princess, rescued by her prince.</p>
                        <p className='p'>Right now, more than when I was a child, I can relate to the loneliness Cinderella felt. She could only share her feelings with the animals around her; her only friends. Were the animals attracted to her somehow or did they just happen to be there?</p>
                        <p className='p'>There's mystery with the glass slipper - whose foot will it fit? Only the one who belongs with the prince. Only she will do. She alone captured his heart.</p>
                        <p className='p'>Cinderellas first night with her prince was magical. But their time together was limited. Neither was ready to part when their time was up. The pain of her sudden departure was more than he was willing to accept so he went looking for her; the love of his life. Sadness overwhelmed her. Her dream-come-true had ended … or so she thought.</p>
                        <p className='p'>Fate brought them together. Love binds them for eternity.</p>
                        <p className='p'></p>
                        <p className='p'></p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Apr132011;