import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Sept282011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/tp-holder.jpg" alt="Toothpic holder" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 sept282011'>
                        <h5>November 3rd, 2011</h5>
                        <h3>She Was Just “Differnt”</h3>
                        <p className='p'>I have this little trinket. It sits among other miscellaneous trinkets in my purse. It is my toothpick holder and it holds more than toothpicks, it holds a story….</p>
                        <p className='p'>I bought this little wooden toothpick holder years ago in my hometown, the name of which is printed on the side in “touristy-type” writing - Torrington, Wyoming.</p>
                        <p className='p'>I don't remember why I was back in Torrington but I was downtown one day doing some reminiscing that I called shopping. When I ran across this tourist gift trinket I knew I had to have it.</p>
                        <p className='p'>I spent most of my formative years gleaning little tidbits from my stern, correct-your-grammar, wrinkly-faced grandmother. She carried one of these little wooden toothpick holders in her purse. It looked just like mine - little cylindrical cedar tube with a wooden stopper. I don't remember if hers had any writing on it but, now that I look at mine, hers just might have originally and wore off over time like mine is doing. She always had to have a toothpick after she finished a meal and they were always close at hand in her little wooden holder.</p>
                        <p className='p'>Toothpicks were somewhat precious to my grandmother, who by the way had all her own teeth when she died. I remember her being a little prickly when someone asked to borrow one of her toothpicks. A piece of gum, no problem. A calculator, she always had one of those too. But asking for one of her toothpicks was like asking for her last toothpick.</p>
                        <p className='p'>My grandmother's toothpicks were not ordinary round toothpicks either. No, she preferred the flat ones; said they got between her teeth better. She was a practical woman if nothing else. Why fight with a round toothpick if you can't get it between your teeth?</p>
                        <p className='p'>Every time I see my little wooden toothpick holder in my purse I am reminded of many of my grandmother's eccentricities. She was fantastically eccentric. She would say she was just “differnt.” And she was just OK with that. In fact, I think she preferred it.</p>
                        <p className='p'>I think tomorrow I am going to buy some flat toothpicks to go in my Torrington toothpick holder.</p>
                        <p className='p'>I miss you, Marcie. You are forever in my toothpick holder.</p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Sept282011;