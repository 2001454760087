import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Nov252013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/caramel-apples.jpg" alt="Caramel apples" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 nov252013'>
                    <h5>November 25th, 2013</h5>
                    <h3>Perfect Caramel Apple Flop</h3>
                    <p className='p'>For a Thanksgiving meal at church yesterday, everybody was supposed to bring a salad and a dessert. So, I decided to make Salted Caramel Apples from a recipe I had recently gotten in the mail. The caramel required only four ingredients and sounded super easy to make - easy enough to invite the boys to help me, which they were excited to do.</p>
                    <p className='p'>Perfect.</p>
                    <p className='p'>Either the recipe was flawed or I expected too much because it turned out to be THE biggest mess that took two tries to get right and then it still wasn't right.</p>
                    <p className='p'>The caramel looked perfect, smelled perfect, even tasted amazing! But, no matter how much we cleaned and scrubbed apples, dipped at different temperatures, or put right into the freezing cold night out the back door, that perfect caramel would not stick to the apples. Every time we tried, the caramel would run completely off the apple, taking with it the nuts or mini M&M's.</p>
                    <p className='p'>It needed to cool more, I thought. So we put it back into the fridge. But it didn't make it to perfect before bedtime. We decided to get up early enough to try again Sunday morning. It would be our last chance to get it to work.</p>
                    <p className='p'>But Sunday morning, when I pulled the now stiff caramel out of the fridge, I knew Perfect had just flown out the window. And not only had our apples flopped, but we had spent so much time trying to get them to work that we never got the salad put together. And we were out of time.</p>
                    <p className='p'>This was going to be our last meal gathering with this church family and I had wanted it to be perfect. At the very moment I started to think about just staying home and moping, the Peace that I often don't understand lifted my chin, took off my Martha hat and told me to get my Mary tail to church.</p>
                    <p className='p'>So, off we went, empty-handed. Not perfect, I thought, but we'll make the best out of it anyway. What I forgot was that worship isn't about the perfect recipe or even perfect people, but about a perfect God. All He wants is for us to come to Him with nothing but ourselves, empty-handed. And that's what I did yesterday.</p>
                    <p className='p'>And it was perfect.</p>
                    <p className='p'>“Martha, Martha,” the Lord answered, “you are worried and upset about many things but only one thing is needed. Mary has chosen what is better, and it will not be taken away from her.” ~Luke 10:41-42.</p>
                    <p className='p'>The worship service was so incredible, I was tempted to pull out my phone and videotape it so I could remember it later. And, as is often the case in a Baptist church, there was more food than could have been consumed by twice as many people. My last-ditch effort run to the grocery store for boxed cookies went unnoticed. And our late entry for dinner afforded me the opportunity to share a table with a lovely family. We had a great time.</p>
                    <p className='p'>God's perfect shows up when we are willing to leave ours behind.</p>
                    <p className='p'>But he said to me, “My grace is sufficient for you, for my power is made PERFECT in weakness.” ~2 Corinthians 12:9 (emphasis mine)</p>
                    <p className='p'>We've given up the caramel apple idea and decided our caramel makes a really good apple dip.</p>
                    <p className='p'>In fact, it just might be perfect after all.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Nov252013;