import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const May242016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/spring-chicken.jpg" alt="Spring chicken" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may242016'>
                    <h5>May 24th, 2016</h5>
                    <h3>No Spring Chicken</h3>
                    <p className='p'>Tonight I attended a College Readiness meeting with my Junior-to-be kid. This is Kid #3, meaning this is the third of these meetings I've attended, and it was exactly the same as the previous two, so I probably could have skipped it. It was mostly all been-there-done-that type stuff except for one HUGE difference: the crowd of parents.</p>
                    <p className='p'>It slowly dawned on me during the meeting that I am no longer the “young” mom of the group.  Don't get me wrong, there were plenty of gray hairs and reading glasses in the group. My own reading glasses stayed quietly tucked inside my purse, even though there was print so tiny - and I mean T I N Y - I know I couldn't have read it even with my glasses on. But, even without my glasses on, it was painfully clear that I fall somewhere in the lower middle of the youngest moms.</p>
                    <p className='p'>Thankfully, next year's college readiness meeting with my youngest will be the last time I must endure this glaring realization.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May242016;