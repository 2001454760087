import React from 'react';
import { Link } from 'react-router-dom';
import './arch2010.css';

const Nov182010 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2010/post-it.jpg" alt="Post-It Notes" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 nov182010'>
                    <h5>November 18th, 2010</h5>
                    <h3>Me. Take it or leave it.</h3>
                    <p className='p'>I don't like saggy socks. You know the kind. They feel good and tight when you put them on in the morning but by afternoon they're all stretched out and saggy. I'd rather go barefoot.</p>
                    <p className='p'>I don't like to listen to people chewing in a quiet room. Well, ok, the only experience I have of this is my own kids. I guess I don't like to hear my children chewing.</p>
                    <p className='p'>I like my scissors where they belong. I don't like to have to look for them when I need them. When I need them, I need them now and I don't want to have to wait for them to be found. I don't mind if you use my scissors but there are four pair in my kitchen drawer and I like to open that drawer and find all four pair in there.</p>
                    <p className='p'>I like pens. I get a kick out of office supplies in general but I have a particular fetish for pens. Many a waitress has lost their pen to my purse collection. I like pens that write a certain way. My favorites are ballpoint with no drag. If they drag, they're trash. Actually, I hardly ever throw away a pen that still writes but those are the ones the kids are allowed to use. (Note to waitresses: Offer only sloppy gel pens or ballpoints that drag and you're guarenteed to keep your pen.)</p>
                    <p className='p'>I love the snooze button on my alarm clock. Always have. I think I'm addicted to it. My husband calls it lazy. As in, “get your lazy butt out of bed instead of hitting the snooze button 25 times.” Those nine minutes in between snoozes is the best dream sleep. I think it's impressive I can hit it 25 times without feeling compelled to get up.</p>
                    <p className='p'>I can not chew gum without popping it. It's just not possible.</p>
                    <p className='p'>I love to cook.</p>
                    <p className='p'>I hate to clean.</p>
                    <p className='p'>I hate gardening or yard work of any kind. If I had the money I would hire a professional landscaper to maintain my yard with lots of pretty flowers.</p>
                    <p className='p'>If I had the money I'd also hire a housekeeper. The live-in kind that would be around to drink coffee with me in the morning and volunteer to clean up the cat puke before anyone else even admits to seeing it.</p>
                    <p className='p'>I prefer onion rings over ice cream. (Not to be confused with onion rings ON ice cream.) I don't like cake, and never eat the frosting, but I'll eat my daily calorie allowance in chips and salsa.</p>
                    <p className='p'>I don't mind a mess as long as it's in a respectable pile that isn't on the floor. I currently have three respectable piles on my kitchen counter. Jason, one of them is your mail.</p>
                    <p className='p'>I don't make the bed. I just pull up the covers and throw on the pillows. It looks made and that's good enough for me.</p>
                    <p className='p'>I own 21 bottles of perfume. I never wear the same scent twice in a row.</p>
                    <p className='p'>I think the two greatest inventions ever are Post-It Notes and Ramen noodles. Seriously. Where would we be without them?</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Nov182010;