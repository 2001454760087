import React from 'react';
import './main.css';

const Reviews = () => {

    return (

        <section className="main-container">
            <div>
                <p>REVIEWS</p>
            </div>

        </section>

    )
}

export default Reviews;