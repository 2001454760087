import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jun122015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/alligator.jpg" alt="Alligator" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jun122015'>
                    <h5>June 12th, 2015</h5>
                    <h3>Life on Lake Placid</h3>
                    <p className='p'>When we first moved into our house sixteen months ago, I asked our neighbor if there were alligators in the pond behind our two houses. I think she laughed a little at my question, I can't remember. I do, however, remember her answer. She said no, but she thought maybe the pond had some fish.</p>
                    <p className='p'>I wouldn't learn until much later that this particular neighbor never comes outside because she's allergic to the sun. It would have surprised me, though, if she had said anything else just because our neighborhood, even with its many ponds, is a golf course community tucked inside four walls of Georgia highway.</p>
                    <p className='p'>I knew from my husband that indeed there was an alligator in a pond near the airport. Him and his coworkers call it “Nessi,” a cute nick name for the Loch Ness monster. Because of this, I have kept a peeled eye when passing ponds around town, hoping to catch a glimpse of a gator if, for no other reason, than to add some excitement to an otherwise uneventful day of errands and taxiing kids to and from. I've never seen one.</p>
                    <p className='p'>Until two days ago. In my own back yard.</p>
                    <p className='p'>Believe me when I tell you that knowing there are alligators in this part of the country (I've seen some big ones at the Savannah National Wildlife Refuge, where they belong) did not prepare me for having one show up in our back yard.</p>
                    <p className='p'>Our youngest child has what might be considered a “colorful” imagination so when he burst through the door claiming there was a crocodile in our pond, of course I was skeptical. So I grabbed the good camera with the super zoom lens (I was skeptical, not cynical) to get an investigative look without getting too close. Sure enough, he was right.</p>
                    <p className='p'>Of course, it was an alligator rather than a crocodile. But - as my son said when I corrected him - same thing. It is more the same thing than it isn't when one is in your back yard. After a few fully-zoomed pictures (for proof, of course) I contacted our neighborhood property manager to find out what to do about it. While I waited for a response, I held an afternoon vigil at the window overlooking the back yard. I didn't want to lose sight of him. The response: I'll come take a look in the morning.</p>
                    <p className='p'>Morning is so far away when you have an alligator in your back yard.</p>
                    <p className='p'>When Gary, the property manager, showed up the next day - closer to noon than actual morning, because it is the South after all - I showed him the pictures I had taken so he could see the perilousness of our plight. He even said<i>oh yeah, he's gotta go</i>. Yes, exactly. Thank you.</p>
                    <p className='p'>But when he spotted the ominous, soon-to-be-boots, threat floating on the top of the water, his opinion changed in a breath.<i> Oh he's just a baby</i>. OK, baby boots then. Maybe just a belt. What he said next, along with the conversation that followed, left me bewildered and befuddled.</p>
                    <p className='p'>He promised to keep an eye on him. What? You're not taking him away? There's an ALLIGATOR IN MY BACK YARD and this is not a problem for you? Because let me assure you, it is a problem for me.</p>
                    <p className='p'>Apparently, alligators are common in South Georgia back yards. Mr. Gary informed me he is currently<i>keeping an eye</i> on no less than twenty (that's 2-0) in our neighborhood ponds, having removed two last week.  Apparently, with the copious amounts of rain we've gotten lately, swollen tributaries create an influx of gators making it into low country suburban neighborhoods. Who knew?</p>
                    <p className='p'>I was told there are two criteria for calling the Alligator Police to come capture neighborhood gators: they have to be at least four feet long (ours is just short of this marker as far as we can tell) or they have to be a nuisance. So, in other words, if he tries to eat our cat we can call the cops? Great.</p>
                    <p className='p'>I have calmed down since Wednesday about having a living, breathing, swimming baby alligator in my yard. I've even named him Beni, from these lines in the 1999 movie, The Mummy:</p>
                    <p className='p'>Rick: Well if it ain't my little buddy Beni. I think I'll kill you.</p>
                    <p className='p'>Beni: Think of my children.</p>
                    <p className='p'>Rick: You don't have any children</p>
                    <p className='p'>Beni: Someday I might.</p>
                    <p className='p'>Yes, exactly.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jun122015;