import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Dec022013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/erin-healy.jpg" alt="Author Erin Healy" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 dec022013'>
                    <h5>December 2nd, 2013</h5>
                    <h3>Author Interview: Erin Healy</h3>
                    <p className='p'>I am excited to have the opportunity to have Erin Healy on my blog for my first-ever author interview. Her latest supernatural thriller,<a href="http://www.erinhealy.com/2013/10/26/stranger-things/" target="_blank" rel="noopener noreferrer"><i><b>Stranger Things</b></i></a>, comes to stores on New Year's Eve.</p>
                    <p className='p'>When I read what this new book is about I had to back up and read it again. Sex trafficking - the same topic that I'm writing about in my novel. Coincidence? I don't think so.</p>
                    <p className='p'>Sex trafficking is finally being exposed and the awareness of its permeation of our society is what, I believe, will bring it to an end. I am thrilled to partner with other writers, like Erin Healy, to bring the light of awareness to this difficult topic.</p>
                    <p className='p'>Library Journal says: “Serena Diaz's teaching career came to an abrupt end when a student falsely accused her of sexual misconduct. Seeking solace in the woods, she discovers that a gang of sex traffickers has taken over a vacant house. Serena is almost captured by one of the criminals but is saved by an unknown man who has been shadowing her. He is shot, and Serena escapes with her life. But she is drawn to know more about this stranger who died for her. What follows is a suspenseful story of danger and pure evil. Whom can Serena trust in a world that seems intent on serving its own self-interests? VERDICT Healy (<i>Afloat</i>; coauthor with Ted Dekker,<i>Burn</i> and<i>Kiss</i>) has written an edgy, fast-paced spiritual thriller that will please Dekker fans.”</p>
                    <p className='p'><b>How was your idea for<i>Stranger Things</i> born?</b></p>
                    <p className='p'>Two years ago, during a Good Friday service, my pastor (Kelly Williams of Vanguard Church, Colorado Springs) asked the congregation: “If a complete stranger died while saving your life, wouldn't you want to know everything you could about that person? Wouldn't you want your life to honor that person's death?” He challenged us to consider Jesus Christ in a new light—as a stranger, as a savior we might not know as well as we think we do. This idea has roots in Romans 5:8—“While we were still sinners, Christ died for us.” Before I ever had the chance to know him, while he was a complete stranger to me, Christ died for me. The Message translation says “when [I was] of no use whatever to him.” Why would he do that? Have I investigated him thoroughly enough to connect my own life with his purposes? This is all background, though. Stranger Things isn't an overtly Christian tale as my previous novels have been, but it's a parable about these questions.</p>
                    <p className='p'><b>Stranger Things sounds like a pretty dark read. Why did you choose to write about sex trafficking?</b></p>
                    <p className='p'>Human trafficking (of which sex trafficking is a subcategory) is the world's third-fastest growing illegal industry behind drugs and weapons. It is the most horrifying kind of modern captivity I can imagine, and my research proved that even my imagination fell short of reality. I picked it because it's a real contemporary crisis, but also because it profoundly symbolizes the kind of bondage that Christ came to end (Isaiah 61:1-3). Freeing the captive, physically and spiritually, is a high calling for followers of Jesus who want to express their gratitude for his sacrifice and demonstrate his love through the continuation of his work.</p>
                    <p className='p'><b>What does all this have to do with the “thin places” that you're always talking about?</b></p>
                    <p className='p'>The traditional (Celtic) definition of a thin place is a physical location in the world where the division between physical and spiritual realities falls away, a place where we can see the greater truth of our existence. In my stories I use the term “thin place” to define moments when a person experiences a sharpened spiritual awareness about what's really going on in his or her life. Stranger Things is the first novel in which I've combined both ideas. The thin place is a physical location, a burned-out house in a sparse terrain, where Serena discovers her purpose. “There are places in the world where you will encounter things so real that you will be surprised others don't have an identical experience,” Serena's father tells her. “But then you will realize that the clarity given to you is a gift from God. Perhaps this gift is just for you, maybe also it will touch the lives of others.”</p>
                    <p className='p'><b>Did anything surprise you while writing the novel?</b></p>
                    <p className='p'>I started with intentions to write about an Asian-based trafficking ring, but in the course of my research was distressed to learn just how close to home the problem lies. Though it's impossible to get a precise count of how many people are victims of sex trafficking in the US, most estimates fall between 100,000 and 300,000 (mostly women and children). Since I learned this my own awareness has expanded, and I'm happy to see just how many efforts are already underway—not only in the US—to end this atrocity. The Polaris Project is a great place to begin learning about global human trafficking.</p>
                    <p className='p'><b>What do you hope readers will take away from Stranger Things?</b></p>
                    <p className='p'>I hope the novel is layered enough to meet each reader individually. Maybe some will be challenged to investigate Jesus Christ further. Maybe some will use their new awareness of trafficking to do something about it. (I've joined the prayer team of a local home for girls rescued from sexual slavery.) To date my favorite response to the book was from the person who found herself looking in a new way at the strangers who surrounded her. She felt unexpectedly protective and concerned, on heightened alert to ways in which she might be able to help them. In other words, ways in which she might be able to do what Christ did for her. So many opportunities! If we all moved through the world with eyes like that, what might change for the better? I love to think of all the possibilities.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Dec022013;