import React from 'react';
import './main.css';

const Recent = () => {

    return (

        <section className="main-container">
            <div>
                <p>RECENT</p>
            </div>

        </section>

    )
}

export default Recent;