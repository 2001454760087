import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Jun112012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/dog-cat.jpg" alt="Dog and cat in yard" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jun112012'>
                    <h5>June 11th, 2012</h5>
                    <h3>The Season of Dog Hair</h3>
                    <p className='p'>It's that time of year again.  The season of dog hair.</p>
                    <p className='p'>I love my dog.  I really do.  He's a good dog.</p>
                    <p className='p'>Yukon is a seven year old Alaskan Malamute.  He is not a barker, a leg humper, a crotch sniffer, a licker, a chewer or a digger.  For the most part.  There is this one thing he likes to chew on and that is the pop up cover where the sump pump spits out into the back yard.  I think it's made of the same material as Nylabones so, no wonder.  He will sniff a crotch at first introduction but once he knows you're safe, he doesn't bother again.  And, if left in the house by himself for any length of time, he will drag used Kleenex out of the bathroom trash.  He only carries them a few feet and then leaves them for you to see that he's been snooping.</p>
                    <p className='p'>I will admit my affection and admiration for this dog is far greater than my dear husband can muster.  I see my dog through my pink glasses while David has threatened over and over through his blue megaphone to get rid of the “d*mn useless mutt.”  I shudder to think that I did almost give him away to keep the peace.  How would I ever know when it's noon on Mondays without having that tell-tale howl? Only those who live where there are tornado sirens will understand that.</p>
                    <p className='p'>Yukon, is in fact, our second Mal so it's not like we didn't know what to expect this time every year.  Mal's typically “blow” their undercoat once or twice a year.  It's an amazing transformation really.  They go from full sled dog fluff to looking like they got a coyote haircut with an atrocious molting look in between.  The problem is that Kansas is really not the place to own a malamute in the summer.  It's too hot so Yukon is an indoor dog from about the time we turn on the air conditioning until we are back to sleeping with the windows open.  And during those many months we have to vacuum every day.</p>
                    <p className='p'>Every day.</p>
                    <p className='p'>Sometimes 2-3 times a day.  And then again right before we know company is coming. It's ridiculous.  I've always said that pet hair is one of the dietary staples in our house.  But during these few first weeks of summer Yukon sheds a small dog every other day.  And picking the white tufts of shed but yet-to-fall hair becomes an out of body thing for many in the household.  Sometimes we just don't realize we are doing it until we have a handful of collected hair with nowhere to put it.</p>
                    <p className='p'>I swear there are times I see, out of the corner of my eye, a mouse scurrying across the floor only to realize it's just a hair bunny.  And ten of his friends.</p>
                    <p className='p'>So, even though I have assigned daily dog brushing to the kids, there will still be hair everywhere until Yukon is done with his summer blow.  He is one of our family so we put on our big kid undies and deal.</p>
                    <p className='p'>You might want to bring a tape roller if you come to visit.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jun112012;