import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Mar182013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/weekly-schedule.jpg" alt="Weekly Schedule" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar182013'>
                    <h5>March 18th, 2013</h5>
                    <h3>A Little of This, A Little of That</h3>
                    <p className='p'>I had a couple friends ask me recently what I do all day. This is not the first time someone has asked me this question since I made the jump to full-time writer. They ask because they never see me anymore. I have become somewhat of a recluse.</p>
                    <p className='p'>Except for Wednesdays. Wednesday is my grocery shopping day. And that is only because there are people who live in my house that like to eat. And due to the invisible hat on my head that says Mom, they expect that I will procure for them something that resembles dinner. Even if it's breakfast. Or Ramen noodles.</p>
                    <p className='p'>I've come to realize that when people ask what I do with my days they don't like my answer of a little of this, a little of that. Even though that is exactly what my day looks like most of the time.</p>
                    <p className='p'>Being a writer doesn't mean sitting at my computer and actually writing eight hours every day. I wouldn't do that even if my brain would cooperate that long because my back would revolt and I'd have to have a special desk built so I could type standing up.</p>
                    <p className='p'>No worries. My brain says that's a no-go.</p>
                    <p className='p'>Thankfully, for back relief, there's multiple trips to the kitchen for coffee. And the unending avenues for research.</p>
                    <p className='p'>I think it might scare my friends if they knew I spent a substantial amount of time last week researching chloroform. And that I have read more books and seen more videos on sex trafficking than the average person should ever have to experience in their lifetime. Next week it's on to hard-core drugs.</p>
                    <p className='p'>Having said that, it might be disturbing for some to know that my favorite part of writing is the research. I do get carried away with it sometimes. I have to remind myself that the book isn't going to write itself with all the information I've collected. At some point that information has to make its way into the story via the keyboard.</p>
                    <p className='p'>In the last year+ that I have been writing full time I have redone my work schedule about a dozen times, trying to find the formula that works best for me. I knew I'd always have to work when the boys are at school and the house is quiet because I have major ADD tendencies and am easily distracted.</p>
                    <p className='p'>I cannot even have the radio on. I end up singing to songs I know (and the words make their way onto the page) or I get distracted by the commercials.  I hear something that reminds me I need to water my plants or give the pets their meds or clean the dryer vent.</p>
                    <p className='p'>I do have to have some sort of background noise (music with no lyrics, my little space heater, etc.) otherwise I get distracted by the ringing in my ears. I suffer from tinnitus and apparently there isn't anything that can be done about it.</p>
                    <p className='p'>I know this because I forgot to turn on my work noise one day when I sat down to write, got distracted by the ringing, which got me concerned that I was losing my hearing so I Googled about the ringing to self-diagnose (because that's what I do) and spent the next two hours learning about tinnitus. Helpful to writing only if I ever have a character with this highly annoying condition.</p>
                    <p className='p'>So, back to what I do all day. A little of this, a little of that.</p>
                    <p className='p'>As my work schedule stands now, I am “in the office” in the mornings. Except Thursdays. Thursdays mornings I have Bible study group.</p>
                    <p className='p'>I tried writing after lunch but found myself getting sleepy more days than not and discovered I am a very good afternoon napper. A few weeks of nothing-but-blank-page later, I decided I needed to change that schedule.</p>
                    <p className='p'>Afternoons are when I run errands, workout, get groceries for the week, and try to get a few household chores done so, just in case anyone drops by, it doesn't look like we should be on an episode of Hoarders.</p>
                    <p className='p'>Unless it's Thursday. I'm in the office on Thursday afternoons (probably napping.)</p>
                    <p className='p'>Or unless it's hair day. Not much takes precedence over hair day. (And nail day is whenever I can fit myself in.)</p>
                    <p className='p'>So, there you are. A little this and that of my day.</p>
                    <p className='p'>Schedule subject to change without notice.</p>
                    <p className='p'></p>
                    <p className='p'></p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar182013;