import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Aug142013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/80s-women.jpg" alt="" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 aug142013'>
                    <h5>August 14th, 2013</h5>
                    <h3>(Mostly) Happily Mothering Boys</h3>
                    <p className='p'>I have always said that I am thankful to have all boys. And most of the time I mean it.  But there are just some things that are lost on boys. My boys, anyway. Shopping for school clothes is one of them.</p>
                    <p className='p'>I don't expect my boys to look forward to shopping for school clothes when they aren't excited about clothes shopping any other time of the year.  If I thought I could get away with doing the shopping on my own and everything would fit perfectly, I would do that. In a heartbeat, I would do that. But I don't like the hassle of returning stuff so I drag them along.</p>
                    <p className='p'>And I do mean drag. With the promise of a Sonic drink stop on the way.</p>
                    <p className='p'>Kids need to be comfortable with their attire. And middle school kids need for others to be comfortable with their attire. So I put a choke hold on my  “mom” opinion and away we went.</p>
                    <p className='p'>Back in my school days, the great 80's, fashion was all over the place. As long as you were dressed, you fit into one of any number of acceptable fashion categories, provided you were wearing your clothing items correctly.</p>
                    <p className='p'>My frustration grew with every rejection to my subtle suggestions of picks on this trip.</p>
                    <p className='p'>I am still baffled at my youngest, who didn't like the plaid shorts because they “looked like boxers with a zipper” and yet he chose shoes that look like an Adidas billboard.</p>
                    <p className='p'>And then it took me thirty minutes of explaining that a rectangle binder will not fit in a triangle-shaped, sling-style backpack. I can't win.</p>
                    <p className='p'>After two hours of <i>are we done yet, I don't really like that one</i>, and <i>can I go wait in the car</i>, we managed to purchase just enough new stuff to get them by until cold weather hits and then we'll have to do the dragging all over again to find jeans and long-sleeved shirts that don't say Tony Hawk or the name of any Disney character.</p>
                    <p className='p'>After the clothes are bought though, life gets pretty easy. Too easy. They will pull on the first pair of shorts they run across after their feet hit the floor in the morning. And more days than not, their shirt is a wrinkled mess straight out of the laundry basket.</p>
                    <p className='p'>They get tired of me sending them back to their room to put on something that looks a little less third-world orphan child. I get tired of the looks I get in return.</p>
                    <p className='p'>At the end of the day, pretty much every day, I am still thankful to be the mother of boys. They may not always be color-coordinated, well-dressed, fashion statements, (well, hardly ever,) but they're mine.</p>
                    <p className='p'>And someday they might have girls that I can take shopping.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Aug142013;