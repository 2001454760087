import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Jan122015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/linen-closet.jpg" alt="Linen closet" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan122015'>
                    <h5>January 12th, 2015</h5>
                    <h3>Just In Case - Confessions of a “Collector”</h3>
                    <p className='p'>I had what I'll call a<i>moment</i> with my husband this weekend. It wasn't an argument; I agreed with what he was saying.</p>
                    <p className='p'>Our laundry room needed cleaned and I was the reason. I have a really hard time throwing things away and our laundry room has lots of drawers and cupboards, meaning many places to stuff my “collections.” Things like plastic bags - the really big ones - from Target and T.J. Maxx. Pens, pencils, highlighters, and markers that I've had for years. Candles and shoelaces and empty cardboard boxes.</p>
                    <p className='p'>And then there were the towels. Oh my.</p>
                    <p className='p'>My husband wasn't critical at first. He just wanted to know why one entire two-door cupboard and two deep drawers were full of towels. But, I instantly go on the defensive, because that's what I do when he asks about my “collections.”</p>
                    <p className='p'>He kindly offered to clean the laundry room for me. At least that's what he'd tell you. But both of us knew (this comes from being married for nearly twenty-six years) that this was more like an ultimatum - either I clean it or he'll come in with a big trash bag and “clean” it for me.</p>
                    <p className='p'>I think he might have even said the word<i>hoarder</i> at one point, but I can't be certain.</p>
                    <p className='p'>It was probably at this point (it's hard to recall exactly because I was busy at the time scrambling to find a way to save my stuff!) that I nearly yelled, “I know I have a problem and, if you'll just give me five minutes to swallow that, I'll be able to accept it and clean this room.”</p>
                    <p className='p'>I say<i>nearly</i> yelled, because I don't yell at my husband when I know I'm wrong. I save the yelling for the times I know I'm right. And for the kids. (Except not this year, which is an entirely different post.)</p>
                    <p className='p'>A couple of hours later I had filled the big trash bag myself. And cleaned off the counters, swept the floor, and dusted many<i>many</i> days of lint off the washer and dryer. (Hey, I'm the first one to admit that I am NOT Mrs. Housekeeper.)</p>
                    <p className='p'>I later admitted to my husband that it felt good to have the laundry room clean again. Because, really, it hasn't been since the day before we moved in almost a year ago. Goodness.</p>
                    <p className='p'>What I didn't admit to him was how many towels I cleared out. Of course, he will know now if he reads my blog posts. Fifty-two. No, that's not a typo. Fifty-two towels of varying size, color, and length of use. Only fourteen of those went into the Goodwill donation bag because most of them were so threadbare or stained they had exceeded the point where anyone would pay money for them.</p>
                    <p className='p'>Why was I keeping them? In case I needed them, duh! You just never know when you'll need a towel you won't care ends up in the trash because you won't put it in your washing machine. Like to wash the car, wipe the dog's feet, clean up puke that missed the intended receptacle. There are so many reasons to have throw-away towels. I just happen to have a decade-old collection of them. Or maybe more like two decades.</p>
                    <p className='p'>I kept eight. Just in case.</p>
                    <p className='p'>Someday I will have to address my fetish for, and overwhelming collection of, writing utensils. But that's a struggle for another day.</p>                   
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan122015;