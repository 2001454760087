import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Jan112013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/bookshelf.jpg" alt="Bookshelf" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 jan112013'>
                    <h5>January 11th, 2013</h5>
                    <h3>My Year of Books</h3>
                    <p className='p'>I always feel like I never have enough time to read. There are always more unread books on my bookshelf than there are minutes in the day to read them. Of course, that doesn't keep me from buying more books. I figure I'll get to them someday.</p>
                    <p className='p'>Looking back through my book journal, it looks like I took more time to read than I thought I had, having read 24 books in 2012. While this is not a huge number, I'm proud to say I read more than two.</p>
                    <p className='p'>I bought a little Moleskine book journal at the big, multi-story Barnes and Noble store in Kansas City last year and while, historically, I've not been very good at keeping personal logs, this is one I have been faithful at keeping. It feels like Progress (with a capital P) to look back and see books from my bookshelf that I've “knocked out.” Anything that feels like progress is a very good thing.</p>
                    <p className='p'>Other than the memoirs, the only non-girly books I read last year were The Hunger Games trilogy (and to say those aren't girly is pushing it,) of which Mocking Jay received one of only four of my 5-star ratings.  Maybe this means I need to be reading better books, maybe not.</p>
                    <p className='p'>Of the 24 books, only two did I want to re-read immediately after finishing. One, because I felt like there were things I missed along the way and should have taken notes. The other, because I got so caught up in the story, I lost track of reading it like a writer; analyzing the plot, structure and characterization along the way. I truly admire authors who have the talent to write that way. (And the editors who polish the story to that level.)</p>
                    <p className='p'>Two of the memoirs I read in 2012 were research for the novel I'm writing.</p>
                    <p className='p'><a href="https://www.amazon.com/s?k=The+Slave+Across+the+Street+by+Theresa+Flores&crid=1IVL01FSG10AE&sprefix=the+slave+across+the+street+by+theresa+flores+%2Caps%2C109&ref=nb_sb_noss_2" target="_blank" rel="noopener noreferrer">The Slave Across the Street by Theresa Flores</a> is a true account of the author's experience as a victim of sex trafficking. This is the book that really got the wheels turning, so to speak, for my book. To think something like this could happen, literally next door to us, is appalling and frightening.</p>
                    <p className='p'>By far, the most difficult book I tackled this year was Jaycee Dugard's book, <a href="https://www.amazon.com/Stolen-Life-Memoir-Jaycee-Dugard/dp/1451629192/ref=sr_1_1?s=books&ie=UTF8&qid=1357935391&sr=1-1&keywords=a+stolen+life" target="_blank" rel="noopener noreferrer">A Stolen Life</a>. In fact, I put it down about a third of the way through, not intending to finish it. But I had to know how she got away and if the monster who enslaved her got what was coming to him, so I finished it.</p>
                    <p className='p'>Both of these stories broke my heart for all the young ladies out there whose lives will forever be marked by the tragedy they were forced to endure. And I pray they find their way through it so as not to feel defined by it.</p>
                    <p className='p'>One other memoir I read, which wasn't research, was Jeannette Walls' <a href="https://www.amazon.com/Glass-Castle-Memoir-Jeannette-Walls/dp/074324754X/ref=sr_1_1?s=books&ie=UTF8&qid=1357935642&sr=1-1&keywords=the+glass+castle#_" target="_blank" rel="noopener noreferrer">The Glass Castle</a>.</p>
                    <p className='p'>It starts out with, <i>“I was sitting in a taxi, wondering if I had overdressed for the evening, when I looked out the window and saw Mom rooting through a Dumpster.”</i> This opening sentence is what made me buy the book and what followed kept me reading until the end. In the Opinion section of my book journal I wrote, “Wow. This would be an incredible fictional story but blows your mind as a true story.”</p>
                    <p className='p'>Although I gave it only 3 ½ stars, <a href="https://www.amazon.com/Language-Flowers-Novel-Vanessa-Diffenbaugh/dp/0345525558/ref=sr_1_1?s=books&ie=UTF8&qid=1357935729&sr=1-1&keywords=the+language+of+flowers+vanessa+diffenbaugh" target="_blank" rel="noopener noreferrer">The Language of Flowers</a> by Vanessa Diffenbaugh was an interesting read.</p>
                    <p className='p'>The protagonist was so well-developed I wanted to reach through the pages of the book and either strangle her or slap some sense into her. And just about the time I was ready to give up on her, she turns it around. For a bit. This would be a wonderful read for anyone who appreciates the different meanings of flowers.</p>
                    <p className='p'>Here are some of my favorite quotes from <a href="https://www.amazon.com/Help-Deluxe-Kathryn-Stockett/dp/0399157913/ref=sr_1_1?s=books&ie=UTF8&qid=1357935782&sr=1-1&keywords=the+help" target="_blank" rel="noopener noreferrer">The Help</a> by Kathryn Stockett. (Which was rejected over 60 times before it was published!):</p>
                    <p className='p'><i>“If chocolate was a sound, it would've been Constantine's voice singing. If singing was a color, it would've been the color of that chocolate.”</i></p>
                    <p className='p'><i>“…look around, investigate, and write. Don't waste your time on the obvious things. Write about what disturbs you, particularly if it bothers no one else.”</i></p>
                    <p className='p'><i>“It always sound scarier when a hollerer talk soft.”</i></p>
                    <p className='p'>The other three 5-star ratings belong to Christian Fiction stories whose authors I have come to adore and I pray every single writing day that I can emulate. One of which includes my very first Historical Christian Fiction read, <a href="https://www.amazon.com/Home-Drayton-Valley-Vogel-Sawyer/dp/0764207881/ref=sr_1_1?s=books&ie=UTF8&qid=1357935892&sr=1-1&keywords=a+home+in+drayton+valley+kim+vogel+sawyer" target="_blank" rel="noopener noreferrer">A Home in Drayton Valley</a> by Kim Vogel Sawyer.</p>
                    <p className='p'>I loved this story and loved how Kim seamlessly wove Christian truths into it without ever coming across as preachy. My favorite quote: <i>“…she couldn't eat a bite. Joss's sweet words filled her so completely, she didn't have room for anything else.”</i></p>
                    <p className='p'>One of the most heart-wrenching stories I read was Deborah Raney's book, <a href="https://www.amazon.com/Beneath-Southern-Sky-Deborah-Raney/dp/0307458768/ref=sr_1_1?s=books&ie=UTF8&qid=1357935933&sr=1-1&keywords=beneath+a+southern+sky+-+deborah+raney" target="_blank" rel="noopener noreferrer">Beneath a Southern Sky</a>.</p>
                    <p className='p'>This is a tremendous story of love, love lost and Love's promise. My favorite quote: <i>“She turned away, keenly aware that she was closing the cover forever on another chapter of her life. Yet the pages that lay ahead, still to be written, held sweet promise.”</i> Every one of Raney's books I've read leaves me saying to myself, “Wow. This is how I want to write my stories.”</p>
                    <p className='p'>And finally, the book whose characters carried me away through their story, <a href="https://www.amazon.com/Will-Wisteria-Denise-Hildreth-Jones/dp/1595542094/ref=sr_1_1?s=books&ie=UTF8&qid=1357935973&sr=1-1&keywords=the+will+of+wisteria" target="_blank" rel="noopener noreferrer">The Will of Wisteria</a> by Denise Hildreth.</p>
                    <p className='p'>One of the main reasons I loved this book so much is its setting: Charleston, SC. Having been there and fallen in love with my experience there, this story was a fun read. This is <i>“...a Southern tale about learning to love, learning to live, and learning to bend.”</i>  My favorite quotes:</p>
                    <p className='p'><i>“At least he knew now that wherever he was headed, it was away from where he'd been.”</i></p>
                    <p className='p'><i>“We do not travel along the paths of our lives so that we can pretend they never happened. We travel them so that when we encounter others on the same journey, we can comfort and help them.”</i></p>
                    <p className='p'>That wraps up My Year of Books 2012. 2013 is starting with <a href="https://www.amazon.com/Submerged-Alaskan-Courage-Dani-Pettrey/dp/0764209825/ref=sr_1_1?s=books&ie=UTF8&qid=1357935087&sr=1-1&keywords=submerged+dani+pettrey" target="_blank" rel="noopener noreferrer">Submerged</a> by Dani Pettrey, which I bought because of the beautiful cover and its setting in Alaska - a new love for me after traveling there last year. See, isn't it pretty?</p>
                    <p className='p'>And on my Kindle (which I read from at night because it's easier to hold in bed than a “real book” as my Dear Husband likes to call them), <a href="https://www.amazon.com/s?k=the+negotiator+dee+henderson&i=stripbooks&crid=3G8M7BJIM68C1&sprefix=the+negotiator+dee+%2Cstripbooks%2C122&ref=nb_sb_ss_fb_1_19" target="_blank" rel="noopener noreferrer">The Negotiator</a> by Dee Henderson.</p>
                    <p className='p'>I plan to tackle a couple Classics this year. The Great Gatsby tops my list. I saw that's coming out in movies this year - need to read it first. Anna Karenina is on my Kindle, as well as Les Mis, Madame Bovary, Dracula, Uncle Tom's Cabin, Wuthering Heights and The Adventures of Sherlock Holmes.</p>
                    <p className='p'>And I promised my son that, at some point, I would read ALL of the Harry Potter books. Those might take me awhile.</p>
                    <p className='p'>What was your favorite book of 2012? Of all time? Have a 'Must Read' recommendation? Leave me a comment.</p>
                    <p className='p'>Happy Reading!</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Jan112013;