import React from 'react';
import { Link } from 'react-router-dom';
import './arch2016.css';

const May262016 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2016/abc.jpg" alt="abc" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 may262016'>
                    <h5>May 26th, 2016</h5>
                    <h3>The ABCs of Me</h3>
                    <p className='p'>I saw this as a friend's Facebook post and thought it would make a fun blog post instead.</p>
                    <p className='p'>A- Age: 40ish</p>
                    <p className='p'>B- Biggest fear: cancer</p>
                    <p className='p'>C- Current time: morning</p>
                    <p className='p'>D- Drink you had: coffee</p>
                    <p className='p'>E- Every day starts with: coffee</p>
                    <p className='p'>F- Favorite song: depends on my mood, but never a country song</p>
                    <p className='p'>G- Ghosts, are they real: never seen one</p>
                    <p className='p'>H- Hometown: Andover</p>
                    <p className='p'>I- In love with: coffee; do you sense a theme here?</p>
                    <p className='p'>J- Jewelry you wear every day: 4  rings; 1 of them since 4/1/89 and have never taken it off</p>
                    <p className='p'>K- Killed someone?: Of course, haven't you?</p>
                    <p className='p'>L- Last time you?: I've never!</p>
                    <p className='p'>M- Middle name: Michelle (Feather, if you ask my Aunt Karen)</p>
                    <p className='p'>N- Number of siblings: I lost count at 5 or 6</p>
                    <p className='p'>O- One wish: Gone to college, studied Journalism/Communications</p>
                    <p className='p'>P- Person you last called: my mom-in-law</p>
                    <p className='p'>Q- Question you're always asked: you have kids how old?</p>
                    <p className='p'>R- Reason to smile: Isn't is statistically proven smiling makes you feel happier?</p>
                    <p className='p'>S- Song last sang: The Sign, Ace of Base</p>
                    <p className='p'>T- Time you woke up: 6:45, then again 9 minutes later</p>
                    <p className='p'>U- Underwear color: I have several colors.</p>
                    <p className='p'>V- Vacation destination: to the mountains, any mountains</p>
                    <p className='p'>W- Worst habit: tuning out my kids</p>
                    <p className='p'>X- X-Rays you've had: teeth, foot, elbow</p>
                    <p className='p'>Y- Your favorite food: pretzels and milk chocolate, even together</p>
                    <p className='p'>Z- Zodiac sign: Not a believer, but my birthday is in January if you're wanting to get me a gift.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default May262016;