import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Sept192013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/book-misery-loves-comp.jpg" alt="Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept192013'>
                    <h5>September 19th, 2013</h5>
                    <h3>Book Talk: MISERY LOVES COMPANY</h3>
                    <p className='p'>If I were to make a list of all the things I like about Facebook, connecting with favorite authors would be at the top.</p>
                    <p className='p'>I met Rene Gutteridge when she spoke to our American Christian Fiction Writers (ACFW) group several months ago. At that meeting, she generously offered each of us pick of one of her novels. I chose LISTEN, which fast made me a Rene Gutteridge fan. I have stalked her…eh emm, I mean, followed her on Facebook since that meeting.</p>
                    <p className='p'>Recently Rene was hosting a drawing on her Facebook page of her newest release, <a href="https://www.amazon.com/Misery-Loves-Company-Rene-Gutteridge/dp/1414349335" target="_blank" rel="noopener noreferrer"> MISERY LOVES COMPANY</a>, and … (drumroll for dramatic effect) … I won!</p>
                    <p className='p'>I just finished reading this book and I was again impressed with not only the storyline, but the author's execution and incredible ability to create suspense.</p>
                    <p className='p'>What if ….</p>
                    <p className='p'>An author is kidnapped by an obsessed fan? And what if that fan happens to be slightly crazy?</p>
                    <p className='p'>Okay, that story has been done. Being a long-time Stephen King fan (but don't call me if he turns up missing,) I think it was done very well in his book MISERY. And I guess some other people thought that too because in 1990 it was turned into a movie, starring Kathy Bates.</p>
                    <p className='p'>Now, what if the table was turned and it was the fan that was kidnapped by the author? That could be interesting.</p>
                    <p className='p'>That is exactly what Rene Gutteridge did with her just-released book MISERY LOVES COMPANY. Right away I noticed the similarity of this story to Stephen King's book and I enjoyed finding out where Rene (yes, we are on a first name basis - can't say the same for Mr. King and I) would take her story.</p>
                    <p className='p'>Keeping life simple serves as a comfort for protagonist, Jules Belleno, after losing her police-officer husband to a deadly accident. But her life quickly becomes very complicated after a trip to the grocery store ends up in kidnapping and isolation. While a family friend is putting together the pieces surrounding her disappearance, Jules is fighting for her life. Or is she?</p>
                    <p className='p'>She isn't sure whether to be terrified or intrigued by her mysterious abductor. Will her experience end her life or will she finally find her way back to living it?</p>
                    <p className='p'>Rene builds suspense right from the get-go all the way to the end, which kept me turning page after page. And that's what makes a book worth talking about.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept192013;