import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Mar232012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/no-children.gif" alt="No Children" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar232012'>
                    <h5>March 23rd, 2012</h5>
                    <h3>No Pay No Play</h3>
                    <p className='p'>Spring break is nearly over and my rant about the YMCA's poor planning has lost its steam.  But here's what happened:</p>
                    <p className='p'>Monday morning, after having traded Matchbox cars for Ping-Pong paddles and a ball, my boys were turned away from the kid-filled family area with a “you can't be in here.” Imagine their confusion. They had been going in that room to play Ping-Pong while David and I work out for months. Anyway, they find David and tell him what happened and he inquires at the front desk as to why they would not be allowed there. The response he got from the woman at the front desk: “Well, that doesn't make any sense.”</p>
                    <p className='p'>Duh.</p>
                    <p className='p'>So she makes some inquiries to see if there was an answer to be had. Whoever she got on the phone was apparently too busy to come to the front desk but their over-the-phone answer was that they (the parents of kids who were allowed in the play area) had paid for them to be there. To which my quick-witted husband replied that he also pays for his kids to be in there.</p>
                    <p className='p'>I love this about him even though it means I will never win an argument with him.</p>
                    <p className='p'>The woman didn't know how to respond to that so she relayed that message over the phone and, between her and her phone counterpart, they came up with, “they paid more.”</p>
                    <p className='p'>Excuse me? Isn't the YMCA a nonprofit entity?</p>
                    <p className='p'>As a matter of fact, it is.  I looked it up.</p>
                    <p className='p'><a href="https://www.ymca.org/who-we-are "target="_blank">YMCA - Who We Are</a></p>
                    <p className='p'>And they tout “The Y is a nonprofit like no other.” Well, that is for certain. If nonprofit means whoever pays more get to play more.</p>
                    <p className='p'>It turns out there was a day camp going on during spring break week and working parents could sign up (and pay more) for their kids to spend the day at the Y. That's fantastic. I'm very happy there is that option for kids and their parents.</p>
                    <p className='p'>But here's where it gets sticky.</p>
                    <p className='p'>Because it was raining they could not take those kids outside as planned and had to keep them corralled inside. Not a huge deal. It is a YMCA, after all. But, as we were informed by the over-the-phone person, there is a KDHE regulation about supervised day care programs that does not allow for the “unsupervised” to be comingled with the “paying-more-to-play supervised.”</p>
                    <p className='p'>So, it's OK for me to drop my kids off at that play area when there is no supervision, but it's not OK when there are adults present.</p>
                    <p className='p'>Huh. Well, if that doesn't just knot up the panties.</p>
                    <p className='p'>So the message I took away from this was that, even though we have been paying members to this nonprofit establishment for a number of years, it will be my kids who sit out. No notice. No (voluntary) explanation. Just a thank-you-for-your-money-now-go-away.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar232012;