import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Oct112015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/hindi.jpg" alt="Pramukh Swami Maharaj" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 oct112015'>
                    <h5>October 11th, 2015</h5>
                    <h3>Strangely Beautiful, Profoundly Sad</h3>
                    <p className='p'>This time last week I was attending a funeral service at a Hindu temple. It was strangely beautiful and profoundly sad at the same time. I have thought a lot about it since.</p>
                    <p className='p'>Three men lost their lives during a Hindu religious ceremony on Tybee Island two weeks ago today. While many were looking skyward for a glimpse at the super moon lunar eclipse - what Christians admire as God's amazing creation and perfect will - the friends and family of these three men were undoubtedly questioning God's love and protection, following the drowning deaths of their loved ones.</p>
                    <p className='p'>One of the men who died was a long-time friend and colleague of my husband. As much as I have just recently come to understand the cultural bubble we live in, David has understood this for some time, but probably never as much as when attending the funeral service of his friend, Anup.</p>
                    <p className='p'>The atmosphere in the temple (or mandir) was as different as it was familiar. Inside was mostly like any other church, with seats and a stage. The difference being the huge shrine off to one side with statues of unfamiliar gods. It reminded me a little of the golden idols of the Bible. Instead of a piano or organ, the sadhus played music on what looked like a combination of a small piano and an accordion, while sitting on the floor.</p>
                    <p className='p'>One of my favorite things about Indian women is that they dress up all the time, either in brightly colored kurti (tunic style) or the traditional wrap-around style, sari. This service was no different, and it is so much prettier to celebrate life than the black mourning ensemble we are used to seeing at funerals. As the mourning color of their culture is white, even the men wore white or light-colored garments.</p>
                    <p className='p'>We all took off our shoes at the door - men's shoe closet on the right, women's on the left - and went barefoot or in socks until we were ready to leave the building. And as tradition dictates, men and women sit separately as well. Our translator explained this is to keep the focus on God while in the temple, and not to invite sexual tension. It was a little strange being barefoot in church, even during the post-service meal.</p>
                    <p className='p'>The swami spoke of God's will - yes, Hindus do believe in God - and that we should not question His divine decisions, but to search instead for His Supreme peace (or Shanti.) Put in a suit, rather than saffron-colored robes, and placed in a church, rather than a mandir, you probably would not know the difference between his Hindu message and a Christian one.</p>
                    <p className='p'>With the exception of one very large deficiency.</p>
                    <p className='p'>And this is the profoundly sad part. While Hindus do believe in one Supreme Divine God, they believe God incarnates in different forms on earth at different times, for different reasons, each receiving a different name - I've read, up to 330 million of them. And they believe Jesus Christ was just a man, who died, and was reincarnated … just like all men.</p>
                    <p className='p'>There is no savior, no heaven, no eternal life in paradise. This world is as good as it gets, with the chance to do it over and over again through rigorous spiritual practice, forever striving to realize for himself the ever-elusive “god-consciousness.”</p>
                    <p className='p'>My reading over the last two weeks about Hinduism has only touched the tiniest tip of the iceberg of all there is to know about the religion. I've thought about how it is that people all over the world are a part of certain religions (usually) because that's what they've been exposed to since they were children.</p>
                    <p className='p'>And the thing that bothers me the most about those three lives lost on Tybee Island two weeks ago … that they didn't know my Savior, Jesus. I shudder to think that they faced their last moments in that water knowing they had … no … Hope.</p>
                    <p className='p'>I've found that almost nothing has brought me greater appreciation for the time and place God has put me - to learn of the Gospel, to find my hope in God through Jesus Christ - than having been exposed, so intimately, to something outside my bubble. This is the place God grows me. Not just sitting in my comfortable church pew on Sunday morning, but out in the world - to be salt and light. My friend and Bible teacher, Janice, used to tell us, “To be the salt of the earth you have to get out of the salt shaker.”</p>
                    <p className='p'>I pray more now for our non-believing family and friends, and praying for more opportunities outside of the salt shaker.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Oct112015;