import React from 'react';
import { Link } from 'react-router-dom';
import './arch2015.css';

const Mar092015 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2015/ironing.jpg" alt="Ironing" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 mar092015'>
                    <h5>March 9th, 2015</h5>
                    <h3>As Long As It Looks Clean, It's Good Enough</h3>
                    <p className='p'>Not long after we all got up and moving this first Monday morning of Daylight Saving Time, I heard strange noises coming from the direction of our laundry room. When I went to investigate, I found my fifteen year-old son ironing his school shirt.</p>
                    <p className='p'>This would be more impressive if it hadn't been the dirty Friday school shirt that had been sitting all weekend in the huge pile of laundry on his closet floor. But still, for any fifteen year-old boy, this is something of a marvel. Especially for the child who is too lazy to carry the aforementioned dirty clothes down the hall to the laundry room to be washed by someone else - me.</p>
                    <p className='p'>All my boys learned to do laundry at an early age. When I say,<i>learned to do laundry</i>, it simply means put clothes in the washer, put the soap in, and turn on the normal cycle. There's no sorting, buttoning of pants, or stain removal included. Nor do they usually remember to check pockets.</p>
                    <p className='p'>It seems that, with each child, the motivation to wear clean clothes has waned substantially. So much so, that the youngest two would rarely wear a clean article of clothing if I hadn't decided to wash their clothes for them during the school year, provided they get them to the laundry room.</p>
                    <p className='p'>I have a suspicion that Axe Body products were created by a mom of boys.</p>
                    <p className='p'>If I knew there were elves in my laundry room that would sort, wash, dry, fold, and return my clothes to my bedroom, you'd better believe I'd take advantage of that on a regular basis. Like every time I ran out of clean underwear. My boys have yet to appreciate and<i>utilize</i> their elf.</p>
                    <p className='p'>(Did I really just refer to myself as an elf?)</p>
                    <p className='p'>This ironing revelation (which turns out isn't a new thing - my husband says this child irons several morning a week) comes not long after finding out this same child doesn't know how to write in cursive.</p>
                    <p className='p'>What! How can that be? Yes, imagine my overreaction. I was stunned at how I could miss that for so many years and could feel my good mama status slipping away like a mudslide. And I considered giving him a crash course that very minute.</p>
                    <p className='p'>I had to let that one go because, after all, he knows how to do laundry and iron a shirt, for crying out loud. (Good mama status, still in tact.)</p>
                    <p className='p'>He may not wear another clean shirt this school year, but at least he won't look like his mama let him sleep in his school clothes.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Mar092015;