import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Feb132012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2011/treadmill.jpg" alt="Treadmill" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 feb132012'>
                    <h5>February 13th, 2012</h5>
                    <h3>Treadmill Epiphany</h3>
                    <p className='p'>Have you ever noticed how your activities color everything around you?</p>
                    <p className='p'>You don't even notice, until you're on a diet, the copious amount of diet books that are suddenly everywhere.  And not until you're on that diet are you aware of the magic power of Cheez-Its.  Those things take a well-timed dive into your grocery cart and, before you know it, they have ridden the check-out belt and are bagged up faster than an elephant riding a zip line.</p>
                    <p className='p'>Some years ago my husband was pretty serious into mountain biking and every worn down strip of grass or weeds was a “bike trail.”  Most of them actually were, but passing them without him with me to point out the trails, I would have only noticed the cows in the field, the jutting rocks by the river or the single diseased hedge tree in the field of nothing else.  Nothing else but a bike trail, that is.  Biking colored everything he saw.</p>
                    <p className='p'>After the first half-serious thought we had about adopting several years ago, adoption colored our days like a leaky marker in the dryer - showing up everywhere.  Every time I turned around, there was some reference to adoption that I was pretty sure hadn't been there before.  Talk about being nudged.</p>
                    <p className='p'>Writing is starting to color parts of my world.</p>
                    <p className='p'>Yesterday I had an epiphany while on the treadmill:  Running is much like writing.  Some days I get on the treadmill and I feel good, the energy is there, my breathing is steady and my run just flows.  I feel as if I could keep going even after my planned time is up.  I feel energized after and can hardly wait until my next run.</p>
                    <p className='p'>Writing can be that way too.  On those days I have words in my head just waiting to spill out into something creative and cohesive.  There is energy behind it and my fingers can type without ceasing.  On those days I don't have to force my way to my computer and I don't need butt glue to keep me there until something productive comes out.   I most always feel good about what I've written and can't wait to share it. Those are the “flow” days.  Everything just flows.</p>
                    <p className='p'>On other days, like yesterday, I don't want to run.  I have no energy to run.  I drag my feet until I have no choice but to get my fanny on the treadmill before the day burns completely away.  Then once I'm there, the only thing I can concentrate on is the heaviness of my legs and the burning in my shins; my entire run time peppered with stops and starts.  And when I'm done I'm exhausted, physically and mentally.  I call those “forced” days.  I have writing days just..like..that.</p>
                    <p className='p'>Forced writing days are tough.  For me they are tougher than the forced run days.  On forced run days, at least my legs know what they are supposed to do.  But on forced writing days, my brain and my fingers often seem to not be in cooperation.  The fingers want to type but the brain isn't giving them anything.  Even if I can force my way through a couple pages, at the end I'm mentally drained and emotionally spent.  Then I just want my Cheez-Its and a nap.</p>
                    <p className='p'>The most difficult thing for me is to not get discouraged by the forced days.  One day of less-than-stellar performance does not define me.  A bad run does not make me a bad runner. One day of falling off the diet wagon clutching a box of Cheez-Its with both hands doesn't mean I've blown it.  A blank page at the end of the day does not mean failure.  Every day is new, 1,440 minutes to try again.  Never give up.  Never say I can't.  My grandmother used to say you can do anything you want if you just try.  My grandmother was a pretty smart woman.</p>
                    <p className='p'>My interests, my activities may color my world but my stumbles will not color my attitude.  At least not today.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Feb132012;