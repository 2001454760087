import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header, Main, Footer, Recent, Archives, Reviews, Contact, ScrollToTop } from "./components/index";
// 2016 Archives
import { Jan042016, Feb112016, Feb282016, May182016, May242016, May262016, May272016, May312016, Jun102016, Sept022016, Sept172016, Dec312016 } from "./components/archives/2016/index";
// 2015 Archives
import { Jan052015, Jan072015, Jan122015, Jan262015, Feb032015, Feb132015, Mar032015, Mar092015, Apr292015, Jun122015, Jul062015, Aug182015, Oct112015, Nov062015 } from "./components/archives/2015/index";
// 2014 Archives
import { Jan312014, Mar102014, Apr242014, Jul282014, Sept082014, Sept092014, Dec162014, Dec262014 } from "./components/archives/2014/index";
// 2013 Archives
import { Jan042013, Jan112013, Mar182013, Apr012013, May012013, May132013, Jul162013, Jul222013, Jul302013, Aug142013, Sept192013, Oct152013, Oct222013, Nov252013, Dec022013 } from "./components/archives/2013/index";
// 2012 Archives
import { Jan172012, Feb022012, Feb132012, Feb212012, Mar232012, Mar262012, Apr162012, Apr192012, Jun112012, Aug062012, Aug022012, Aug232012, Dec122012 } from "./components/archives/2012/index";
// 2011 Archives
import { Feb222011, Apr132011, Apr142011, Jun212011, Jun222011a, Jun222011b, Jun262011, Sept282011, Oct192011, Dec212011 } from "./components/archives/2011/index";
// 2010 Archives
import { Jan292010, May012010, Jul112010, Nov182010, Nov252010 } from "./components/archives/2010/index";
// 2009 Archives
import { Sept212009, Sept282009 } from "./components/archives/2009/index";




const App = () => {
      

    return (

        <BrowserRouter>
                <section>
			        <header><Header /></header>
			        <div>
                    <ScrollToTop />
                        <Routes>
                            
                                <Route path="/" element={<Main />}/>
                                <Route path="/recent" element={<Recent />}/>
                                <Route path="/archives" element={<Archives />}/>
                                <Route path="/reviews" element={<Reviews />}/>
                                <Route path="/contact" element={<Contact />}/>
                                {/* Archives routes */}
                                {/* 2016 */}
                                <Route path="/jan042016.jsx" element={<Jan042016 />}/>
                                <Route path="/feb112016.jsx" element={<Feb112016 />}/>
                                <Route path="/feb282016.jsx" element={<Feb282016 />}/>
                                <Route path="/may182016.jsx" element={<May182016 />}/>
                                <Route path="/may242016.jsx" element={<May242016 />}/>
                                <Route path="/may262016.jsx" element={<May262016 />}/>
                                <Route path="/may272016.jsx" element={<May272016 />}/>
                                <Route path="/may312016.jsx" element={<May312016 />}/>
                                <Route path="/jun102016.jsx" element={<Jun102016 />}/>
                                <Route path="/sept022016.jsx" element={<Sept022016 />}/>
                                <Route path="/sept172016.jsx" element={<Sept172016 />}/>
                                <Route path="/dec312016.jsx" element={<Dec312016 />}/>
                                {/* 2015 */}
                                <Route path="/jan052015.jsx" element={<Jan052015 />}/>
                                <Route path="/jan072015.jsx" element={<Jan072015 />}/>
                                <Route path="/jan122015.jsx" element={<Jan122015 />}/>
                                <Route path="/jan262015.jsx" element={<Jan262015 />}/>
                                <Route path="/feb032015.jsx" element={<Feb032015 />}/>
                                <Route path="/feb132015.jsx" element={<Feb132015 />}/>
                                <Route path="/mar032015.jsx" element={<Mar032015 />}/>
                                <Route path="/mar092015.jsx" element={<Mar092015 />}/>
                                <Route path="/apr292015.jsx" element={<Apr292015 />}/>
                                <Route path="/jun122015.jsx" element={<Jun122015 />}/>
                                <Route path="/jul062015.jsx" element={<Jul062015 />}/>
                                <Route path="/aug182015.jsx" element={<Aug182015 />}/>
                                <Route path="/oct112015.jsx" element={<Oct112015 />}/>
                                <Route path="/nov062015.jsx" element={<Nov062015 />}/>
                                {/* 2014 */}
                                <Route path="/jan312014.jsx" element={<Jan312014 />}/>
                                <Route path="/mar102014.jsx" element={<Mar102014 />}/>
                                <Route path="/apr242014.jsx" element={<Apr242014 />}/>
                                <Route path="/jul282014.jsx" element={<Jul282014 />}/>
                                <Route path="/sept082014.jsx" element={<Sept082014 />}/>
                                <Route path="/sept092014.jsx" element={<Sept092014 />}/>
                                <Route path="/dec162014.jsx" element={<Dec162014 />}/>
                                <Route path="/dec262014.jsx" element={<Dec262014 />}/>
                                {/* 2013 */}
                                <Route path="/jan042013.jsx" element={<Jan042013 />}/>
                                <Route path="/jan112013.jsx" element={<Jan112013 />}/>
                                <Route path="/mar182013.jsx" element={<Mar182013 />}/>
                                <Route path="/apr012013.jsx" element={<Apr012013 />}/>
                                <Route path="/may012013.jsx" element={<May012013 />}/>
                                <Route path="/may132013.jsx" element={<May132013 />}/>
                                <Route path="/jul162013.jsx" element={<Jul162013 />}/>
                                <Route path="/jul222013.jsx" element={<Jul222013 />}/>
                                <Route path="/jul302013.jsx" element={<Jul302013 />}/>
                                <Route path="/aug142013.jsx" element={<Aug142013 />}/>
                                <Route path="/sept192013.jsx" element={<Sept192013 />}/>
                                <Route path="/oct152013.jsx" element={<Oct152013 />}/>
                                <Route path="/oct222013.jsx" element={<Oct222013 />}/>
                                <Route path="/nov252013.jsx" element={<Nov252013 />}/>
                                <Route path="/dec022013.jsx" element={<Dec022013 />}/>
                                {/* 2012 */}
                                <Route path="/jan172012.jsx" element={<Jan172012 />}/>
                                <Route path="/feb022012.jsx" element={<Feb022012 />}/>
                                <Route path="/feb132012.jsx" element={<Feb132012 />}/>
                                <Route path="/feb212012.jsx" element={<Feb212012 />}/>
                                <Route path="/mar232012.jsx" element={<Mar232012 />}/>
                                <Route path="/mar262012.jsx" element={<Mar262012 />}/>
                                <Route path="/apr162012.jsx" element={<Apr162012 />}/>
                                <Route path="/apr192012.jsx" element={<Apr192012 />}/>
                                <Route path="/jun112012.jsx" element={<Jun112012 />}/>
                                <Route path="/aug022012.jsx" element={<Aug022012 />}/>
                                <Route path="/aug062012.jsx" element={<Aug062012 />}/>
                                <Route path="/aug232012.jsx" element={<Aug232012 />}/>
                                <Route path="/dec122012.jsx" element={<Dec122012 />}/>

                                {/* 2011 */}
                                <Route path="/feb222011.jsx" element={<Feb222011 />}/>
                                <Route path="/apr132011.jsx" element={<Apr132011 />}/>
                                <Route path="/apr142011.jsx" element={<Apr142011 />}/>
                                <Route path="/jun212011.jsx" element={<Jun212011 />}/>
                                <Route path="/jun222011a.jsx" element={<Jun222011a />}/>
                                <Route path="/jun222011b.jsx" element={<Jun222011b />}/>
                                <Route path="/jun262011.jsx" element={<Jun262011 />}/>
                                <Route path="/sept282011.jsx" element={<Sept282011 />}/>
                                <Route path="/oct192011.jsx" element={<Oct192011 />}/>
                                <Route path="/dec212011.jsx" element={<Dec212011 />}/>
                                {/* 2010 */}
                                <Route path="/jan292010.jsx" element={<Jan292010 />}/>
                                <Route path="/may012010.jsx" element={<May012010 />}/>
                                <Route path="/jul112010.jsx" element={<Jul112010 />}/>
                                <Route path="/nov182010.jsx" element={<Nov182010 />}/>
                                <Route path="/nov252010.jsx" element={<Nov252010 />}/>
                                {/* 2009 */}
                                <Route path="/sept282009.jsx" element={<Sept282009 />}/>
                                <Route path="/sept212009.jsx" element={<Sept212009 />}/>

                        </Routes>
                    </div>
			        <footer><Footer /></footer>
		        </section>
        </BrowserRouter>

)};

const appElement = document.getElementById("app");
const root = createRoot(appElement);
root.render(<App />);