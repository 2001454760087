import React from 'react';
import { Link } from 'react-router-dom';
import './arch2011.css';

const Apr142011 = () => {

    return (

        <section className="main-sec">
            <div className='main-container'>
                <div className='main-flex-left'>
                    <img src="/images/2011/savannah.jpg" alt="Savannah GA" className='head-pic'/>
                </div>

                <div className='main-flex-right'>
                    <div className='main-flex-right-sub-1 apr142011'>
                        <h5>April 14th, 2011</h5>
                        <h3>City or Country</h3>
                        <p className='p'>If you had to choose between living in the heart of a big city or out in the country, which would you choose? and why?</p>
                        <p className='p'>Without question, I would choose out in the country…but close to a big city. Country is quiet when you want quiet. Country is your own space when you want space. Country next to big city is convenient when you want convenience.</p>
                        <p className='p'>And you can't see the stars in the sky at night when you're in the city.</p>
                        <p></p>
                        <p></p>
                        <div className='back-container'>
                            <Link to="/archives" className='back-btn'>Back to Archives</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-flex-bottom'></div>
        </section> 

    )
}

export default Apr142011;