import React from 'react';
import { Link } from 'react-router-dom';
import './arch2013.css';

const Apr012013 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2013/book-heart.jpg" alt="Book" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 apr012013'>
                    <h5>April 1st, 2013</h5>
                    <h3>Happy Anniversary ... No Fooling!</h3>
                    <p className='p'>Twenty-four years ago today, in a park, on a windy Kansas afternoon, two scared but determined teenagers said 'I do' in front of God and a small group of friends and family.</p>
                    <p className='p'>4 kids,</p>
                    <p className='p'>4 dogs and 2+ cats,</p>
                    <p className='p'>14 automobiles,</p>
                    <p className='p'>10 homes,</p>
                    <p className='p'>2 remodels,</p>
                    <p className='p'>a new build,</p>
                    <p className='p'>and just a couple gray hairs later,  I can confidently say...</p>
                    <p className='p'>We're done having kids. And pets. And we'll never build a house again.</p>
                    <p className='p'>We're not the same people we were 24 years ago. And I thank God for that. He's grown us; changed us into the husband and wife we were meant to be. We've become each other's best friend and favorite companion.</p>
                    <p className='p'>God is still working; we're still growing.</p>
                    <p className='p'>We're no longer scared but we're still determined. We're not perfect, but we're perfect for each other. We've gained confidence in each other and ourselves. We've reached a place that only comes with time and commitment.</p>
                    <p className='p'>We've been through different seasons; immersed ourselves in the sun-filled days, clung to the Lord, the life raft of our promise, during the storms.</p>
                    <p className='p'>We've grown wiser; learned to weed and feed, become subject matter experts on preventative maintenance.</p>
                    <p className='p'>Twenty-four years from now we won't be the same people we are today. We may again be scared, but for different reasons. We'll still be determined. And we'll still be best friends.</p>
                    <p className='p'>Happy Anniversary, My Love.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Apr012013;