import React from 'react';
import { Link } from 'react-router-dom';
import './arch2012.css';

const Apr192012 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2012/dick-clark.jpg" alt="Dick Clark" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 apr192012'>
                    <h5>April 19th, 2012</h5>
                    <h3>For Now, Dick Clark ...So long</h3>
                    <p className='p'>You know you're getting old when you mention someone famous has died and your kid says, “who?”</p>
                    <p className='p'>Dick Clark.  You know. The guy from American Bandstand.</p>
                    <p className='p'>Blank stares.</p>
                    <p className='p'>The guy who hosts the New Year's Eve show in Times Square.</p>
                    <p className='p'>More blank looks.</p>
                    <p className='p'>TV's Bloopers and Practical Jokes?</p>
                    <p className='p'>Still nothing.</p>
                    <p className='p'>Anyway, if famous people die in three's, Dick is the third.  First Thomas Kinkaid, then Mike Wallace, and now Dick Clark.</p>
                    <p className='p'>And my kids hadn't a clue who any of them were.</p>
                    <p className='p'>I've often thought there isn't much gap of generation between my kids and me.  There's less than 20 years between me and the older two.  But, for Pete's sake, if they don't know who Dick Clark is then maybe I'm older than I think.</p>
                    <p className='p'>As 'America's Oldest Teenager,' Dick Clark was older than an entire nation thought him to be.  And New Year's Eve will never be the same.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Apr192012;