import React from 'react';
import { Link } from 'react-router-dom';
import './arch2010.css';

const Nov252010 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2010/ham.jpg" alt="Ham" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 nov252010'>
                    <h5>November 25th, 2010</h5>
                    <h3>Thanksgiving Ham</h3>
                    <p className='p'>How is it, after 37 years of Thanksgivings, that it's just dawning on me that I really don't like turkey?</p>
                    <p className='p'>I go so far as to shut my bedroom door while the thing is in the oven because I don't like my bedroom to smell like turkey when I go to bed at night.</p>
                    <p className='p'>I think, in previous years, I've missed this revelation because I've drowned my turkey in gravy. Well, not this calorie-counting holiday. Gravy costs too much. I didn't make myself go to the Y to work out on Thanksgiving morning in the freezing cold just to eat four thousand calories in one tablespoon. Turkey covered in mashed turnips just doesn't quite cut it.</p>
                    <p className='p'>So, today, this is what I decided: I am an adult. I don't have to eat turkey at Thanksgiving just because the pilgrims did or just because that's what my guests are expecting to be served. I don't have to eat turkey because it's good for me or low in calories. I am an adult and I can decide I don't like turkey if I want. Well, I don't like turkey.</p>
                    <p className='p'>I will continue to cook turkey for holiday meals (and shut my bedroom door) so my family doesn't organize a revolt or strap me up and send me to the funny farm. But, I will also cook a ham and that's what I'll be eating.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Nov252010;