import React from 'react';
import { Link } from 'react-router-dom';
import './arch2009.css';

const Sept212009 = () => {

    return (

        <section className="main-sec">
        <div className='main-container'>
            <div className='main-flex-left'>
                <img src="/images/2009/cabin.jpg" alt="Cabin" className='head-pic'/>
            </div>

            <div className='main-flex-right'>
                <div className='main-flex-right-sub-1 sept212009'>
                    <h5>September 21st, 2009</h5>
                    <h3>Church Camp Memories</h3>
                    <p className='p'>I was asked to share memories of church camp for a reunion that is coming up. I only went to church camp once but those memories are a huge part of my testimony.I moved to Wellington, KS in June in 1988. I was the unhappiest 15 year old that ever lived. My mother had remarried and moved me from Wyoming to Kansas. From the only friends, school, and hometown I had ever known to a place where I knew no one and didn't want to be there. And then I got the flu. Having moved from the dry climate of Wyoming to the humidity that is Kansas, I thought I was going to die! I thought, “I can't live here!”I was a miserable teen looking to make everyone around me as miserable as I was. One of the first times I met Dane was before church camp in August, 1988. He handed me a camp registration pamphlet and said “the bus leaves on (whatever day and whatever time it was). Be there” He didn't ask me if I would like to go, didn't ask me if I would go. He just said “Be there” Being the miserable poop that I was, I just wanted to get away from the mess that was my life. So, I packed my stuff (including my mini skirts and cigarettes; neither were allowed) and loaded a bus bound for Las Vegas, New Mexico. I did not even know there was a Las Vegas in New Mexico.</p>
                    <p className='p'>The week started out as miserable as I was. The bus got stuck in the mud going up a hill to the camp and we had to unload and walk, I don't even remember how far, in the mud. And the cabins, if I remember right, had walls that didn't go all the way down to meet the floor and it got pretty stinkin' cold at night. Not so bad when you're tucked all snug in a sleeping bag, but getting up at the crack of dawn to beat all the other girls to the shower in the cold kept me right there in the bad mood I started out in.</p>
                    <p className='p'>Two amazing things happened that week. First, I met David at that muddy, stinkin', miserable camp in Las Vegas, New Mexico. I didn't know it then, but that turned out to be a divine meeting. We've been married 20 years and counting.</p>
                    <p className='p'>Secondly, God softened me that week. Dane preached on Romans. I do so wish I still had my notes. I remember sitting in the everything hall (everything because we ate, worshipped, played and hung out there), listening to Dane and I wrote “I'm lost” in my notes. One of our counselors, forgive me for not remembering her name - Carol something, I think- was sitting next to me and saw me write those profound words. Later, she and Sandy Metz (not Metz back then, I don't think) took me aside and prayed with me. I accepted Jesus as my Savior that day. That day was August 8, 1988. (8-8-88)</p>
                    <p className='p'>David had his 17th birthday that week. By his 18th birthday we'd been married 4 months and Jason was on the way. (He'll be 20 next month and is a sophomore at KU) If I've learned anything in the 20 years since that week in Las Vegas, New Mexico it's that being saved doesn't mean you are a perfect Christian. Twenty years of seeking the Lord doesn't even make you a perfect Christian. Being saved does mean you are forgiven and you keep trying.</p>
                    <p className='p'>I also learned that you don't wear mini skirts to church camp. Just in case you end up having to trudge through the mud.</p>
                    <p></p>
                    <p></p>
                    <div className='back-container'>
                        <Link to="/archives" className='back-btn'>Back to Archives</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='main-flex-bottom'></div>
    </section> 

    )
}

export default Sept212009;